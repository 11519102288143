import React from 'react'
import { PoliticalDetailtsContainer } from "../../components/voterDetail";

function Political() {
  return (
    <div>
      <PoliticalDetailtsContainer />
    </div>
  )
}

export default Political
