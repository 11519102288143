import {
  BOOTH_DETAILS_PENDING,
  BOOTH_DETAILS_SUCCESS,
  BOOTH_DETAILS_ERROR,
  BOOTH_DETAILS_EMPTY,
  JANSAMPARK_COUNT_SUCCESS,
  JANSAMPARK_COUNT_ERROR,
  JANSAMPARK_COUNT_EMPTY,
  JANSAMPARK_COUNT_PENDING,
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  success: false,
  notFound: false,
  errorMessage: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BOOTH_DETAILS_PENDING:
      return {
        ...state,
        success: false,
        loading: true,
        notFound: false,
        errorMessage: null,
      };
    case BOOTH_DETAILS_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        errorMessage: null,
        notFound: false,
        data: action.payload,
      };
    case BOOTH_DETAILS_ERROR:
      return {
        ...state,
        success: true,
        loading: false,
        notFound: false,
        errorMessage: action.payload,
      };
    case BOOTH_DETAILS_EMPTY:
      return {
        ...state,
        success: true,
        loading: false,
        errorMessage: null,
        notFound: false,
        data: [],
      };
    default:
      return state;
  }
};


 // jansampark count details cases for boothdetails page
const INITIAL_STATE_JAN = {
  loading: false,
  success: false,
  notFound: false,
  errorMessage: null,
};
export const jansamparkCount = (state = INITIAL_STATE_JAN, action) =>{
  switch (action.type) {
   
    case JANSAMPARK_COUNT_PENDING:
      return {
        ...state,
        success: false,
        loading: true,
        notFound: false,
        errorMessage: null,
      };
    case JANSAMPARK_COUNT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        errorMessage: null,
        notFound: false,
        data: action.payload,
      };
    case JANSAMPARK_COUNT_ERROR:
      return {
        ...state,
        success: true,
        loading: false,
        notFound: false,
        errorMessage: action.payload,
      };
    case JANSAMPARK_COUNT_EMPTY:
      return {
        ...state,
        success: true,
        loading: false,
        errorMessage: null,
        notFound: false,
        data: [],
      };
    default:
      return state;
  }
  
}
