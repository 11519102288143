import axios from "axios";
let url;
if (window.location.hostname === "localhost") {
  url = "http://localhost:8080/v1";
} else {
  url = "https://api.abhiyaaan.in/v1";
}

// url = "https://votermodule.opalina.in/api/v1";

if (window.location.href.indexOf("votermodule") !== -1) {
  url = "https://votermodule.opalina.in/api/v1";
}

if (window.location.href.indexOf("abhiyaaan") !== -1) {
  url = "https://api.abhiyaaan.in/v1";
}

// for local
// let url = "http://localhost:8080/v1";

// for testing
// let url = "https://jsonplaceholder.typicode.com"

export const axiosInstance = axios.create({
  baseURL: url,
});

// axiosInstance.defaults.headers.common['Authorization'] = 'AUTH_TOKEN';
// axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false
    : true;
};

const requestHandler = (request) => {
  if (isHandlerEnabled(request)) {
    const token = localStorage.getItem("access-token");
    if (token) {
      request.headers["Authorization"] = token;
    }
  }
  return request;
};

const errorHandler = (error) => {
  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  return response;
};

const setupAxiosInterceptors = () => {
  // Add interceptors
  axiosInstance.interceptors.request.use((request) => requestHandler(request));

  axiosInstance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
  );
};

export default {
  setupAxiosInterceptors,
  axiosInstance,
};
