import React from "react";

class OtpComponent extends React.Component {

    render() {
        return (
            <div>
                <div className="voter_container">
                    <div className="otp_container">
                        <h4>Enter the verify code</h4>
                        <p>We sent a verifycation code to <span>+84 90756xxxx</span></p>
                        <form className="otp" autocomplete="off" novalidate="">
                            <fieldset>
                              <input maxlength="1" />
                              <input maxlength="1" />
                              <input maxlength="1" />
                              <input maxlength="1" />
                              <input maxlength="1" />
                              <input maxlength="1" />
                            </fieldset>
                        </form>
                        <h5>The verify code will be expire in <span>01:23</span></h5>
                        <h6><a href="#"><img src="/images/refresh.svg" alt="" />Resend Code</a></h6>
                        <ul>
                            <li>
                                <a href="#" className="verify_btn">verify</a>
                            </li>
                            <li>
                                <a href="#" className="cancel_btn">cancel</a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        );
    }
}


export default OtpComponent;