import React, { useEffect, useState } from "react";
import VoterHeaderComponent from "../voter/voterheader";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import LoaderComponent from "../loader/LoaderComponent";

// import InfoWindow from "./InfoWindow";
import "./RoutemapStyle.css";

function RoutemapComponent(props) {
  
  const [loading, setLoading] = useState(false);
  const [infoWindow, setInfoWindow] = useState(false);
  const [locationName, setLocationName] = useState("");
  useEffect(() => {
    // console.log("goog", props.google);
   
    return () => {};
  }, []);
  const onMarkerClick = (props, marker, e) => {
    // console.log(props, marker, e);
    setLocationName(props.name);
    setInfoWindow(true);
  };

  const onMapClicked = () => {
    console.log("Mapped Click");
    setInfoWindow(false);
  };

  const onInfoWindowClose = () => {
    console.log("closed");
  };
  
  // console.log(infoWindow);
  return (
    <div className="voter_bg">
      <VoterHeaderComponent />
      {/* <div className="routemap_center"> */}
      <Map google={props.google} onClick={onMapClicked} zoom={14}>
        <Marker onClick={onMarkerClick} name={"Current location"} />
        {/* <InfoWindow isOpen={infoWindow} name={locationName} /> */}

        <InfoWindow visible={infoWindow}>
          <div>
            <h1>{locationName}</h1>
          </div>
        </InfoWindow>
      </Map>
      {/* </div> */}
    </div>
  );
}

// export default RoutemapComponent;

export default GoogleApiWrapper((props) => ({
  apiKey: "AIzaSyAljVcaYgkugi40_at4DcNtI7Ohk50TITE",
  language: "en",
}))(RoutemapComponent);
