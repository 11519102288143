import { connect } from "react-redux";
import { PoliticalDetailts } from "./PoliticalDetailts";

const mapStateToProps = (state) => {
  // console.log(state);
  return {}
 }

const mapDispatchToProps = () => ({});

export const PoliticalDetailtsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PoliticalDetailts);