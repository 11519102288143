import React, { memo } from "react";
import { connect } from "react-redux";
import { PieChart } from "react-minimal-pie-chart";
import LoaderComponent from "../loader/LoaderComponent";

const PieChartComponent = (props) => {
  // console.log(props.jansamparkCountData);

  let count =
    props.jansamparkCountData &&
    props.jansamparkCountData.success &&
    props.jansamparkCountData.data.count;
  let totalcount =
    props.jansamparkCountData &&
    props.jansamparkCountData.success &&
    props.jansamparkCountData.data.total;

  let doneCount = Math.round((count / totalcount) * 100);
  let notDoneCount = Math.round(100 - doneCount);

  const chartData = [
    { title: "One", value: doneCount, color: "#FF7C78" },
    { title: "Two", value: notDoneCount, color: "#FED2D1" },
  ];

  const defaultLabelStyle = {
    fontSize: "7px",
    fontFamily: "sans-serif",
  };

  return (
    <React.Fragment>
      {props.jansamparkCountData && props.jansamparkCountData.loading ? (
        <LoaderComponent displayType={props.jansamparkCountData.loading} />
      ) : null}

      <PieChart
        data={chartData}
        label={({ dataEntry }) => Math.round(dataEntry.percentage) + "%"}
        labelStyle={(index) => ({
          fill: "#FFFF",
          fontSize: "6px",
          fontFamily: "sans-serif",
        })}
        style={{ height: "250px" }}
      />

      <ul className="bootdetail_img_list">
        <li>
          <h6>
            <img src="/images/rectangle1.svg" alt="" />
            <span>Completed</span>
          </h6>
        </li>

        <li>
          <h6>
            <img src="/images/rectangle2.svg" alt="" />
            <span>Remaining</span>
          </h6>
        </li>
      </ul>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    jansamparkCountData: state.jansamparkCount,
  };
};

export default memo(connect(mapStateToProps)(PieChartComponent));
