import React, { useState, useEffect } from "react";
import { VoterHeaderMenu } from "../VoterHeaderMenu";
// import Scheme from "../../voter/scheme/Scheme";
// import Skilltest from "./Skilltest";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {
  educationLevel,
  subjectList,
  getProfessionList,
} from "./handleOnChange";
import { axiosPost } from "../../../utils/commonAxios";
import { getYearList } from "../../common/Function";
import { AlertBox } from "../../../components/common/AlertBox"

function EducationalDetails() {
  const { epic_id } = useParams();
  const [getEducationalData, setGetEducationalData] = useState({
    educationLevelArr: [],
    subjectsArr: [],
    degreeArr: [],
    institutionArr: [],
    yearStartArr: [],
    yearEndArr: [],
    professionalProfileArr: [],
    mainProfessionArr: [],
    organizationArr: [],
    yearStartProfArr: [],
    yearEndProfArr: [],
    skillTestArr: [],
  });

  useEffect(() => {
    getEducationLevel();
    getSubjectList();
    setFormField();
  }, []);

  const getSubjectList = async () => {
    try {
      let subjectData = await subjectList();
      setGetEducationalData((prevState) => ({
        ...prevState,
        subjectsArr: subjectData,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const [education, setEducation] = useState({
    educationLevel: [],
    subjects: [],
    degree: "",
    subject: "",
    institution: "",
    yearStart: [],
    yearEnd: [],
    professionalProfile: [],
    mainProfession: [],
    organization: "",
    position: "",
    yearStartProf: [],
    yearEndProf: [],
    skillTest: [],
    description: "",
  });

  const [skilData, setSkilData] = useState([]);

  const getEducationLevel = async () => {
    try {
      let educationLevelData = await educationLevel();
      setGetEducationalData((prevState) => ({
        ...prevState,
        educationLevelArr: educationLevelData,
      }));

      let subectList = await subjectList();
      setGetEducationalData((prevState) => ({
        ...prevState,
        subjectsArr: subectList,
      }));
      //years list
      let yearlist = await getYearList();
      setGetEducationalData((prevState) => ({
        ...prevState,
        yearStartArr: yearlist,
        yearEndArr: yearlist,
        yearStartProfArr: yearlist,
        yearEndProfArr: yearlist,
      }));

      //professionalProfileArr
      let profession = await getProfessionList();
      setGetEducationalData((prevState) => ({
        ...prevState,
        professionalProfileArr: profession,
        mainProfessionArr: profession,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const updateForm = (e) => {
    const { name, value } = e.target;
    setEducation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const updateFormSkills = (e) => {
  //   const { name, value } = e.target;
  //   console.log(name, value);
  //   setEducation((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  const handleChange = async (selectedOpt, type) => {
    console.log(selectedOpt);
    switch (type) {
      case "education":
        setEducation((prevState) => ({
          ...prevState,
          educationLevel: selectedOpt,
        }));
        break;
      case "subject":
        setEducation((prevState) => ({
          ...prevState,
          subjects: selectedOpt,
        }));
        break;

      case "yearStart":
        setEducation((prevState) => ({
          ...prevState,
          yearStart: selectedOpt,
        }));
        //I m using this becosue start is always less than end year
        // let startFrom = selectedOpt["value"] + 1;
        // let endYearList = await getYearList(startFrom);
        // setGetEducationalData((prevState) => ({
        //   ...prevState,
        //   yearEndArr: endYearList,
        // }));

        break;
      case "yearEnd":
        setEducation((prevState) => ({
          ...prevState,
          yearEnd: selectedOpt,
        }));
        break;

      case "professional":
        setEducation((prevState) => ({
          ...prevState,
          professionalProfile: selectedOpt,
        }));
        break;
      case "mainprofessional":
        setEducation((prevState) => ({
          ...prevState,
          mainProfession: selectedOpt,
        }));
        break;

      case "yearStartProf":
        setEducation((prevState) => ({
          ...prevState,
          yearStartProf: selectedOpt,
        }));

        //I m using this becosue start is always less than end year
        // let startFromDate = selectedOpt["value"] + 1;
        // let endYearListProf = await getYearList(startFromDate);
        // setGetEducationalData((prevState) => ({
        //   ...prevState,
        //   yearEndProfArr: endYearListProf,
        // }));

        break;

      case "yearEndProf":
        setEducation((prevState) => ({
          ...prevState,
          yearEndProf: selectedOpt,
        }));
        break;
      default:
        break;
    }
  };

  const tagsArray = (skillData) => {
    setSkilData(skillData);
  };
  //Update form
  const setFormField = async () => {
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) { }

    let reqobject = {
      action: "/voter/search",
      epic_no: epic_id,
    };

    try {
      const resp = await axiosPost(reqobject, checkuser);
      if (resp.data.resultFlag) {
        const { professional } = resp.data.response;

        let updatedData = {};
        let years = {};

        if (professional["degree"]) {
          updatedData["degree"] = professional["degree"]["name"];
          updatedData["subject"] = professional["degree"]["subject"];
          updatedData["institution"] = professional["degree"]["institute"];

          if (professional["degree"]["start_yr"]) {
            let startYear = [];
            startYear = {
              value: professional["degree"]["start_yr"],
              label: professional["degree"]["start_yr"],
            };
            setEducation((prevState) => ({
              ...prevState,
              yearStart: startYear,
            }));
          }

          if (professional["degree"]["end_yr"]) {
            let endYear = [];
            endYear = {
              value: professional["degree"]["end_yr"],
              label: professional["degree"]["end_yr"],
            };
            setEducation((prevState) => ({
              ...prevState,
              yearEnd: endYear,
            }));
          }

          // if (typeof professional["degree"]["start_yr"] !== "undefined") {
          //   //I m using this becosue start is always less than end year
          //   let startFrom = professional["degree"]["start_yr"] + 1;
          //   let endYearList = await getYearList(startFrom);
          //   years["yearEndArr"] = endYearList;
          // }
        }
        if (professional["edu_level"]) {
          let educationLevel = [];
          educationLevel = {
            value: professional["edu_level"],
            label: professional["edu_level"],
          };
          setEducation((prevState) => ({
            ...prevState,
            educationLevel: educationLevel,
          }));
        }
        if (professional["subject"]) {
          let subject = [];
          subject = {
            value: professional["subject"],
            label: professional["subject"],
          };
          setEducation((prevState) => ({
            ...prevState,
            subjects: subject,
          }));
        }

        if (professional["profession"]) {
          let profess = [];
          profess = {
            value: professional["profession"],
            label: professional["profession"],
          };
          setEducation((prevState) => ({
            ...prevState,
            professionalProfile: profess,
          }));
        }
        if (professional["main_profession"]) {
          let main_profession = [];
          main_profession = {
            value: professional["main_profession"],
            label: professional["main_profession"],
          };
          setEducation((prevState) => ({
            ...prevState,
            mainProfession: main_profession,
          }));
        }
        if (professional["occupation"]) {
          updatedData["organization"] =
            professional["occupation"]["organisation"];
          updatedData["position"] = professional["occupation"]["position"];

          if (professional["occupation"]["start_yr"]) {
            let startYearPro = [];
            startYearPro = {
              value: professional["occupation"]["start_yr"],
              label: professional["occupation"]["start_yr"],
            };
            setEducation((prevState) => ({
              ...prevState,
              yearStartProf: startYearPro,
            }));
          }

          if (professional["occupation"]["end_yr"]) {
            let endYearPro = [];
            endYearPro = {
              value: professional["occupation"]["end_yr"],
              label: professional["occupation"]["end_yr"],
            };
            setEducation((prevState) => ({
              ...prevState,
              yearEndProf: endYearPro,
            }));
          }

          // if (typeof professional["occupation"]["start_yr"] !== "undefined") {
          //   //I m using this becosue start is always less than end year
          //   let startFromDate = professional["occupation"]["start_yr"] + 1;
          //   let endYearListProf = await getYearList(startFromDate);
          //   years["yearEndProfArr"] = endYearListProf;
          // }
        }
        if (Array.isArray(professional["skill_set"])) {
          updatedData["skillTest"] = professional["skill_set"]
            ? professional["skill_set"].join(",")
            : "";
        }

        updatedData["description"] = professional["description"];

        setEducation((prevState) => ({
          ...prevState,
          ...updatedData,
        }));
        setGetEducationalData((prevState) => ({
          ...prevState,
          ...years,
        }));
        // setStdCode(communication["std_code"]);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Submit form
  const submitForm = async (event) => {
    event.preventDefault();

    let professional = {};
    // professional['epic_no'] = epic_id

    professional["edu_level"] =
      education["educationLevel"] && education["educationLevel"]["value"];
    professional["subject"] =
      education["subjects"] && education["subjects"]["value"];

    professional["degree"] = {
      name: education["degree"],
      subject: education["subject"],
      institute: education["institution"],
      start_yr: education["yearStart"] && education["yearStart"]["value"],
      end_yr: education["yearEnd"] && education["yearEnd"]["value"],
    };
    professional["profession"] =
      education["professionalProfile"] &&
      education["professionalProfile"]["value"];
    professional["main_profession"] =
      education["mainProfession"] && education["mainProfession"]["value"];

    professional["occupation"] = {
      organisation: education["organization"],
      position: education["position"],
      start_yr:
        education["yearStartProf"] && education["yearStartProf"]["value"],
      end_yr: education["yearEndProf"] && education["yearEndProf"]["value"],
    };
    professional["skill_set"] = education["skillTest"].split(",");
    professional["description"] = education["description"];
    // send in api
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) { }

    let reqobject = {
      action: "/voter/save_voter_data",
      data: {
        professional,
        epic_no: epic_id,
      },
    };

    try {
      // let resp = await axiosGet("/voter/get_jansampark_count", params);
      const resp = await axiosPost(reqobject, checkuser);
      // console.log(resp);
      if (resp.data.resultFlag) {
        AlertBox("Success message", resp.data.message);
      } else {
        AlertBox("Error message", resp.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(education);
  return (
    <div>
      <VoterHeaderMenu>
        <div className="voter_container">
          <div className="voter_question2">
            <h6>Education Level*</h6>
            <div className="select_margin">
              <Select
                value={education.educationLevel}
                onChange={(e) => handleChange(e, "education")}
                className="basic-single"
                classNamePrefix="select"
                options={getEducationalData.educationLevelArr}
              />
            </div>
          </div>

          <div className="voter_question2">
            <h6>
              Subject*{" "}
              <span className="voter_subheading">(of main qualification)</span>{" "}
            </h6>
            <div className="select_margin">
              <Select
                value={education.subjects}
                onChange={(e) => handleChange(e, "subject")}
                className="basic-single"
                classNamePrefix="select"
                options={getEducationalData.subjectsArr}
              />
            </div>
          </div>

          <div className="voter_question2">
            <h6>Degree*</h6>
            {/* <div className="select_margin">
              <Select
                onChange={(e) => handleChange(e, "degree")}
                className="basic-single"
                classNamePrefix="select"
                options={getEducationalData.degreeArr}
              />
            </div> */}
            <input
              type="text"
              placeholder="Degree"
              name="degree"
              value={education.degree}
              onChange={updateForm}
            />
          </div>

          <div className="voter_question2">
            <h6>Subject*</h6>
            <input
              type="text"
              placeholder="Subject"
              name="subject"
              value={education.subject}
              onChange={updateForm}
            />
          </div>

          <div className="voter_question2">
            <h6>Institution*</h6>
            {/* <Select
              onChange={(e) => handleChange(e, 'institute')}
              className="basic-single"
              classNamePrefix="select"
              options={getEducationalData.institutionArr}
            /> */}
            <input
              type="text"
              placeholder="Institute"
              name="institution"
              value={education.institution}
              onChange={updateForm}
            />
          </div>

          <div className="voter_question2">
            <h6>Year*</h6>
            <div className="select_margin">
              <ul className="voter_year">
                <li>
                  <Select
                    value={education.yearStart}
                    onChange={(e) => handleChange(e, "yearStart")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={getEducationalData.yearStartArr}
                  />
                </li>
                <li>to</li>
                <li>
                  <Select
                    value={education.yearEnd}
                    onChange={(e) => handleChange(e, "yearEnd")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={getEducationalData.yearEndArr}
                  />
                </li>
              </ul>
            </div>
          </div>

          <div className="voter_question2">
            <h6>Professional Profile*</h6>
            <div className="select_margin">
              <Select
                value={education.professionalProfile}
                onChange={(e) => handleChange(e, "professional")}
                className="basic-single"
                classNamePrefix="select"
                options={getEducationalData.professionalProfileArr}
              />
            </div>
          </div>

          <div className="voter_question2">
            <h6>Main Profession*</h6>
            <div className="select_margin">
              <Select
                value={education.mainProfession}
                onChange={(e) => handleChange(e, "mainprofessional")}
                className="basic-single"
                classNamePrefix="select"
                options={getEducationalData.mainProfessionArr}
              />
            </div>
          </div>

          <div className="voter_question2">
            <h6>Organisation*</h6>
            <input
              type="text"
              placeholder="Organization"
              name="organization"
              value={education.organization}
              onChange={updateForm}
            />
            {/* <div className="select_margin">
              <Select
                onChange={(e) => handleChange(e, "organization")}
                className="basic-single"
                classNamePrefix="select"
                options={getEducationalData.organizationArr}
              />
            </div> */}
          </div>

          <div className="voter_question2">
            <h6>Position</h6>

            <input
              type="text"
              placeholder="Position"
              name="position"
              value={education.position}
              onChange={updateForm}
            />
          </div>

          <div className="voter_question2">
            <h6>Year*</h6>
            <div className="select_margin">
              <ul className="voter_year">
                <li>
                  <Select
                    value={education.yearStartProf}
                    onChange={(e) => handleChange(e, "yearStartProf")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={getEducationalData.yearStartProfArr}
                  />
                </li>
                <li>to</li>
                <li>
                  <Select
                    value={education.yearEndProf}
                    onChange={(e) => handleChange(e, "yearEndProf")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={getEducationalData.yearEndProfArr}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="voter_question2">
            <h6>Skill Set</h6>
            <input
              type="text"
              placeholder="Skill Set[seperated by ,]"
              name="skillTest"
              value={education.skillTest}
              onChange={updateForm}
            />
          </div>

          {/* <Skilltest skilData={skilData} tagsArray={tagsArray} /> */}

          <div className="voter_question2">
            <h6>Description</h6>

            <textarea
              type="text"
              placeholder="Description"
              name="description"
              value={education.description}
              onChange={updateForm}
            />
          </div>

          <div className="child_btn">
            <button onClick={submitForm}>UPDATE</button>
          </div>
        </div>
      </VoterHeaderMenu>
    </div>
  );
}

export { EducationalDetails };
