import React, { useState, useEffect } from "react";
import { VoterHeaderMenu } from "../VoterHeaderMenu";
import Select from "react-select";
import { axiosPost } from "../../../utils/commonAxios";
import { useParams } from "react-router-dom";
import {
  getPoliticalProfileList,
  getUnitList,
  getPartyList,
} from "./handleOnChange";
import {
  getYearList,
  getNumbers,
  relationType,
  politicalLeaning,
} from "../../common/Function";
import "./PoliticalDetails.css";
import { AlertBox } from "../../../components/common/AlertBox"
function PoliticalDetailts() {
  const { epic_id } = useParams();

  const [political, setPolitical] = useState({
    bjpMemberShipId: "",
    bjpNumberOfYearAss: "",
    rssNumberOfYearAss: "",
    contested: "",
    associatedWithOther: "",
    familyMemberInPolitics: "",
    familyMemberName: "",
    familyMemberProfile: "",
  });

  const [politicalArr, setPoliticalArr] = useState({
    politicalProfileInBjp: [],
    unit: [],
    designation: [],
    yearList: [],
    party: [],
    numberOfYearAss: [],
    partySupportedArr: [],
    partySupported: [],
    politicalLeaningArr: [],
    familyMemberRelationship: [],
  });
  const [contestType, setContestType] = useState("");

  useEffect(() => {
    getPoliticalDetails();
    setFormField();
  }, []);

  const getPoliticalDetails = async () => {
    try {
      //political profile api
      let political = await getPoliticalProfileList();
      setPoliticalArr((prevState) => ({
        ...prevState,
        politicalProfileInBjp: political,
        designation: political,
      }));

      //selectUnit
      let unitList = await getUnitList();
      setPoliticalArr((prevState) => ({
        ...prevState,
        unit: unitList,
      }));

      //years list
      let yearlist = await getYearList();
      setPoliticalArr((prevState) => ({
        ...prevState,
        yearList: yearlist,
      }));

      //party api
      let partyList = await getPartyList();
      setPoliticalArr((prevState) => ({
        ...prevState,
        party: partyList,
      }));

      //Number api
      let numbers = await getNumbers();
      console.log(numbers);
      setPoliticalArr((prevState) => ({
        ...prevState,
        numberOfYearAss: numbers,
      }));
      //Relation type
      let relation = await relationType();
      setPoliticalArr((prevState) => ({
        ...prevState,
        familyMemberRelationship: relation,
      }));
      //Relation type
      let leaning = await politicalLeaning();
      setPoliticalArr((prevState) => ({
        ...prevState,
        politicalLeaningArr: leaning,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const updateForm = (e) => {
    const { name, value } = e.target;
    setPolitical((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (selectedOpt, type) => {
    console.log(selectedOpt, type);
    setPolitical((prevState) => ({
      ...prevState,
      [type]: selectedOpt,
    }));
  };

  const setFormField = async () => {
    // epic_id
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) { }

    let reqobject = {
      action: "/voter/search",
      epic_no: epic_id,
    };

    try {
      const resp = await axiosPost(reqobject, checkuser);
      if (resp.data.resultFlag) {
        const { political } = resp.data.response;
        // console.log(political);
        let updatedData = {};
        //political leaning
        if (political["political_leaning"]) {
          let leaning = [];
          leaning = {
            label: political["political_leaning"],
            value: political["political_leaning"],
          };
          updatedData["selectedPoliticalLeaning"] = leaning;
        }

        updatedData["isProspect"] = political["is_prospect"];
        updatedData["prospectAdded"] = political["is_prospect_added"];
        updatedData["isNamoUser"] = political["is_namo_user"];
        updatedData["bjpProfile"] = political["is_bjg_profile"];

        // bjp_profile
        if (political["bjp_profile"]) {
          updatedData["bjpMemberShipId"] = political["bjp_profile"]["memb_id"];
          let unit = [];
          unit = {
            label: political["bjp_profile"]["unit"],
            value: political["bjp_profile"]["unit"],
          };
          updatedData["selectedUnit"] = unit;

          let joinYear = [];
          joinYear = {
            label: political["bjp_profile"]["join_yr"],
            value: political["bjp_profile"]["join_yr"],
          };
          updatedData["selectedJoinYear"] = joinYear;

          let leaveYear = [];
          leaveYear = {
            label: political["bjp_profile"]["leave_yr"],
            value: political["bjp_profile"]["leave_yr"],
          };
          updatedData["selectedLeaveYear"] = leaveYear;

          let degination = [];
          degination = {
            label: political["bjp_profile"]["designation"],
            value: political["bjp_profile"]["designation"],
          };
          updatedData["selectedDesignation"] = degination;

          let startYear = [];
          startYear = {
            label: political["bjp_profile"]["start_yr"],
            value: political["bjp_profile"]["start_yr"],
          };
          updatedData["selectedYearStart"] = startYear;

          let endYear = [];
          endYear = {
            label: political["bjp_profile"]["end_yr"],
            value: political["bjp_profile"]["end_yr"],
          };
          updatedData["selectedYearEnd"] = endYear;

          updatedData["description"] = political["bjp_profile"]["desc"];
        }

        // is_rss_profile
        updatedData["rssProfile"] = political["is_rss_profile"];

        // rss_profile
        if (political["rss_profile"]) {
          updatedData["rssMemberShipId"] = political["rss_profile"]["memb_id"];
          let unit = [];
          unit = {
            label: political["rss_profile"]["unit"],
            value: political["rss_profile"]["unit"],
          };
          updatedData["selectedRSSUnit"] = unit;
          let joinYear = [];
          joinYear = {
            label: political["rss_profile"]["join_yr"],
            value: political["rss_profile"]["join_yr"],
          };
          updatedData["selectedRSSJoinYear"] = joinYear;
          let leaveYear = [];
          leaveYear = {
            label: political["rss_profile"]["leave_yr"],
            value: political["rss_profile"]["leave_yr"],
          };
          updatedData["selectedRSSLeaveYear"] = leaveYear;
          let degination = [];
          degination = {
            label: political["rss_profile"]["designation"],
            value: political["rss_profile"]["designation"],
          };
          updatedData["selectedRSSDesignation"] = degination;
          let startYear = [];
          startYear = {
            label: political["rss_profile"]["start_yr"],
            value: political["rss_profile"]["start_yr"],
          };
          updatedData["selectedRSSYearStart"] = startYear;
          let endYear = [];
          endYear = {
            label: political["rss_profile"]["end_yr"],
            value: political["rss_profile"]["end_yr"],
          };
          updatedData["selectedRSSYearEnd"] = endYear;
          updatedData["RSSdescription"] = political["rss_profile"]["desc"];
        }

        // is_contested_elections
        updatedData["contested"] = political["is_contested_elections"];

        // contested_elections
        if (political["contested_elections"]) {
          updatedData["contestedName"] =
            political["contested_elections"]["name"];
          let selectedCParty = [];
          selectedCParty = {
            label: political["contested_elections"]["party"],
            value: political["contested_elections"]["party"],
          };
          updatedData["selectedContestedParty"] = selectedCParty;
          updatedData["isown"] = political["contested_elections"]["is_won"];
        }

        // is_associated_with_other
        updatedData["associatedWithOther"] =
          political["is_associated_with_other"];

        // oth_party_asso
        if (political["oth_party_asso"]) {
          //selected party
          let selectedParty = [];
          selectedParty = {
            label: political["oth_party_asso"]["name"],
            value: political["oth_party_asso"]["name"],
          };
          updatedData["selectedParty"] = selectedParty;

          //no of asso year
          let noass = [];
          noass = {
            label: political["oth_party_asso"]["no_of_years_asso_oth"],
            value: political["oth_party_asso"]["no_of_years_asso_oth"],
          };
          updatedData["selectedNOfYAss"] = noass;

          let joinYear = [];
          joinYear = {
            label: political["rss_profile"]["join_yr"],
            value: political["rss_profile"]["join_yr"],
          };
          updatedData["selectedOtherJoinYear"] = joinYear;

          let leaveYear = [];
          leaveYear = {
            label: political["rss_profile"]["leave_yr"],
            value: political["rss_profile"]["leave_yr"],
          };
          updatedData["selectedOtherLeaveYear"] = leaveYear;

          updatedData["otherDescription"] = political["oth_party_asso"]["desc"];
        }

        // is_family_member_in_politics
        updatedData["familyMemberInPolitics"] =
          political["is_family_member_in_politics"];

        // family_asso
        if (political["family_asso"]) {
          updatedData["familyMemberName"] = political["family_asso"]["name"];
          let relation = [];
          relation = {
            label: political["family_asso"]["relation"],
            value: political["family_asso"]["relation"],
          };
          updatedData["selectedRelation"] = relation;
          let selectParty = [];
          selectParty = {
            label: political["family_asso"]["party"],
            value: political["family_asso"]["party"],
          };
          updatedData["selectedFamilyParty"] = selectParty;
          updatedData["epic_no"] = political["family_asso"]["epic_no"];
          updatedData["familyDescription"] = political["family_asso"]["desc"];
        }

        // party_supported
        if (political["party_supported"]) {
          let partySupported = [];
          partySupported = {
            value: political["party_supported"],
            label: political["party_supported"],
          };
          updatedData["partySupported"] = partySupported;
          // setPolitical((prevState) => ({
          //   ...prevState,
          //   partySupported: partySupported,
          // }));
        }

        if (political["contested_elections"]["seat"]) {
          setContestType(political["contested_elections"]["seat"]);
        }

        setPolitical((prevState) => ({
          ...prevState,
          ...updatedData,
        }));
      }
    } catch (error) {
      // alert(error.message);
    }
  };

  //Submit form
  const submitForm = async (event) => {
    event.preventDefault();
    console.log(political);
    let politics = {};
    politics["political_leaning"] =
      political["selectedPoliticalLeaning"] &&
      political["selectedPoliticalLeaning"]["value"];
    // politics["political_profile"] =
    //   political["selectedPoliticalLeaning"] &&
    //   political["selectedPoliticalLeaning"]["value"];

    politics["party_supported"] =
      political["partySupported"] && political["partySupported"]["value"];
    politics["is_prospect"] = political["isProspect"]
      ? political["isProspect"]
      : "no";
    politics["is_prospect_added"] = political["prospectAdded"]
      ? political["prospectAdded"]
      : "no";
    politics["is_namo_user"] = political["isNamoUser"]
      ? political["isNamoUser"]
      : "no";
    politics["is_bjg_profile"] = political["bjpProfile"]
      ? political["bjpProfile"]
      : "no";
    if (political.bjpProfile === "yes") {
      politics["bjp_profile"] = {
        memb_id: political["bjpMemberShipId"]
          ? political["bjpMemberShipId"]
          : "",
        unit: political["selectedUnit"] && political["selectedUnit"]["value"],
        join_yr:
          political["selectedJoinYear"] &&
          political["selectedJoinYear"]["value"],
        leave_yr:
          political["selectedLeaveYear"] &&
          political["selectedLeaveYear"]["value"],
        designation:
          political["selectedDesignation"] &&
          political["selectedDesignation"]["value"],
        start_yr:
          political["selectedYearStart"] &&
          political["selectedYearStart"]["value"],
        end_yr:
          political["selectedYearEnd"] && political["selectedYearEnd"]["value"],
        desc: political["description"],
      };
    }

    politics["is_rss_profile"] = political["rssProfile"]
      ? political["rssProfile"]
      : "no";
    if (political.rssProfile === "yes") {
      politics["rss_profile"] = {
        memb_id: political["rssMemberShipId"],
        unit:
          political["selectedRSSUnit"] && political["selectedRSSUnit"]["value"],
        join_yr:
          political["selectedRSSJoinYear"] &&
          political["selectedRSSJoinYear"]["value"],
        leave_yr:
          political["selectedRSSLeaveYear"] &&
          political["selectedRSSLeaveYear"]["value"],
        designation:
          political["selectedRSSDesignation"] &&
          political["selectedRSSDesignation"]["value"],
        start_yr:
          political["selectedRSSYearStart"] &&
          political["selectedRSSYearStart"]["value"],
        end_yr:
          political["selectedRSSYearEnd"] &&
          political["selectedRSSYearEnd"]["value"],
        desc: political["RSSdescription"],
      };
    }

    politics["is_contested_elections"] = political["contested"]
      ? political["contested"]
      : "no";
    if (political.contested === "yes") {
      politics["contested_elections"] = {
        // name: political["contestedName"],
        seat: contestType,
        party:
          political["selectedContestedParty"] &&
          political["selectedContestedParty"]["value"],
        is_won: political["isown"],
      };
    }
    politics["is_associated_with_other"] = political["associatedWithOther"]
      ? political["associatedWithOther"]
      : "no";
    if (political.associatedWithOther === "yes") {
      politics["oth_party_asso"] = {
        name: political["selectedParty"] && political["selectedParty"]["value"],
        no_of_years_asso_oth:
          political["selectedNOfYAss"] && political["selectedNOfYAss"]["value"],
        join_yr:
          political["selectedOtherJoinYear"] &&
          political["selectedOtherJoinYear"]["value"],
        leave_yr:
          political["selectedOtherLeaveYear"] &&
          political["selectedOtherLeaveYear"]["value"],
        desc: political["otherDescription"],
      };
    }

    politics["is_family_member_in_politics"] = political[
      "familyMemberInPolitics"
    ]
      ? political["familyMemberInPolitics"]
      : "no";
    if (political.familyMemberInPolitics === "yes") {
      politics["family_asso"] = {
        name: political["familyMemberName"],
        relation:
          political["selectedRelation"] &&
          political["selectedRelation"]["value"],
        party:
          political["selectedFamilyParty"] &&
          political["selectedFamilyParty"]["value"],
        epic_no: political["epic_no"],
        desc: political["familyDescription"],
      };
    }

    // send in api
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) { }

    let reqobject = {
      action: "/voter/save_voter_data",
      data: {
        politics,
        epic_no: epic_id,
      },
    };

    try {
      // let resp = await axiosGet("/voter/get_jansampark_count", params);
      const resp = await axiosPost(reqobject, checkuser);
      // console.log(resp);
      if (resp.data.resultFlag) {
        AlertBox("Success message", resp.data.message);
      } else {
        AlertBox("Error message", resp.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkHandler = (e) => {
    setContestType(e.target.value);
  };

  // console.log(politicalArr.party);
  // console.log(political.selectedParty);
  // console.log(contestType);

  return (
    <div>
      <VoterHeaderMenu>
        <div className="voter_container">
          {/* <div className="voter_question2">
            <h6>Political Leaning*</h6>
            <div className="select_margin">
              <input
                type="text"
                placeholder="Political Leaning"
                name="politicalLeaning"
                value={political.politicalLeaning}
                onChange={updateForm}
              />
            </div>
          </div> */}

          <div className="voter_question2">
            <h6>Political Leaning</h6>
            <div className="select_margin">
              <Select
                value={political.selectedPoliticalLeaning}
                onChange={(e) => handleChange(e, "selectedPoliticalLeaning")}
                className="basic-single"
                classNamePrefix="select"
                options={politicalArr.politicalLeaningArr}
              />
            </div>
          </div>
          {/* <div className="voter_question2">
            <h6>BJP Membership ID*</h6>
            <input
              type="text"
              placeholder="BJP Membership ID"
              name="politicalLeaning"
              value={political.politicalLeaning}
              onChange={updateForm}
            />
          </div>

          <div className="voter_question2">
            <h6>BJP (No of years associated)*</h6>
            <input
              type="text"
              placeholder="BJP (No of years associated)"
              name="politicalLeaning"
              value={political.politicalLeaning}
              onChange={updateForm}
            />
          </div>

          <div className="voter_question2">
            <h6>RSS (No of years associated)*</h6>
            <input
              type="text"
              placeholder="RSS (No of years associated)"
              name="politicalLeaning"
              value={political.politicalLeaning}
              onChange={updateForm}
            />
          </div> */}

          {/* <div className="voter_question2">
            <h6>Political Profile (in BJP)</h6>
            <div className="select_margin">
              <Select
                value={political.partySupported}
                onChange={(e) => handleChange(e, "partySupported")}
                className="basic-single"
                classNamePrefix="select"
                options={politicalArr.party}
              />
            </div>
          </div> */}
          {/* {political.selectedPoliticalLeaning &&
            political.selectedPoliticalLeaning.value == "supporter" && ( */}
          {/* <div className="voter_question2">
            <h6>Political Profile (in BJP)</h6>
            <div className="select_margin">
              <Select
                value={political.selectedProfile}
                onChange={(e) => handleChange(e, "selectedProfile")}
                className="basic-single"
                classNamePrefix="select"
                options={politicalArr.politicalProfileInBjp}
              />
            </div>
          </div> */}
          {/* )} */}

          {/* <div className="voter_question2">
            <h6>Select Unit*</h6>
            <div className="select_margin">
              <Select
                value={political.partySupported}
                onChange={(e) => handleChange(e, "partySupported")}
                className="basic-single"
                classNamePrefix="select"
                options={politicalArr.party}
              />
            </div>
          </div> */}

          {/* <div className="voter_question2">
            <h6>Select Designation*</h6>
            <div className="select_margin">
              <Select
                value={political.partySupported}
                onChange={(e) => handleChange(e, "partySupported")}
                className="basic-single"
                classNamePrefix="select"
                options={politicalArr.party}
              />
            </div>
          </div> */}
          {/* {political.selectedPoliticalLeaning &&
            political.selectedPoliticalLeaning.value == "supporter" && ( */}
          {/* <div className="voter_question2">
            <h6>Year*</h6>
            <div className="select_margin">
              <ul className="voter_year">
                <li>
                  <Select
                    value={political.selectedYearStart}
                    onChange={(e) => handleChange(e, "yearStart")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={politicalArr.yearList}
                  />
                </li>
                <li>to</li>
                <li>
                  <Select
                    value={political.selectedYearEnd}
                    onChange={(e) => handleChange(e, "yearEnd")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={politicalArr.yearList}
                  />
                </li>
              </ul>
            </div>
          </div> */}
          {/* )} */}

          <div className="voter_question2">
            <h6>Contested Elections ?</h6>
            <ul className="gender_listing pol_listing">
              <li>
                <label className="personal_container">
                  Yes
                  <input
                    type="radio"
                    id="yes"
                    value="yes"
                    name="contested"
                    checked={political.contested === "yes" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>

              <li>
                <label className="personal_container">
                  No
                  <input
                    type="radio"
                    id="no"
                    value="no"
                    name="contested"
                    checked={political.contested === "no" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>
            </ul>
          </div>
          {political.contested === "yes" && (
            <React.Fragment>
              <div className="voter_checkbox">
                <ul>
                  <li>
                    <label className="container_check">
                      <input
                        type="radio"
                        checked={contestType == "loksabha" ? true : false}
                        name="loksabha"
                        value="loksabha"
                        onChange={checkHandler}
                      />
                      Loksabha <span className="checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="container_check">
                      <input
                        type="radio"
                        checked={contestType == "vidhansabha" ? true : false}
                        name="vidhansabha"
                        value="vidhansabha"
                        onChange={checkHandler}
                      />
                      Vidhansabha <span className="checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="container_check">
                      <input
                        type="radio"
                        name="MLC"
                        checked={contestType == "MLC" ? true : false}
                        value="MLC"
                        onChange={checkHandler}
                      />
                      MLC <span className="checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="container_check">
                      <input
                        type="radio"
                        name="localbody"
                        checked={contestType == "localbody" ? true : false}
                        value="localbody"
                        onChange={checkHandler}
                      />
                      Localbody <span className="checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="container_check">
                      <input
                        type="radio"
                        name="any other"
                        checked={contestType == "any other" ? true : false}
                        value="any other"
                        onChange={checkHandler}
                      />
                      Any Other <span className="checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>

              <div className="voter_question2">
                <h6>Party</h6>
                <Select
                  value={political.selectedContestedParty}
                  onChange={(e) => handleChange(e, "selectedContestedParty")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={politicalArr.party}
                />
              </div>
              <div className="voter_question2">
                <h6>Is Own ?</h6>
                <ul className="gender_listing pol_listing">
                  <li>
                    <label className="personal_container">
                      Yes
                      <input
                        type="radio"
                        id="yes"
                        value="yes"
                        name="isown"
                        checked={political.isown === "yes" && true}
                        onChange={(element) => updateForm(element)}
                      />
                      <span className="personal_checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="personal_container">
                      No
                      <input
                        type="radio"
                        id="no"
                        value="no"
                        name="isown"
                        checked={political.isown === "no" && true}
                        onChange={(element) => updateForm(element)}
                      />
                      <span className="personal_checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          )}
          <div className="voter_question2">
            <h6>Is Prospect ?</h6>
            <ul className="gender_listing pol_listing">
              <li>
                <label className="personal_container">
                  Yes
                  <input
                    type="radio"
                    id="yes"
                    value="yes"
                    name="isProspect"
                    checked={political.isProspect === "yes" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>

              <li>
                <label className="personal_container">
                  No
                  <input
                    type="radio"
                    id="no"
                    value="no"
                    name="isProspect"
                    checked={political.isProspect === "no" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>
            </ul>
          </div>

          {political.isProspect === "yes" && (
            <div className="voter_question2">
              <h6>Is Prospect Added ?</h6>
              <ul className="gender_listing pol_listing">
                <li>
                  <label className="personal_container">
                    Yes
                    <input
                      type="radio"
                      id="yes"
                      value="yes"
                      name="prospectAdded"
                      checked={political.prospectAdded === "yes" && true}
                      onChange={(element) => updateForm(element)}
                    />
                    <span className="personal_checkmark"></span>
                  </label>
                </li>

                <li>
                  <label className="personal_container">
                    No
                    <input
                      type="radio"
                      id="no"
                      value="no"
                      name="prospectAdded"
                      checked={political.prospectAdded === "no" && true}
                      onChange={(element) => updateForm(element)}
                    />
                    <span className="personal_checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>
          )}
          {/* is nanouser */}
          <div className="voter_question2">
            <h6>Is Namo User ?</h6>
            <ul className="gender_listing pol_listing">
              <li>
                <label className="personal_container">
                  Yes
                  <input
                    type="radio"
                    id="yes"
                    value="yes"
                    name="isNamoUser"
                    checked={political.isNamoUser === "yes" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>

              <li>
                <label className="personal_container">
                  No
                  <input
                    type="radio"
                    id="no"
                    value="no"
                    name="isNamoUser"
                    checked={political.isNamoUser === "no" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>
            </ul>
          </div>

          <div className="voter_question2">
            <h6>BJP Profile ?</h6>
            <ul className="gender_listing pol_listing">
              <li>
                <label className="personal_container">
                  Yes
                  <input
                    type="radio"
                    id="yes"
                    value="yes"
                    name="bjpProfile"
                    checked={political.bjpProfile === "yes" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>

              <li>
                <label className="personal_container">
                  No
                  <input
                    type="radio"
                    id="no"
                    value="no"
                    name="bjpProfile"
                    checked={political.bjpProfile === "no" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>
            </ul>
          </div>

          {political.bjpProfile === "yes" && (
            <React.Fragment>
              <div className="voter_question2">
                <h6>BJP Membership ID*</h6>
                <input
                  type="text"
                  placeholder="Member ID"
                  name="bjpMemberShipId"
                  value={political.bjpMemberShipId}
                  onChange={updateForm}
                />
              </div>

              <div className="voter_question2">
                <div className="select_margin">
                  <h6>Select Unit*</h6>
                  <Select
                    value={political.selectedUnit}
                    onChange={(e) => handleChange(e, "selectedUnit")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={politicalArr.unit}
                  />
                </div>
              </div>

              <div className="voter_question2">
                <div className="select_margin">
                  <h6>Join Year*</h6>
                  <ul className="voter_year">
                    <li>
                      <Select
                        value={political.selectedJoinYear}
                        onChange={(e) => handleChange(e, "selectedJoinYear")}
                        className="basic-single"
                        classNamePrefix="select"
                        options={politicalArr.yearList}
                      />
                    </li>
                    <li>to</li>
                    <li>
                      <Select
                        value={political.selectedLeaveYear}
                        onChange={(e) => handleChange(e, "selectedLeaveYear")}
                        className="basic-single"
                        classNamePrefix="select"
                        options={politicalArr.yearList}
                      />
                    </li>
                  </ul>
                </div>
              </div>

              <div className="voter_question2">
                <div className="select_margin">
                  <h6>Select Designation*</h6>
                  <Select
                    value={political.selectedDesignation}
                    onChange={(e) => handleChange(e, "selectedDesignation")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={politicalArr.designation}
                  />
                </div>
              </div>

              <div className="voter_question2">
                <div className="select_margin">
                  <h6>Year*</h6>
                  <ul className="voter_year">
                    <li>
                      <Select
                        value={political.selectedYearStart}
                        onChange={(e) => handleChange(e, "selectedYearStart")}
                        className="basic-single"
                        classNamePrefix="select"
                        options={politicalArr.yearList}
                      />
                    </li>
                    <li>to</li>
                    <li>
                      <Select
                        value={political.selectedYearEnd}
                        onChange={(e) => handleChange(e, "selectedYearEnd")}
                        className="basic-single"
                        classNamePrefix="select"
                        options={politicalArr.yearList}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="voter_question2">
                <h6>Description</h6>
                <textarea
                  type="text"
                  placeholder="Description"
                  name="description"
                  value={political.description}
                  onChange={updateForm}
                />
              </div>
            </React.Fragment>
          )}

          {/* Is RSS ? */}
          <div className="voter_question2">
            <h6>RSS Profile ?</h6>
            <ul className="gender_listing pol_listing">
              <li>
                <label className="personal_container">
                  Yes
                  <input
                    type="radio"
                    id="yes"
                    value="yes"
                    name="rssProfile"
                    checked={political.rssProfile === "yes" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>

              <li>
                <label className="personal_container">
                  No
                  <input
                    type="radio"
                    id="no"
                    value="no"
                    name="rssProfile"
                    checked={political.rssProfile === "no" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>
            </ul>
          </div>

          {political.rssProfile === "yes" && (
            <React.Fragment>
              <div className="voter_question2">
                <h6>RSS Membership ID*</h6>
                <input
                  type="text"
                  placeholder="Member ID"
                  name="rssMemberShipId"
                  value={political.rssMemberShipId}
                  onChange={updateForm}
                />
              </div>

              <div className="voter_question2">
                <div className="select_margin">
                  <h6>Select Unit*</h6>
                  <Select
                    value={political.selectedRSSUnit}
                    onChange={(e) => handleChange(e, "selectedRSSUnit")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={politicalArr.unit}
                  />
                </div>
              </div>

              <div className="voter_question2">
                <h6>Join Year*</h6>
                <div className="select_margin">
                  <ul className="voter_year">
                    <li>
                      <Select
                        value={political.selectedRSSJoinYear}
                        onChange={(e) => handleChange(e, "selectedRSSJoinYear")}
                        className="basic-single"
                        classNamePrefix="select"
                        options={politicalArr.yearList}
                      />
                    </li>
                    <li>to</li>
                    <li>
                      <Select
                        value={political.selectedRSSLeaveYear}
                        onChange={(e) =>
                          handleChange(e, "selectedRSSLeaveYear")
                        }
                        className="basic-single"
                        classNamePrefix="select"
                        options={politicalArr.yearList}
                      />
                    </li>
                  </ul>
                </div>
              </div>

              <div className="voter_question2">
                <div className="select_margin">
                  <h6>Select Designation*</h6>
                  <Select
                    value={political.selectedRSSDesignation}
                    onChange={(e) => handleChange(e, "selectedRSSDesignation")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={politicalArr.designation}
                  />
                </div>
              </div>

              <div className="voter_question2">
                <h6>Year*</h6>
                <div className="select_margin">
                  <ul className="voter_year">
                    <li>
                      <Select
                        value={political.selectedRSSYearStart}
                        onChange={(e) =>
                          handleChange(e, "selectedRSSYearStart")
                        }
                        className="basic-single"
                        classNamePrefix="select"
                        options={politicalArr.yearList}
                      />
                    </li>
                    <li>to</li>
                    <li>
                      <Select
                        value={political.selectedRSSYearEnd}
                        onChange={(e) => handleChange(e, "selectedRSSYearEnd")}
                        className="basic-single"
                        classNamePrefix="select"
                        options={politicalArr.yearList}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="voter_question2">
                <h6>Description</h6>
                <textarea
                  type="text"
                  placeholder="Description"
                  name="RSSdescription"
                  value={political.RSSdescription}
                  onChange={updateForm}
                />
              </div>
            </React.Fragment>
          )}

          {/* <div className="voter_question2">
            <h6>Contested Elections ?</h6>
            <ul className="gender_listing pol_listing">
              <li>
                <label className="personal_container">
                  Yes
                  <input
                    type="radio"
                    id="yes"
                    value="yes"
                    name="contested"
                    checked={political.contested === "yes" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>

              <li>
                <label className="personal_container">
                  No
                  <input
                    type="radio"
                    id="no"
                    value="no"
                    name="contested"
                    checked={political.contested === "no" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>
            </ul>
          </div>

          {political.contested === "yes" && (
            <React.Fragment>
              <div className="voter_question2">
                <h6>Name*</h6>
                <input
                  type="text"
                  placeholder="Name"
                  name="contestedName"
                  value={political.contestedName}
                  onChange={updateForm}
                />
              </div>
              <div className="voter_question2">
                <h6>Seat*</h6>
                <input
                  type="text"
                  placeholder="Seat"
                  name="contestedSeat"
                  value={political.contestedSeat}
                  onChange={updateForm}
                />
              </div>
              <div className="voter_question2">
                <h6>Party</h6>
                <Select
                  value={political.selectedContestedParty}
                  onChange={(e) => handleChange(e, "selectedContestedParty")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={politicalArr.party}
                />
              </div>
              <div className="voter_question2">
                <h6>Is Own ?</h6>
                <ul className="gender_listing pol_listing">
                  <li>
                    <label className="personal_container">
                      Yes
                      <input
                        type="radio"
                        id="yes"
                        value="yes"
                        name="isown"
                        checked={political.isown === "yes" && true}
                        onChange={(element) => updateForm(element)}
                      />
                      <span className="personal_checkmark"></span>
                    </label>
                  </li>

                  <li>
                    <label className="personal_container">
                      No
                      <input
                        type="radio"
                        id="no"
                        value="no"
                        name="isown"
                        checked={political.isown === "no" && true}
                        onChange={(element) => updateForm(element)}
                      />
                      <span className="personal_checkmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </React.Fragment>
          )} */}

          <div className="voter_question2">
            <h6>Associated With Other Parties</h6>
            <ul className="gender_listing pol_listing">
              <li>
                <label className="personal_container">
                  Yes
                  <input
                    type="radio"
                    id="yes"
                    value="yes"
                    name="associatedWithOther"
                    checked={political.associatedWithOther === "yes" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>

              <li>
                <label className="personal_container">
                  No
                  <input
                    type="radio"
                    id="no"
                    value="no"
                    name="associatedWithOther"
                    checked={political.associatedWithOther === "no" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>
            </ul>
          </div>

          {political.associatedWithOther === "yes" && (
            <React.Fragment>
              {/* <div className="voter_question2">
                <h6>Name*</h6>
                <input
                  type="text"
                  placeholder="Name"
                  name="otherName"
                  value={political.otherName}
                  onChange={updateForm}
                />
              </div> */}

              <div className="voter_question2">
                <h6>Party*</h6>
                <div className="select_margin">
                  <Select
                    value={political.selectedParty}
                    onChange={(e) => handleChange(e, "selectedParty")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={politicalArr.party}
                  />
                </div>
              </div>

              <div className="voter_question2">
                <h6>No of years associated*</h6>
                <div className="select_margin">
                  <Select
                    value={political.selectedNOfYAss}
                    onChange={(e) => handleChange(e, "selectedNOfYAss")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={politicalArr.numberOfYearAss}
                  />
                </div>
              </div>

              <div className="voter_question2">
                <h6>Year*</h6>
                <div className="select_margin">
                  <ul className="voter_year">
                    <li>
                      <Select
                        value={political.selectedOtherJoinYear}
                        onChange={(e) =>
                          handleChange(e, "selectedOtherJoinYear")
                        }
                        className="basic-single"
                        classNamePrefix="select"
                        options={politicalArr.yearList}
                      />
                    </li>
                    <li>to</li>
                    <li>
                      <Select
                        value={political.selectedOtherLeaveYear}
                        onChange={(e) =>
                          handleChange(e, "selectedOtherLeaveYear")
                        }
                        className="basic-single"
                        classNamePrefix="select"
                        options={politicalArr.yearList}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="voter_question2">
                <h6>Description</h6>
                <textarea
                  type="text"
                  placeholder="Description"
                  name="otherDescription"
                  value={political.otherDescription}
                  onChange={updateForm}
                />
              </div>
            </React.Fragment>
          )}

          <div className="voter_question2">
            <h6>Any Family Member in Politics</h6>
            <ul className="gender_listing pol_listing">
              <li>
                <label className="personal_container">
                  Yes
                  <input
                    type="radio"
                    id="yes"
                    value="yes"
                    name="familyMemberInPolitics"
                    checked={political.familyMemberInPolitics === "yes" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>

              <li>
                <label className="personal_container">
                  No
                  <input
                    type="radio"
                    id="no"
                    value="no"
                    name="familyMemberInPolitics"
                    checked={political.familyMemberInPolitics === "no" && true}
                    onChange={(element) => updateForm(element)}
                  />
                  <span className="personal_checkmark"></span>
                </label>
              </li>
            </ul>
          </div>

          {political.familyMemberInPolitics === "yes" && (
            <React.Fragment>
              <div className="voter_question2">
                <h6>Name</h6>
                <input
                  type="text"
                  placeholder="Name"
                  name="familyMemberName"
                  value={political.familyMemberName}
                  onChange={updateForm}
                />
              </div>

              <div className="voter_question2">
                <h6>Relationship</h6>
                <div className="select_margin">
                  <Select
                    value={political.selectedRelation}
                    onChange={(e) => handleChange(e, "selectedRelation")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={politicalArr.familyMemberRelationship}
                  />
                </div>
              </div>
              <div className="voter_question2">
                <h6>Party</h6>
                <Select
                  value={political.selectedFamilyParty}
                  onChange={(e) => handleChange(e, "selectedFamilyParty")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={politicalArr.party}
                />
              </div>
              <div className="voter_question2">
                <h6>Epic No</h6>
                <input
                  type="text"
                  placeholder="Epic Number"
                  name="epic_no"
                  value={political.epic_no}
                  onChange={updateForm}
                />
              </div>
              {/* <div className="voter_question2">
                <h6>Description</h6>
                <textarea
                  type="text"
                  placeholder="Description"
                  name="familyDescription"
                  value={political.familyDescription}
                  onChange={updateForm}
                />
              </div> */}
            </React.Fragment>
          )}

          <div className="child_btn">
            <button onClick={submitForm}>UPDATE</button>
          </div>
        </div>
      </VoterHeaderMenu>
    </div>
  );
}

export { PoliticalDetailts };

{
  /* <div className="voter_question2">
    <h6>BJP Membership ID*</h6>
    <input type="text" placeholder="Member ID" name="bjpMemberShipId" value={political.bjpMemberShipId} onChange={updateForm} />
  </div>

  <div className="voter_question2">
    <h6>BJP (No of years associated)*</h6>
    <input type="number" placeholder="BJP (No of years associated)" name="bjpNumberOfYearAss" value={political.bjpNumberOfYearAss} onChange={updateForm} />
  </div>

  <div className="voter_question2">
    <h6>RSS (No of years associated)*</h6>
    <input type="number" placeholder="RSS (No of years associated)" name="rssNumberOfYearAss" value={political.rssNumberOfYearAss} onChange={updateForm} />
  </div> */
}

{
  /* <div className="voter_question2">
    <h6>Political Profile (in BJP)*</h6>
    
    <Select
      value={political.selectedProfile}
      onChange={(e) => handleChange(e, 'profile')}
      className="basic-single"
      classNamePrefix="select"
      options={political.politicalProfileInBjp}
    />
  </div>

  <div className="voter_question2">
    <h6>Select Unit*</h6>
    <Select
      value={political.selectedUnit}
      onChange={(e) => handleChange(e, 'unit')}
      className="basic-single"
      classNamePrefix="select"
      options={political.unit}
    />
  </div>

  <div className="voter_question2">
    <h6>Select Designation*</h6>
    <Select
      value={political.selectedDesignation}
      onChange={(e) => handleChange(e, 'designation')}
      className="basic-single"
      classNamePrefix="select"
      options={political.designation}
    />
  </div>

  <div className="voter_question2">
    <h6>Year*</h6>
    <ul className="voter_year">
      <li>
        <Select
          value={political.selectedYearStart}
          onChange={(e) => handleChange(e, 'yearStart')}
          className="basic-single"
          classNamePrefix="select"
          options={political.yearList}
        />
      </li>
      <li>to</li>
      <li>
        <Select
          value={political.selectedYearEnd}
          onChange={(e) => handleChange(e, 'yearEnd')}
          className="basic-single"
          classNamePrefix="select"
          options={political.yearEnd}
        />
      </li>
    </ul>
  </div> */
}

{
  /* <div className="voter_question2">
                <h6>Party</h6>
                  <Select
                    value={political.selectedParty}
                    onChange={(e) => handleChange(e, 'selectedParty')}
                    className="basic-single"
                    classNamePrefix="select"
                    options={political.party}
                />
              </div>

              <div className="voter_question2">
                <h6>No of years associated</h6>
                <Select
                  value={political.selectedNOfYAss}
                  onChange={(e) => handleChange(e, 'selectedNOfYAss')}
                  className="basic-single"
                  classNamePrefix="select"
                  options={political.numberOfYearAss}
                />
              </div> */
}

//   <div className="voter_question2">
//   <h6>Profile</h6>
//   <input type="text" placeholder="Profile" name="familyMemberProfile" value={political.familyMemberProfile} onChange={updateForm} />
// </div>

// <div className="voter_checkbox political_check">
//   <ul>
//     <li>
//       <label className="container_check">
//         <input type="checkbox" />
//         Loksabha <span className="checkmark"></span>
//       </label>
//     </li>
//     <li>
//       <label className="container_check">
//         <input type="checkbox" />
//         Vidhansabha <span className="checkmark"></span>
//       </label>
//     </li>
//     <li>
//       <label className="container_check">
//         <input type="checkbox" />
//         MLC <span className="checkmark"></span>
//       </label>
//     </li>
//     <li>
//       <label className="container_check">
//         <input type="checkbox" />
//         Localbody <span className="checkmark"></span>
//       </label>
//     </li>

//     <li>
//       <label className="container_check">
//         <input type="checkbox" />
//         Any Other <span className="checkmark"></span>
//       </label>
//     </li>
//   </ul>
// </div>

{
  /* <div className="select_margin">
              <ul className="voter_year">
                <li>
                  <Select
                    value={education.yearStart}
                    onChange={(e) => handleChange(e, "yearStart")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={getEducationalData.yearStartArr}
                  />
                </li>
                <li>to</li>
                <li>
                  <Select
                    value={education.yearEnd}
                    onChange={(e) => handleChange(e, "yearEnd")}
                    className="basic-single"
                    classNamePrefix="select"
                    options={getEducationalData.yearEndArr}
                  />
                </li>
              </ul>
            </div> */
}

{
  /* <div className="voter_checkbox">
                <ul>
                  <li>
                    <label className="container_check2">
                      <input type="checkbox" value="nutral" />
                      Loksabha <span className="checkmark2"></span>
                    </label>
                  </li>

                  <li>
                    <label className="container_check2">
                      <input type="checkbox" value="nutral" />
                      Vidhansabha <span className="checkmark2"></span>
                    </label>
                  </li>

                  <li>
                    <label className="container_check2">
                      <input type="checkbox" value="party member" />
                      MLC <span className="checkmark2"></span>
                    </label>
                  </li>

                  <li>
                    <label className="container_check2">
                      <input type="checkbox" value="supporter" />
                      Localbody <span className="checkmark2"></span>
                    </label>
                  </li>

                  <li>
                    <label className="container_check2">
                      <input type="checkbox" value="opposite" />
                      Any Other <span className="checkmark2"></span>
                    </label>
                  </li>
                </ul>
              </div> */
}
