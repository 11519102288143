import { appLanguageData } from "../../../utils/language";
import { axiosGet } from "../../../utils/commonAxios";

export const religionsList = async () => {
  try {
    //fetch religion data get api
    let regilionsListData = [];
    let regilions = await axiosGet("/religion/list");
    {
      regilions.data.response &&
        regilions.data.response.map((data, index) => {
          let title = appLanguageData(data.json_language_data, "title");
          regilionsListData.push({ value: title, label: title });
        });
    }

    return regilionsListData;
  } catch (error) {}
};

export const categoryList = async () => {
  try {
    //fetch category data get api
    let categoryList = [];
    let catResp = await axiosGet("/category/list");
    //get title from language json
    {
      catResp.data.response &&
        catResp.data.response.map((data) => {
          let title = appLanguageData(data.json_language_data, "title");
          categoryList.push({ value: title, label: title });
        });
    }
    return categoryList;
  } catch (error) {}
};

export const languageList = async () => {
  try {
    //fetch category data get api
    let languageListArr = [];
    let LanguageResp = await axiosGet("/language/list");
    //get title from language json
    {
      LanguageResp.data.response &&
        LanguageResp.data.response.map((data) => {
          // languageListArr.push({ value: data.code, label: data.name });
          languageListArr.push({ value: data.code, label: data.name });
        });
    }
    return languageListArr;
  } catch (error) {}
};

export const stateList = async () => {
  try {
    //fetch State data get api
    let stateListData = [];
    let StateResp = await axiosGet("/state/list");
    {
      StateResp.data.response &&
        StateResp.data.response.map((data) => {
          stateListData.push({ value: data.code, label: data.name });
        });
    }

    return stateListData;
  } catch (error) {}
};

export const districtList = async (code) => {
  try {
    //fetch district data get api
    let districtListData = [];
    let DitrictResp = await axiosGet(`/district/list?state_code=${code}`);
    {
      DitrictResp.data.response &&
        DitrictResp.data.response.map((data) => {
          districtListData.push({ value: data.code, label: data.name });
        });
    }

    return districtListData;
  } catch (error) {}
};
