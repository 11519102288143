import React from "react";
import SectorListComponent from "../../components/sector/SectorListComponent";

function SectorPage() {
  return (
    <div>
      <SectorListComponent />
    </div>
  );
}

export default SectorPage;
