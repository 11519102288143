import { axiosGet } from "../utils/commonAxios";
import {
  VOTER_KARYKARTA_LIST_EMPTY,
  VOTER_KARYKARTA_LIST_ERROR,
  VOTER_KARYKARTA_LIST_PENDING,
  VOTER_KARYKARTA_LIST_SUCCESS,
} from "./types";

export const voterKarykartaList = (booth_code) => {
  return async (dispatch) => {
    dispatch({
      type: VOTER_KARYKARTA_LIST_PENDING,
    });

    try {
      let resp = await axiosGet(
        "/electoral_list/getkaryakartalist?booth_code=" + booth_code
      );
      dispatch({
        type: VOTER_KARYKARTA_LIST_SUCCESS,
        payload: resp.data.response,
      });
    } catch (error) {
      dispatch({
        type: VOTER_KARYKARTA_LIST_ERROR,
      });
    }
  };
};

export const cleanVoterKarykartaList = () => {
  return (dispatch) => {
    dispatch({
      type: VOTER_KARYKARTA_LIST_EMPTY,
    });
  };
};
