import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import "./voterheader.css";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../common/AlertBox.css'

const buttonCss = {
  padding: "7px 15px",
  backgroundColor: "#FCE8C8",
  border: "none",
  borderRadius: "22%",
};

const VoterHeaderComponent = (props) => {
  const history = useHistory();
  const { epic_id } = useParams();
  const [userName, setUserName] = useState("Guest");
  const [windowLocation, setWindowLocation] = useState(
    window.location.pathname
  );
  const [epicId, setEpicId] = useState("");

  const [defaultHomeRoute, setDefaultHomeRoute] = useState("");

  useEffect(() => {
    // for default route
    goToDashBoardRoute();

    var user_data;
    try {
      user_data = localStorage.getItem("user_data");
      user_data = JSON.parse(user_data);
      setUserName(user_data["name"]);
      if (epic_id) {
        setEpicId(epic_id);
      }
    } catch (error) { }
  });
  const onClickHandler = () => {
    // if (windowLocation.split("/")[1] === "voterdetail") {
    //   history.push("/voterlistdata");
    // } else {
    history.goBack();
    // }
  };

  const handleLogout = () => {
    confirmAlert({
      title: 'Confirm to proceed further',
      message: "Are you sure to logout?",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            logOutCurrentSession(true)
          }
        },
        {
          label: 'No',
          onClick: () => {
            logOutCurrentSession(false)
          }
        }
      ]
    });
  };

  const logOutCurrentSession = (confirmation) => {
    if (confirmation) {
      localStorage.clear();
      history.push("/");
    }
  }

  const goToDashBoardRoute = () => {
    var user_data;

    try {
      user_data = localStorage.getItem("user_data");
      user_data = JSON.parse(user_data);

      // console.log(user_data['designition'].toLowerCase());

      switch (user_data && user_data["designition"].toLowerCase()) {
        case "zila president":
          setDefaultHomeRoute("mandallist");
          break;
        case "mandal president":
          setDefaultHomeRoute("sectorlist");
          break;
        case "sector president":
          setDefaultHomeRoute("boothlist");
          break;
        case "booth president":
          setDefaultHomeRoute("menulist");
          break;
        default:
          setDefaultHomeRoute("menulist");
      }
    } catch (error) { }
  };

  // hideBackIcon={true}
  return (
    <div>
      <div className="voter_header">
        <ul>
          {props.hideBackIcon ? null : (
            <li className="voter_backbtn_padding">
              <button onClick={onClickHandler}>
                <img src="/images/arrowright.svg" alt="" />
              </button>
            </li>
          )}

          <li className="voter_backbtn">{/* <h5>Jan Sampark</h5> */}</li>
          {/* <li className="notify_padding">
            <img src="/images/edit2.svg" alt="" />
          </li> */}
          {/* show edit button if details in list view*/}
          {windowLocation === `/voterdetail/communication/list/${epicId}` && (
            <li className="notify_padding">
              <Link to={`/voterdetail/communication/${epicId}`}>
                <img src="/images/edit.svg" alt="" />
              </Link>
            </li>
          )}
          {windowLocation === `/voterdetail/education/list/${epicId}` && (
            <li className="notify_padding">
              <Link to={`/voterdetail/education/${epicId}`}>
                <img src="/images/edit.svg" alt="" />
              </Link>
            </li>
          )}
          {windowLocation === `/voterdetail/political/list/${epicId}` && (
            <li className="notify_padding">
              <Link to={`/voterdetail/political/${epicId}`}>
                <img src="/images/edit.svg" alt="" />
              </Link>
            </li>
          )}

          {windowLocation === `/voterdetail/personal/${epicId}` ||
            windowLocation === `/voterdetail/communication/${epicId}` ||
            windowLocation === `/voterdetail/education/${epicId}` ||
            windowLocation === `/voterdetail/political/${epicId}` ? (
            <div style={{ position: "relative" }}>
              <a
                href={
                  windowLocation === `/voterdetail/personal/${epicId}`
                    ? `/voterdetail/communication/list/${epicId}`
                    : windowLocation === `/voterdetail/communication/${epicId}`
                      ? `/voterdetail/education/list/${epicId}`
                      : windowLocation === `/voterdetail/education/${epicId}`
                        ? `/voterdetail/political/list/${epicId}`
                        : "/voterlistdata"
                  // : `/voterdetail/personal/list/${epicId}`
                }
              >
                <button style={buttonCss}>
                  {windowLocation === `/voterdetail/political/${epicId}`
                    ? "Done"
                    : "Next"}
                </button>
              </a>
            </div>
          ) : (
            <React.Fragment>
              <li style={{ padding: " 0px 20px 0px 20px", cursor: "pointer" }}>
                <Link to={`/${defaultHomeRoute}`}>
                  <img src="/images/home.svg" alt="Home" />
                </Link>
              </li>

              <li>
                <img src="/images/download.svg" alt="" />
              </li>

              <li className="notify_padding">
                <img src="/images/notify.svg" alt="" />
              </li>
              {/* <li className="notify_padding">
                  <button type="button" className="next_btn">Next</button>
                </li> */}
              <li className="dropdown_list">
                {/* <img src="/images/user.svg" alt="" onClick={handleLogout} /> */}
                <img src="/images/user.svg" alt="" />
                <ul className="user_dropdown">
                  <li>
                    <a href="#">
                      Hi,{" "}
                      {userName
                        ? userName.charAt(0).toUpperCase() + userName.slice(1)
                        : "Guest"}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="/images/settings.svg" alt="" /> Settings
                    </a>
                  </li>

                  <li onClick={handleLogout}>
                    <a href="#">
                      <img src="/images/logout.svg" alt="" /> Logout
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <img src="/images/help.svg" alt="" /> Help
                    </a>
                  </li>
                </ul>
              </li>
            </React.Fragment>
          )}
        </ul>
      </div>
    </div>
  );
};

export default VoterHeaderComponent;
