export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

// for test
export const FETCH_POST_PENDING = "FETCH_POST_PENDING";
export const FETCH_POST_SUCCESS = "FETCH_POST_SUCCESS";
export const FETCH_POST_ERROR = "FETCH_POST_ERROR";

// voter
export const SEARCH_VOTER_PENDING = "SEARCH_VOTER_PENDING";
export const SEARCH_VOTER_SUCCESS = "SEARCH_VOTER_SUCCESS";
export const SEARCH_VOTER_ERROR = "SEARCH_VOTER_ERROR";
export const SEARCH_VOTER_EMPTY = "SEARCH_VOTER_EMPTY";
export const SEARCH_VOTER_NOT_FOUND = "SEARCH_VOTER_NOT_FOUND";

// question
export const QUESTION_PENDING = "QUESTION_PENDING";
export const QUESTION_SUCCESS = "QUESTION_SUCCESS";
export const QUESTION_ERROR = "QUESTION_ERROR";

export const QUESTION_OPTIONS_PENDING = "QUESTION_OPTIONS_PENDING";
export const QUESTION_OPTIONS_SUCCESS = "QUESTION_OPTIONS_SUCCESS";
export const QUESTION_OPTIONS_ERROR = "QUESTION_OPTIONS_ERROR";

// booth
export const FETCH_BOOTH_PENDING = "FETCH_BOOTH_PENDING";
export const FETCH_BOOTH_SUCCESS = "FETCH_BOOTH_SUCCESS";
export const FETCH_BOOTH_ERROR = "FETCH_BOOTH_ERROR";
export const FETCH_BOOTH_EMPTY = "FETCH_BOOTH_EMPTY";

export const BOOTH_DETAILS_PENDING = "BOOTH_DETAILS_PENDING";
export const BOOTH_DETAILS_SUCCESS = "BOOTH_DETAILS_SUCCESS";
export const BOOTH_DETAILS_ERROR = "BOOTH_DETAILS_ERROR";
export const BOOTH_DETAILS_EMPTY = "BOOTH_DETAILS_EMPTY";

//JANSAMPARK
export const JANSAMPARK_COUNT_PENDING = "JANSAMPARK_COUNT_PENDING";
export const JANSAMPARK_COUNT_SUCCESS = "JANSAMPARK_COUNT_SUCCESS";
export const JANSAMPARK_COUNT_ERROR = "JANSAMPARK_COUNT_ERROR";
export const JANSAMPARK_COUNT_EMPTY = "JANSAMPARK_COUNT_EMPTY";

//Karyakarta
export const KARYAKARTA_LIST_COUNT_PENDING = "KARYAKARTA_LIST_COUNT_PENDING";
export const KARYAKARTA_LIST_COUNT_SUCCESS = "KARYAKARTA_LIST_COUNT_SUCCESS";
export const KARYAKARTA_LIST_COUNT_ERROR = "KARYAKARTA_LIST_COUNT_ERROR";
export const KARYAKARTA_LIST_COUNT_EMPTY = "KARYAKARTA_LIST_COUNT_EMPTY";

// SECTOR
export const SECTOR_LIST_PENDING = "SECTOR_LIST_PENDING";
export const SECTOR_LIST_SUCCESS = "SECTOR_LIST_SUCCESS";
export const SECTOR_LIST_ERROR = "SECTOR_LIST_ERROR";
export const SECTOR_LIST_EMPTY = "SECTOR_LIST_EMPTY";

// CATEGORY
export const CATEGORY_LIST_PENDING = "CATEGORY_LIST_PENDING";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR";
export const CATEGORY_LIST_EMPTY = "CATEGORY_LIST_EMPTY";

// Karykarta List
export const VOTER_KARYKARTA_LIST_SUCCESS = "VOTER_KARYKARTA_LIST_SUCCESS";
export const VOTER_KARYKARTA_LIST_PENDING = "VOTER_KARYKARTA_LIST_PENDING";
export const VOTER_KARYKARTA_LIST_EMPTY = "VOTER_KARYKARTA_LIST_EMPTY";
export const VOTER_KARYKARTA_LIST_ERROR = "VOTER_KARYKARTA_LIST_ERROR";

// Find voters List
export const FIND_VOTERS_LIST_SUCCESS = "FIND_VOTERS_LIST_SUCCESS";
export const FIND_VOTERS_LIST_PENDING = "FIND_VOTERS_LIST_PENDING";
export const FIND_VOTERS_LIST_EMPTY = "FIND_VOTERS_LIST_EMPTY";
export const FIND_VOTERS_LIST_ERROR = "FIND_VOTERS_LIST_ERROR";
