import React, { Fragment, useState, useEffect, memo } from "react";
import { connect } from "react-redux";
import { useParams, Redirect, useHistory } from "react-router-dom";
import Select from "react-select";
import VoterHeaderComponent from "../voter/voterheader";
import LoaderComponent from "../loader/LoaderComponent";
import { ageNumber } from "../../components/common/Function";
import { axiosPost } from "../../utils/commonAxios";
import {
  FIND_VOTERS_LIST_PENDING,
  FIND_VOTERS_LIST_SUCCESS,
  FIND_VOTERS_LIST_ERROR,
  FIND_VOTERS_LIST_EMPTY,
} from "../../actions/types";
import "./FindVoters.css";
import { AlertBox } from "../../components/common/AlertBox"

const FindVoters = (props) => {
  const history = useHistory();
  const [inputs, setInputs] = useState({
    username: "",
    epicno: "",
    selectedStartAge: {},
    selectedEndAge: {},
  });
  const [numbers, setNumbers] = useState({});
  useEffect(() => {
    let ageNum = getAgeNumber();
  }, []);
  const getAgeNumber = async () => {
    let ageNo = await ageNumber();
    if (ageNo.length > 0) {
      setNumbers(ageNo);
    } else {
      console.log("ho");
    }
  };
  const handleSearchInputs = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e, type) => {
    // console.log(e, type);
    setInputs((prevState) => ({
      ...prevState,
      [type]: e,
    }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    let userData = localStorage.getItem("user_data");
    const { booth_code, designition, _id } = JSON.parse(userData);

    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) { }
    let params = {
      action: "/electoral_list/searchvoter",
      booth_code: booth_code,
    };
    if (designition === "Karyakarta") {
      params.panna_parmukh_id = _id;
    }
    if (inputs.username) {
      params.name_en = inputs.username;
    }
    if (inputs.epicno) {
      params.epic_no = inputs.epicno;
    }
    if (typeof inputs.selectedStartAge.label !== "undefined") {
      params.age_start = inputs.selectedStartAge.label;
    }

    if (typeof inputs.selectedEndAge.label !== "undefined") {
      params.age_end = inputs.selectedEndAge.label;
    }

    if (
      inputs.username ||
      inputs.epicno ||
      inputs.selectedStartAge.label ||
      inputs.selectedEndAge.label
    ) {
      // console.log(checkuser);
      let resp = await axiosPost(params, checkuser);

      if (
        typeof resp.data.response !== "undefined" &&
        resp.data.response.listData.length > 0
      ) {
        props.dispatch({
          type: FIND_VOTERS_LIST_SUCCESS,
          payload: resp.data.response.listData,
        });
        history.push("/find-voters-list");
      } else {
        AlertBox("Message", "No Record Found");
      }
    } else {
      AlertBox("Message", "Plz. provide your search criteria to search voters.");
    }
  };
  // console.log(numbers, inputs.selectedStartAge);
  return (
    <Fragment>
      <div className="searchContainer">
        <VoterHeaderComponent />
        <LoaderComponent />

        <div className="voter_container">
          <div className="sangathan_heading">
            <h4>Find Voter</h4>
            <div className="voter_search">
              <form onSubmit={handleSearchSubmit}>
                <div className="search_postion">
                  <input
                    type="text"
                    name="username"
                    placeholder="Search by Name"
                    value={inputs.username}
                    onChange={handleSearchInputs}
                  />
                  <img src="/images/user_black.svg" alt="username" />
                </div>

                <div className="search_postion">
                  <input
                    type="text"
                    name="epicno"
                    placeholder="Search by Epic Number"
                    value={inputs.epicno}
                    onChange={handleSearchInputs}
                  />
                  <img src="/images/search_black.svg" alt="epicno" />
                </div>

                <h4 className="search_postion2">Search by Age Group</h4>
                <ul>
                  <li>
                    <Select
                      value={inputs.selectedStartAge}
                      onChange={(e) => handleChange(e, "selectedStartAge")}
                      className="basic-single"
                      classNamePrefix="select"
                      options={numbers}
                    />
                  </li>

                  <li>to</li>

                  <li>
                    <Select
                      value={inputs.selectedEndAge}
                      onChange={(e) => handleChange(e, "selectedEndAge")}
                      className="basic-single"
                      classNamePrefix="select"
                      options={numbers}
                    />
                  </li>
                </ul>

                <button className="search_postion_btn" type="submit">
                  Search
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// export default connect()(FindVoters);
// const mapStateToProps = (state) => {
//   // console.log("findvoooo", state.FindVotersList);
//   return {
//     votersList: state.FindVotersList,
//   };
// };

export default connect()(FindVoters);
