import {
  FIND_VOTERS_LIST_PENDING,
  FIND_VOTERS_LIST_SUCCESS,
  FIND_VOTERS_LIST_ERROR,
  FIND_VOTERS_LIST_EMPTY,
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  success: false,
  notFound: false,
  errorMessage: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FIND_VOTERS_LIST_PENDING:
      return {
        ...state,
        success: false,
        loading: true,
        notFound: false,
        errorMessage: null,
      };
    case FIND_VOTERS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        errorMessage: null,
        notFound: false,
        data: action.payload,
      };
    case FIND_VOTERS_LIST_ERROR:
      return {
        ...state,
        success: true,
        loading: false,
        notFound: false,
        errorMessage: action.payload,
      };
    case FIND_VOTERS_LIST_EMPTY:
      return {
        ...state,
        success: true,
        loading: false,
        errorMessage: null,
        notFound: false,
        data: [],
      };
    default:
      return state;
  }
};
