import React, { useState, useEffect, useRef } from "react";
import VoterHeaderComponent from "../../voter/voterheader";
import './AssignVoters.css';
import 'rc-slider/assets/index.css';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { axiosPost, axiosGet } from "../../../utils/commonAxios";
import LoaderComponent from "../../loader/LoaderComponent";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../common/AlertBox.css';
import { AlertBox } from "../../../components/common/AlertBox"

export default function AssignVoters(props) {
  const history = useHistory();
  const { boothid, aid } = useParams();
  const { state } = useLocation();
  const count = state.unAssignedCount;
  const [data, setData] = useState([]);
  // const initialState = {
  //     value: [1, state.unAssignedCount]
  // }
  const initialState = [1, state.unAssignedCount];
  // const [range, setRange] = useState(initialState);
  // const [from, setFrom] = useState(1);
  // const [to, setTo] = useState(state.unAssignedCount);

  // const [count_1, setCount_1] = useState(0);
  // const [to_1, setTo_1] = useState(0)
  const [random, setRandom] = useState(0)
  const [newArr, setNewArr] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    async function getUnAssignedVoters() {
      setIsLoading(true)
      let actionURL = `/electoral_list/unassigned_voter_list?booth_code=${boothid}&panna_parmukh_id=${aid}`;
      try {
        let resp = await axiosGet(actionURL, {});
        if (resp.data.resultFlag === 1) {
          let arrData = resp.data.response.list;
          arrData.map((data) => {
            data['activeClass'] = false
          })
          setData(arrData);
          setIsLoading(false)
        } else {
          console.log("API Error - Unable to Fetch Data!");
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false)
      }
    }
    getUnAssignedVoters();
  }, [])

  const nameForm = useRef(null);

  const confirmationBox = () => {
    confirmAlert({
      title: 'Confirm to proceed further',
      message: "Are you sure you want to assign?",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            assignVoters()
          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });
  };
  const assignVoters = async () => {
    // if (window.confirm("Are you sure you want to assign?")) {
    const form = nameForm.current;

    if (boothid && aid) {
      setIsLoading(true)

      let serialCSV = newArr.join(", ");


      let reqObject = {
        action: "/electoral_list/assign_voter",
        booth_code: boothid,
        panna_parmukh_id: aid,
        serial_no: serialCSV,
        unassign: "unassign"
      };

      try {
        let resp = await axiosPost(reqObject);
        if (resp.data.resultFlag === 1) {
          setIsLoading(false);
          AlertBox("Success message", "Congratulation!, Assignment Done!");
          history.push(`/karyakartalist/${boothid}`);
        } else {
          setIsLoading(false);
          alert("Error message", "API Error - Assignment Failed!");
        }
      } catch (error) {
        setIsLoading(false);
        alert("Error message", "API Error while assigning voters!");
      }

    } else {
      alert("error message", "Invalid Booth Code OR Agent ID!");
    }
    // }
  };


  const setFilterData = (voterData) => {
    let newData = data;

    newData.map((data) => {
      if (data._id == voterData._id) {
        data['activeClass'] = !voterData.activeClass
        let newAr = newArr
        if (newArr.indexOf(voterData.serial_no) != -1) {
          let index = newAr.indexOf(voterData.serial_no);
          newAr.splice(index, 1)
        } else {
          newAr.push(voterData.serial_no)
        }
        setNewArr(newAr)
      }
    })

    setData(newData)
    setRandom(Math.random())
  }


  useEffect(() => { }, [random])

  return (
    <div >
      <VoterHeaderComponent />
      <LoaderComponent displayType={isLoading} />
      <div className="voter_container">

        <form ref={nameForm}>

          {data && data.length > 0 && (
            <div className="total-count">
              <h5>
                Total {data.length}
              </h5>
            </div>
          )}
          <div className="assignvoter_btn">
            <ul>
              <li>
                <button className="assign_btn2" type="button" onClick={() => confirmationBox()}>Un assign</button>
              </li>

            </ul>
          </div>
        </form>

        <div className="loksabha_list" key={Math.random()}>
          <div className="loksabha_list">
            <ul>
              {
                data.map((newData, i) => {
                  let newVoterItem = newData;
                  newVoterItem['activeClass'] = false;
                  if (newArr.indexOf(newVoterItem.serial_no) !== -1) {
                    newVoterItem['activeClass'] = true
                  }

                  return (

                    <li onClick={() => setFilterData(newVoterItem)}>
                      {
                        newData.activeClass ?
                          (
                            <h5 className="un-active_voter">{newVoterItem.serial_no}</h5>
                          ) : (
                            <h5>{newVoterItem.serial_no}</h5>
                          )
                      }
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>

    </div>
  );
}