import React from 'react'
import { EducationalDetailsContainer } from "../../components/voterDetail";

function Education() {
  return (
    <div>
      <EducationalDetailsContainer />
    </div>
  )
}

export default Education
