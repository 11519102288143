import React from "react";
import "./LoaderStyle.css";

function LoaderComponent({ displayType = false }) {
  return (
    <div>
      <div
        className="voter_loaderbg"
        id="full_loader"
        style={{ display: displayType ? "block" : "none" }}
      >
        <div className="voterimg_postion">
          <img src="/images/loader.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default LoaderComponent;
