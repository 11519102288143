import React from "react";
import CommunicationDetailsList from "../../components/voterDetail/communication/CommunicationDetailsList";

function CommunicationList() {
  return (
    <div>
      <CommunicationDetailsList />
    </div>
  );
}

export default CommunicationList;
