import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import VoterHeaderComponent from "../voterheader";
import { axiosPost, axiosGet } from "../../../utils/commonAxios";
import {
  voterKarykartaList,
  cleanVoterKarykartaList,
} from "../../../actions/voterKarykartaList";
// import {
//   karyakartaListCount,
//   cleanKaryakartaListCount,
// } from "../../../actions/karyakarta";
import "./KarykartaList.css";
import { __ucLetter } from "../../../utils/language";

const KaryakartaLInfo = (props) => {
  const { state } = useLocation();
  const [abhiyanInfo, setAbhiyanInfo] = useState("");
  let { booth_code, abhiyaan_id } = useParams();

  useEffect(() => {
    if (typeof booth_code !== "undefined") {
      props.voterKarykartaList(booth_code);
    }

    return () => {
      props.cleanVoterKarykartaList();
    };
  }, [booth_code]);

  useEffect(() => {
    async function getAbhiyanDetails() {
      let actionURL = `/booth/details?code=${booth_code}&abhiyan_id=${state.abhiyaanId}`;
      try {
        let resp = await axiosGet(actionURL, {});
        if (resp.data.resultFlag === 1) {
          try {
            setAbhiyanInfo(resp.data.response.abhiyan[0].json_language_data.en.description);
          } catch (error) { }
        } else {
          console.log("API Error - Unable to Fetch Count!");
        }
      } catch (error) {
        console.log(error);
      }
    }
    getAbhiyanDetails();
  }, [])


  return (
    <>
      <VoterHeaderComponent />
      <div className="voter_bg">
        <div className="voter_container">
          <div className="karya_list1">
            <p>{abhiyanInfo ? abhiyanInfo : ""}</p>
          </div>
        </div>
      </div>

      <div className="voter_container">
        <div className="karyalist_profile">
          {props.voterKarykarta &&
            props.voterKarykarta.data &&
            props.voterKarykarta.data.map((data, i) => {
              return (
                <React.Fragment key={i}>
                  <ul>
                    <li>
                      <img
                        src="/images/userdefault.svg"
                        className="user_img"
                        alt=""
                      />
                    </li>
                    <li>
                      <h6>
                        <img
                          src="/images/user.svg"
                          className="user_icon"
                          alt=""
                        />{" "}
                        {__ucLetter(data.name)}
                      </h6>

                      <h6>
                        <img
                          src="/images/email.svg"
                          className="user_icon"
                          alt=""
                        />{" "}
                        {data.email}
                      </h6>

                      <h6 className="phone_position">
                        <img
                          src="/images/phoneorange.svg"
                          className="user_icon"
                          alt=""
                        />{" "}
                        {data.mobile}
                        <a href={`tel:${data.mobile}`}>
                          <img
                            src="/images/phoneblue.svg"
                            className="phone_icon"
                            alt=""
                          />
                        </a>
                      </h6>


                      {data.jansampark_percentage && typeof data.jansampark_percentage !== "undefined" && data.jansampark_percentage > 0 ? (
                        <>
                          <h6 className="phone_position">
                            <img
                              src="/images/user4.svg"
                              className="user_icon"
                              alt=""
                            />{" "}
                            {data.jansampark_percentage}%
                          </h6>
                        </>
                      ) : <></>
                      }

                    </li>
                  </ul>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    voterKarykarta: state.voterKarykarta,
    karyakartaCount: state.karyakarta,
  };
};

export default connect(mapStateToProps, {
  voterKarykartaList: (booth_code) => voterKarykartaList(booth_code),
  cleanVoterKarykartaList,
})(KaryakartaLInfo);

