import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import VoterHeaderComponent from "../voterheader";
import { axiosPost, axiosGet } from "../../../utils/commonAxios";
import {
  voterKarykartaList,
  cleanVoterKarykartaList,
} from "../../../actions/voterKarykartaList";
import {
  karyakartaListCount,
  cleanKaryakartaListCount,
} from "../../../actions/karyakarta";
import "./KarykartaList.css";
import { __ucLetter } from "../../../utils/language";
import LoaderComponent from "../../loader/LoaderComponent";

const KaryakartaList = (props) => {
  const [vCountObj, setVCountObj] = useState(0);
  const [karykarta, setKarykarta] = useState({
    karykarta_id: "",
    start: "",
    end: "",
    totalNoOfKaryakart: 0,
    countDetails: [],
    abhiyaan_id: "",
  });
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const [unAssignedCount, setUnAssignedCount] = useState(0);
  let { booth_code, abhiyaan_id } = useParams();

  useEffect(() => {
    if (typeof booth_code !== "undefined") {
      props.voterKarykartaList(booth_code).then(() => {
        setLoading(false)
      });
      if (typeof abhiyaan_id !== "undefined") {
        // props.karyakartaListCount(booth_code, abhiyaan_id);
        // updateCountDetials(booth_code, abhiyaan_id);
      }
    }

    return () => {
      props.cleanVoterKarykartaList();
    };
  }, [booth_code]);

  const updateCountDetials = async (booth_code, abhiyaan_id) => {
    await axiosGet(
      `/abhiyan/details?booth_code=${booth_code}&abhiyaan_id=${abhiyaan_id}`
    ).then((resp) => {
      if (resp.data && resp.data.response) {
        let countDetails = [];
        resp.data.response.map((resp) => {
          countDetails[resp["id"]] = {
            count: resp.count,
            totalCount: resp.totalCount,
          };
        });
        setKarykarta({ countDetails: countDetails, abhiyaan_id: abhiyaan_id });
      }
    });
  };

  // const getKaryakartaPercent = async (booth_code, abhiyaan_id) => {
  //   props.karyakartaListCount(booth_code, abhiyaan_id).then(() => {
  //     console.log(props.karyakartaCount.data);
  //   });
  // };
  useEffect(() => {
    async function getUnAssignedCounts() {
      let actionURL = `/booth/details?code=${booth_code}`;
      try {
        let resp = await axiosGet(actionURL, {});
        if (resp.data.resultFlag === 1) {
          setUnAssignedCount(resp.data.response.unassigned_voter_count);
        } else {
          console.log("API Error - Unable to Fetch Count!");
        }
      } catch (error) {
        console.log(error);
      }
    }
    getUnAssignedCounts();
  }, [])

  useEffect(() => {
    async function getVObjCounts() {
      let actionURL = `/electoral_list/getassignedvotercount?booth_code=${booth_code}`;
      try {
        let resp = await axiosGet(actionURL, {});
        if (resp.data.resultFlag === 1) {
          setVCountObj(resp.data.response);
        } else {
          console.log("API Error - Unable to Fetch Count!");
        }
      } catch (error) {
        console.log(error);
      }
    }
    getVObjCounts();
  }, [])


  //User Details
  let userInfo;
  let showAssignBtn = false;
  try {
    userInfo = localStorage.getItem("user_data");
  } catch (error) { }

  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    if (userInfo && userInfo.designition && userInfo.designition.length > 0 && (userInfo.designition.toLowerCase() == "booth president")) {
      showAssignBtn = true;
    }
  }

  return (
    <>
      <VoterHeaderComponent />
      {/* show loader */}
      <LoaderComponent displayType={loading} />
      <div className="voter_bg">
        <div className="voter_container">
          <div className="karya_list1">
            <ul>
              <li>
                <h5>Karyakarta</h5>
                {props.voterKarykarta && props.voterKarykarta.data && (
                  <h4>{props.voterKarykarta.data.length}</h4>
                )}
              </li>

              <li>
                <h5>Voters :</h5>
                <h4>
                  {vCountObj.assigned_count} / <span>{vCountObj.total_count}</span>
                </h4>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="voter_container">
        <div className="karyalist_profile">
          {props.voterKarykarta &&
            props.voterKarykarta.data &&
            props.voterKarykarta.data.map((data, i) => {

              return (
                <React.Fragment key={i}>
                  <ul>
                    <li>
                      <img
                        src="/images/userdefault.svg"
                        className="user_img"
                        alt=""
                      />
                    </li>
                    <li>
                      <h6>
                        <img
                          src="/images/user.svg"
                          className="user_icon"
                          alt=""
                        />{" "}
                        {__ucLetter(data.name)}
                        <img src="/images/delete.svg" className="karya_delete" alt="" />
                      </h6>

                      <h6>
                        <img
                          src="/images/email.svg"
                          className="user_icon"
                          alt=""
                        />{" "}
                        {data.email}
                      </h6>

                      <h6 className="phone_position">
                        <img
                          src="/images/phoneorange.svg"
                          className="user_icon"
                          alt=""
                        />{" "}
                        {data.mobile}
                        <a href={`tel:${data.mobile}`}>
                          <img
                            src="/images/phoneblue.svg"
                            className="phone_icon"
                            alt=""
                          />
                        </a>
                      </h6>

                      <h6 className="phone_position">
                        <img
                          src="/images/user6.svg"
                          className="user_icon"
                          alt=""
                        />{" "}
                        {<React.Fragment>
                          {data.total_count}{" "}
                          | <a href={`/assigned-voters-list/${booth_code}/${data._id}`} style={{ color: "#EC7024" }}>view all</a>
                          {showAssignBtn ? (
                            <h5>
                              <Link to={{
                                pathname: `/assign-voters/${booth_code}/${data._id}`,
                                state: { unAssignedCount }
                              }} className="assign-voters" style={{ marginBottom: '10px' }} >
                                Assign Voter <img src="/images/chev_right.svg" alt="Assign Voter" />
                              </Link>

                              <Link to={{
                                pathname: `/un-assign-voters/${booth_code}/${data._id}`,
                                state: { unAssignedCount }
                              }} className="assign-voters" >
                                Un assign Voter <img src="/images/chev_right.svg" alt="Assign Voter" />
                              </Link>
                            </h5>
                          ) : <></>}

                        </React.Fragment>}
                      </h6>
                    </li>
                  </ul>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    voterKarykarta: state.voterKarykarta,
    karyakartaCount: state.karyakarta,
  };
};

export default connect(mapStateToProps, {
  voterKarykartaList: (booth_code) => voterKarykartaList(booth_code),
  cleanVoterKarykartaList,
  // karyakartaListCount: (code, abhiyaan_id) =>
  //   karyakartaListCount(code, abhiyaan_id),
  // cleanKaryakartaListCount,
})(KaryakartaList);

