import React from "react";
import { Link } from "react-router-dom";
import { removeHiphensinEpicNo } from "../../utils/misc";
const GridView = (props) => {
    return (
        <React.Fragment>
            <div className="list_grid_padding">
                <div className="grid_border">

                    <div className="voter_number">
                        <ul>
                            <li>
                                <h6 className="voter_card">Name:</h6>
                            </li>
                            <li>
                                <Link
                                    to={
                                        "/voterdetail/personal/list/" +
                                        (props.el.epic_no ? props.el.epic_no : "")
                                    }
                                >
                                    <h5><span style={{ color: "#000000", fontSize: "1.2rem" }}>{props.el.name ? props.el.name : ""}</span></h5>
                                </Link>

                            </li>
                            <li>
                                {props.markDoneObj[props.el.epic_no] && (
                                    <p className="voter_check">
                                        <img style={{ marginTop: "4px", marginLeft: "5px", opacity: "1" }} src="/images/check.svg" alt="" />
                                    </p>
                                )}
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <h6 className="voter_card">Epic No.:</h6>
                            </li>
                            <li>
                                <h6><span> {props.el.epic_no ? removeHiphensinEpicNo(props.el.epic_no) : ""}</span></h6>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <h6 className="voter_card">Relation Name:</h6>
                            </li>
                            <li>
                                <h6><span> {props.el.voter_data && props.el.voter_data.personal && props.el.voter_data.personal.rln_name_en}</span></h6>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <h6 className="voter_card">Age:</h6>
                            </li>
                            <li>
                                <h6><span> {props.el.voter_data && props.el.voter_data.personal && props.el.voter_data.personal.age}</span></h6>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <h6 className="voter_card">P. P. Name:</h6>
                            </li>
                            <li>
                                <h6><span> {props.el && props.el.panna_pramukh_name && props.el.panna_pramukh_name}</span></h6>
                            </li>
                        </ul>
                        {/* <ul>
                        <li>
                            <h6 className="voter_card">Mobile:</h6>
                        </li>
                        <li>
                            <h6>
                                 {props.el.voter_data &&
                                    props.el.voter_data.communication &&
                                    props.el.voter_data.communication.mobile.length > 0 && (
                                        <h6 style={{ position: "relative" }}>
                                        <span>
                                            {props.el.voter_data.communication.mobile[0]}
                                        </span>
                                        <img
                                            src="/images/phoneblue.svg"
                                            className="user_number"
                                            alt=""
                                        />
                                        <img
                                            src="/images/message.svg"
                                            className="user_msg"
                                            alt=""
                                        />
                                        </h6>
                                    )}
                            </h6>
                        </li>
                    </ul> */}
                        {/* <ul>
                        <li>
                        <h6 className="voter_card">House No.</h6>
                        </li>
                        <li>
                        <h6>
                            <span>{props.el.voter_data && props.el.voter_data.personal && props.el.voter_data.personal.house_no}</span>
                        </h6>
                        </li>
                    </ul> */}
                        {/* <ul>
                        <li>
                        <h6 className="voter_card">Address</h6>
                        </li>
                        <li>
                            {props.el.voter_data && props.el.voter_data.personal.add_curr && (
                                <h6>
                                    <span>{props.el.voter_data.personal.add_curr.full_add}</span>
                                </h6>
                            )}
                        </li>
                    </ul> */}
                    </div>
                    <div className="voterlist_img" style={{ marginTop: '10px' }}>
                        <p className="grid_img_width">
                            <img src="/images/bjplogo2.svg" alt="" />
                        </p>
                        <p className="grid_img_width grid_grow" onClick={() => props.openSchemePopup(props.el.voter_data)}>

                            <img src="/images/charity.svg" alt="" />
                            <span>
                                {props.el &&
                                    props.el.voter_data &&
                                    props.el.voter_data &&
                                    props.el.voter_data["schemes_beneficary"] &&
                                    Object.keys(
                                        props.el.voter_data["schemes_beneficary"]
                                    )
                                    ? Object.keys(
                                        props.el.voter_data["schemes_beneficary"]
                                    ).length
                                    : 0}
                            </span>
                        </p>


                        <p>
                            <img src="/images/bill.svg" alt="" />
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default GridView