import React, { useState, useEffect, memo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { searchVoter, cleanVoterState, search } from "../../actions/voter";
import VoterHeaderComponent from "./voterheader";
import Voterdetail from "./VoterDetail";
import LoaderComponent from "../loader/LoaderComponent";
import "./Sangathan.css";

const SangathanComponent = (props) => {
  let { vid } = useParams();

  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("id");
  const [startLoading, setStartLoading] = useState(false);

  useEffect(() => {
    if (props.voterData.notFound && !props.voterData.data.length > 0) {
      alert("No Data Found");
    }

    setSearchValue("");

    return () => {
      props.cleanVoterState();
    };
  }, [props.voterData.notFound]);

  useEffect(() => {
    if (vid && vid.length > 0) {
      getVoterDetails(vid);
    }
    return () => {
      props.cleanVoterState();
    };
  }, [vid]);

  const handleChange = (e) => {
    setSearchBy(e.target.value);
  };

  const getVoterDetails = (vid) => {
    props.searchVoter(vid, "id");
  };

  // initial call for getting voter data
  const onSearchHandler = (e) => {
    e.preventDefault();
    var data = search(searchValue, searchBy);
  };

  const loadingIcon = (boolVal) => {
    setStartLoading(boolVal);
  };
  // console.log(props.voterData.data, ' props.voterData.data');
  return (
    <div>
      <div className="">
        <VoterHeaderComponent />

        {/* {props.voterData.loading || startLoading ? <LoaderComponent /> : null} */}
        <LoaderComponent displayType={props.voterData.loading} />

        {props.voterData.data &&
          Object.keys(props.voterData.data).length > 0 ? (
            <Voterdetail
              voterData={props.voterData.data}
              loadingIcon={loadingIcon}
            />
          ) : (
            <div className="voter_container">
              <div className="sangathan_heading">
                <h4>Find Voter</h4>
                {/* <h6>Search Voter</h6> */}
                {/* <select value={searchBy} onChange={handleChange}>
                <option value="id">Search by voter id</option>
                <option value="name">Search by name</option>
              </select> */}

                <div className="voter_search">
                  <form >
                    <div className="search_postion">
                      <input
                        type="text"
                        placeholder="Search by Name"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      {/* <button onClick={onSearchHandler}> */}
                      <img src="/images/user_black.svg" alt="" />
                      {/* </button> */}
                    </div>

                    <div className="search_postion">
                      <input
                        type="number"
                        placeholder="Search by Epic Number"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      {/* <button onClick={onSearchHandler}> */}
                      <img src="/images/search_black.svg" alt="" />
                      {/* </button> */}
                    </div>
                    <h4 className="search_postion2">Search by Age Group</h4>
                    <ul>
                      <li>
                        <select>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                        </select>
                      </li>

                      <li>to</li>

                      <li>
                        <select>
                          <option>18</option>
                          <option>19</option>
                          <option>20</option>
                        </select>
                      </li>
                    </ul>
                    <button className="search_postion_btn" onClick={onSearchHandler}>
                      Search
              </button>
                  </form>
                </div>

              </div>

            </div>
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log(state.voter.data);
  return {
    voterData: state.voter,
  };
};

export default memo(
  connect(mapStateToProps, {
    searchVoter: (searchValue, searchBy) => searchVoter(searchValue, searchBy),
    cleanVoterState: () => cleanVoterState(),
  })(SangathanComponent)
);
