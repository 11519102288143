import { SIGN_IN, SIGN_OUT } from "../actions/types";

const INTIAL_STATE = {
  userData: {},
  authLoading: true,
  success: false,
  errorMessage: null,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        authLoading: false,
        success: true,
        errorMessage: null,
        userData: action.payload,
      };
    case SIGN_OUT:
      return {
        ...state,
        authLoading: false,
        success: true,
        errorMessage: null,
        userData: action.payload
      };
    default:
      return state;
  }
};
