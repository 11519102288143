import React from "react";
import BoothDetailsComponent from "../../components/booth/BoothDetailsComponent";

function BoothDetailsPage() {
  return (
    <div>
      <BoothDetailsComponent />
    </div>
  );
}

export default BoothDetailsPage;
