// import React, { useState } from "react";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './AlertBox.css'
export const AlertBox = async (title = "", message) => {

    confirmAlert({
        title: title ? title : 'Confirm and try again!',
        message: message,
        buttons: [
            {
                label: 'Okay',
            },
        ]
    });

}
