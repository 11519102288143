import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import VoterHeaderComponent from "../voter/voterheader";
import { boothListing, cleanBoothListing } from "../../actions/booth";
import LoaderComponent from "../loader/LoaderComponent";
import "./SectorBoothLists.css";

const BoothListComponent = (props) => {
  let { sector_code } = useParams();

  useEffect(() => {
    if (sector_code) {
      props.boothListing(sector_code);
    }

    return () => {
      props.cleanBoothListing();
    };
  }, []);

  return (
    <div>
      <VoterHeaderComponent hideBackIcon={false} />

      {/* show loader */}
      {props.boothData && props.boothData.loading ? (
        <LoaderComponent displayType={props.boothData.loading} />
      ) : null}

      <div className="voter_container">
        <div className="sevabooth_container">
          {/* <h4>Jansampark</h4> */}

          {/* show data */}
          {props.boothData &&
          props.boothData.data &&
          props.boothData.data.length > 0 ? (
            props.boothData.data.map((booth) => (
              <React.Fragment key={booth._id}>
                <Link to={{ pathname: "/boothlistdetail/" + booth.code }}>
                  <ul className="sevabooth_list">
                    <li>
                      <h5>Booth No</h5>
                      <h5>Polling Station</h5>
                    </li>
                    <li className="booth_number">
                      <h5>{booth.booth_no}</h5>
                      <h5>{booth.name}</h5>
                    </li>
                    <li>
                      <img src="/images/next2.svg" alt="" />
                    </li>
                  </ul>
                </Link>
              </React.Fragment>
            ))
          ) : (
            <div>
              <h4>No Data Found</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    boothData: state.boothList,
  };
};

export default connect(mapStateToProps, {
  boothListing: (booth_code) => boothListing(booth_code),
  cleanBoothListing: () => cleanBoothListing(),
})(BoothListComponent);
