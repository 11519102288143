import React from "react";
import { Link } from "react-router-dom";
import { removeHiphensinEpicNo } from "../../utils/misc";
const ListView = (props) => {
    return (
        <React.Fragment key={props.el.epic_no}>
            <div className="">
                <div className="voter_container">
                    <div className="voterlist_detail">
                        <div className="voterlist_profile">
                            <ul>
                                <li className="dot_position">
                                    <Link
                                    to={
                                        "/voterdetail/personal/list/" +
                                        (props.el.epic_no ? props.el.epic_no : "")
                                    }
                                    >

                                    <img
                                        src={props.el.voter_data.personal.image ? props.el.voter_data.personal.image : "/images/userdefault.svg"}
                                        className="user_img"
                                        alt=""
                                    />

                                    <img
                                        src="/images/red.svg"
                                        className="red_dot"
                                        alt=""
                                    />
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                    to={
                                        "/voterdetail/personal/list/" +
                                        (props.el.epic_no ? props.el.epic_no : "")
                                    }
                                    >
                                    <h5>{props.el.name ? props.el.name : ""}</h5>
                                   
                                    </Link>
                                    <div
                                    className="voterlist_img"
                                    style={{ marginTop: "10px" }}
                                    >
                                        <p>
                                            <img src="/images/bjplogo2.svg" alt="" />
                                        </p>
                                        <p onClick={() => props.openSchemePopup(props.el.voter_data)}>
                                        
                                            <img src="/images/charity.svg" alt="" />
                                            <span>
                                            {props.el &&
                                                props.el.voter_data &&
                                                props.el.voter_data &&
                                                props.el.voter_data["schemes_beneficary"] &&
                                                Object.keys(
                                                props.el.voter_data["schemes_beneficary"]
                                                )
                                                ? Object.keys(
                                                props.el.voter_data["schemes_beneficary"]
                                                ).length
                                                : 0}
                                            </span>
                                        </p>
                                    </div>
                                </li>
                                {props.markDoneObj[props.el.epic_no] && (
                                    <li className="voter_check">
                                    <button type="button">
                                        <img src="/images/check.svg" alt="" />
                                    </button>
                                    </li>
                                )}
                        
                                <li>
                                    <button type="button">
                                    <img src="/images/bill.svg" alt="" />
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="voter_number">
                            <ul>
                                <li>
                                    <h6 className="voter_card">Epic No.:</h6>
                                </li>
                                <li>
                                    <h6>
                                    <span>
                                        {props.el.epic_no
                                        ? removeHiphensinEpicNo(props.el.epic_no)
                                        : ""}
                                    </span>
                                    </h6>
                                </li>
                            </ul>
                            
                            <ul>
                                <li>
                                    <h6 className="voter_card">Relation Name:</h6>
                                </li>
                                <li>
                                    <h6>
                                    <span>
                                        {props.el.voter_data &&
                                        props.el.voter_data.personal &&
                                        props.el.voter_data.personal.rln_name_en}
                                       
                                    </span>
                                    </h6>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <h6 className="voter_card">Age:</h6>
                                </li>
                                <li>
                                    <h6>
                                    <span>
                                        {props.el.voter_data &&
                                        props.el.voter_data.personal &&
                                        props.el.voter_data.personal.age}
                                    </span>
                                    </h6>
                                </li>
                            </ul>

                            <ul>
                                <li>
                                    <h6 className="voter_card">Mobile:</h6>
                                </li>
                                <li>
                                    {props.el.voter_data &&
                                    props.el.voter_data.communication &&
                                    props.el.voter_data.communication.mobile.length > 0 && (
                                        <h6 style={{ position: "relative" }}>
                                        <span>
                                            {props.el.voter_data.communication.mobile[0]}
                                        </span>
                                        <img
                                            src="/images/phoneblue.svg"
                                            className="user_number"
                                            alt=""
                                        />
                                        <img
                                            src="/images/message.svg"
                                            className="user_msg"
                                            alt=""
                                        />
                                        </h6>
                                    )}
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <h6 className="voter_card">House No.:</h6>
                                </li>
                                <li>
                                    <h6>
                                    <span>
                                        {props.el.voter_data &&
                                        props.el.voter_data.personal &&
                                        props.el.voter_data.personal.house_no}
                                    </span>
                                    </h6>
                                </li>
                            </ul>

                            <ul>
                                <li>
                                    <h6 className="voter_card">Address:</h6>
                                </li>
                                <li>
                                    {props.el.voter_data && props.el.voter_data.personal.add_curr && (
                                    <h6>
                                        <span>
                                        {props.el.voter_data.personal.add_curr.full_add}
                                        </span>
                                    </h6>
                                    )}
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <h6 className="voter_card">Panna Pramukh Name:</h6>
                                </li>
                                <li>
                                    <h6><span> {props.el && props.el.panna_pramukh_name && props.el.panna_pramukh_name}</span></h6>
                                </li>
                            </ul>

                        </div>
                   
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ListView;



 {/* Voter Button  */}
                    {/* <div className="voter_marked">
                        <ul>
                            <li>
                            {props.el.is_voted ? (
                                <button className="mark_vote">
                                <img src="/images/markvote2.svg" alt="" />{" "}
                                Voted{" "}
                                </button>
                            ) : (
                                <button
                                className="mark_vote"
                                onClick={() => onClickVoterMaakDone(el)}
                                >
                                <img src="/images/markvote2.svg" alt="" />{" "}
                                Mark Vote
                                </button>
                            )}
                            </li>
                            <li>
                            <button className="generate_btn">
                                <img src="/images/bill.svg" alt="" /> Generate
                                Slip
                            </button>
                            </li>
                        </ul>
                        </div> */}

                         {/* <h6>
                                        {props.el.gender &&
                                            (props.el.gender == "M"
                                            ? "Male"
                                            : props.el.gender == "O"
                                            ? "Other"
                                            : "Female")}{" "}
                                        <span>{props.el.age ? props.el.age + " Years" : ""}</span>
                                        </h6> */}
                                    {/* <button type="button" className="survey_btn">
                                        <img src="/images/survey.svg" alt="" />
                                        survey
                                        </button> */}