import {
  SEARCH_VOTER_PENDING,
  SEARCH_VOTER_SUCCESS,
  SEARCH_VOTER_ERROR,
  SEARCH_VOTER_EMPTY,
  SEARCH_VOTER_NOT_FOUND
} from "../actions/types";

const INTIAL_STATE = {
  // data: [],
  loading: false,
  success: false,
  notFound: false,
  errorMessage: null,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_VOTER_PENDING:
      return {
        ...state,
        success: false,
        loading: true,
        notFound: false,
        errorMessage: null,
        // data: [],
      };
    case SEARCH_VOTER_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        errorMessage: null,
        notFound: false,
        data: action.payload,
      };
    case SEARCH_VOTER_ERROR:
      return {
        ...state,
        success: true,
        loading: false,
        notFound: false,
        errorMessage: action.payload,
        // data: [],
      };
    case SEARCH_VOTER_EMPTY:
      return {
        ...state,
        success: true,
        loading: false,
        errorMessage: null,
        notFound: false,
        data: [],
      };
    case SEARCH_VOTER_NOT_FOUND: 
      return {
        notFound: true,
        data: [],
      }
    default:
      return state;
  }
};
