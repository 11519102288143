import { appLanguageData } from "../../../utils/language";
import { axiosGet } from "../../../utils/commonAxios";

export const educationLevel = async () => {
  try {
    //fetch religion data get api
    let educationalData = [];
    let educations = await axiosGet("/education/list");
    {
      educations.data.response &&
        educations.data.response.map((data, index) => {
          let title = appLanguageData(data.json_language_data, "title");
          educationalData.push({ value: title, label: title });
        });
    }

    return educationalData;
  } catch (error) {}
};

// export const getSubjectList = async ()=>{

//   try {
//       //fetch religion data get api
//       let regilionsListData = [];
//       let regilions = await axiosGet('/subject/list');
//       {regilions.data.response && regilions.data.response.map((data,index)=>{
//         let title = appLanguageData(data.json_language_data, "title")
//         regilionsListData.push({value:title,label:title})
//       })}

//       return regilionsListData
//     } catch (error) {}
// }

// export const getYearList = async ()=>{

//   try {
//     let currentYear = new Date().getFullYear();
//     let listOfyears = [];
//     let startYear =1950;  
//     while ( startYear <= currentYear ) {
//       listOfyears.push({value:startYear,label:startYear});
//       startYear++;
//     } 
//     return listOfyears
//   } catch (error) {}
// }

export const getProfessionList = async ()=>{
  try {
    //fetch religion data get api
    let professionList = [];
    let professions = await axiosGet('/profession/list');
    {professions.data.response && professions.data.response.map((data,index)=>{
      let title = appLanguageData(data.json_language_data, "title")
      professionList.push({value:title,label:title})
    })}

    return professionList
  } catch (error) {}
}

export const subjectList = async () => {
  try {
    //fetch religion data get api
    let subjectListData = [];
    let subjects = await axiosGet("/subject/list");
    {
      subjects.data.response &&
        subjects.data.response.map((data, index) => {
          let title = appLanguageData(data.json_language_data, "title");
          subjectListData.push({ value: title, label: title });
        });
    }

    return subjectListData;
  } catch (error) {}
};

export const professionList = async () => {
  try {
    //fetch religion data get api
    let professionListData = [];
    let professions = await axiosGet("/profession/list");
    {
      professions.data.response &&
        professions.data.response.map((data, index) => {
          let title = appLanguageData(data.json_language_data, "title");
          professionListData.push({ value: title, label: title });
        });
    }

    return professionListData;
  } catch (error) {}
};
