import {
  FETCH_POST_PENDING,
  FETCH_POST_SUCCESS,
  FETCH_POST_ERROR,
} from "../actions/types";

const INTIAL_STATE = {
  data: [],
  success: false,
  errorMessage: null,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_POST_PENDING:
      return {
        ...state,
        success: false,
        errorMessage: null,
        data: [],
      };
    case FETCH_POST_SUCCESS:
      return {
        ...state,
        success: true,
        errorMessage: null,
        data: action.payload,
      };
    case FETCH_POST_ERROR:
      return {
        ...state,
        success: true,
        errorMessage: action.payload,
        data: [],
      };
    default:
      return state;
  }
};
