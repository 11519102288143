import {
  CATEGORY_LIST_PENDING,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_EMPTY,
  CATEGORY_LIST_ERROR,
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  success: false,
  notFound: false,
  errorMessage: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CATEGORY_LIST_PENDING:
      return {
        ...state,
        loading: true,
        success: false,
        notFound: false,
        errorMessage: null,
      };

    case CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        notFound: false,
        errorMessage: null,
        data: action.payload,
      };

    case CATEGORY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        notFound: false,
        errorMessage: action.payload,
      };

    case CATEGORY_LIST_EMPTY:
      return {
        ...state,
        loading: false,
        success: false,
        notFound: true,
        errorMessage: null,
      };

    default:
      return state;
  }
};
