import { appLanguageData } from "../../../utils/language";
import { axiosGet } from "../../../utils/commonAxios";

export const getPoliticalProfileList = async () => {
  try {
    //fetch religion data get api
    let politicalList = [];
    let political = await axiosGet("/voter_designation/list");
    {
      political.data.response &&
        political.data.response.map((data, index) => {
          let title = appLanguageData(data.json_language_data, "title");
          politicalList.push({ value: title, label: title });
        });
    }

    return politicalList;
  } catch (error) {}
};

export const getUnitList = async () => {
  try {
    //fetch religion data get api
    let unitList = [];
    let units = await axiosGet("/voter_unit/list");
    {
      units.data.response &&
        units.data.response.map((data, index) => {
          let title = appLanguageData(data.json_language_data, "title");
          unitList.push({ value: title, label: title });
        });
    }

    return unitList;
  } catch (error) {}
};

export const getPartyList = async () => {
  try {
    //fetch religion data get api
    let partyList = [];
    let party = await axiosGet("/party/list");
    {
      party.data.response &&
        party.data.response.map((data, index) => {
          let title = appLanguageData(data.json_language_data, "title");
          partyList.push({ value: title, label: title });
        });
    }

    return partyList;
  } catch (error) {}
};

// export const getYearList = async ()=>{

//   try {
//     let currentYear = new Date().getFullYear();
//     let listOfyears = [];
//     let startYear =1950;
//     while ( startYear <= currentYear ) {
//       listOfyears.push({value:startYear,label:startYear});
//       startYear++;
//     }
//     return listOfyears
//   } catch (error) {}
// }

// export const getNumbers = async ()=>{

//     try {
//       let maxNumber = 20;
//       let numbersList = [];
//       let startNumber =1;
//       while ( startNumber <= maxNumber ) {
//         if(startNumber <= 9){
//             numbersList.push({value:'0'+startNumber,label:'0'+startNumber});
//         }else{
//             numbersList.push({value:startNumber,label:startNumber});
//         }
//         startNumber++;
//       }
//       return numbersList
//     } catch (error) {}
// }
