import React, { useState, useEffect } from "react";
import { VoterHeaderMenu } from "../VoterHeaderMenu";

import ChildInfo from "./ChildInfo";
import Select from "react-select";
import { useParams, useHistory } from "react-router-dom";
import {
  categoryList,
  languageList,
  stateList,
  districtList,
  religionsList,
} from "./HandleOnChange";
import { axiosPost } from "../../../utils/commonAxios";
import { relationType, maritalStatus } from "../../common/Function";

import { formatDate } from "../../../utils/misc";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import imageCompression from "browser-image-compression";
import Button from "@material-ui/core/Button";
import Modal from "react-modal";
import getCroppedImg from "../../../utils/cropImage";
import LoaderComponent from "../../loader/LoaderComponent";
import "../voterDetail.css";
import "./PersonalDetails.css";

import { AlertBox } from "../../../components/common/AlertBox"

// var fs = require('fs');
import fs from 'fs';

const customStyles = {
  content: {
    top: "27%",
    left: "50%",
    right: "10%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "330px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
};

function PersonalDetails(props) {
  const { epic_id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [childForm, setChildForm] = useState({
    name: "",
    age: "",
    childrenOccupation: "",
    gender: "",
    child_voter: "",
    child_aadhar: "",
  });

  const [inputFormData, setInputFormData] = useState({
    showChildInfo: false,
  });

  const [inputFormData_1, setInputFormData_1] = useState({
    aadhar_no: "",
    epic_no: "",
    is_nri_voter: "",
    passport_no: "",
    country: "",
    name_en: "",
    name_oth: "",
    dob: "",
    age: 0,
    gender: "",
    image: "",
    rln_name_en: "",
    rln_name_oth: "",
    father_name: "",
    father_epic_no: "",
    mother_name: "",
    mother_epic_no: "",
    spouse_name: "",
    spouse_epic_no: "",
    cast: "",
    sub_cast: "",
    part_no: "",
    section_no: "",
    booth_code: "",
    serial_no: "",
    house_no: "",
    house_no_oth: "",
    location: "",
    vote_at: "",
    vs_ac_no: "",
  });

  const [inputFormData_array, setInputFormData_array] = useState({
    religion: [],
    category: [],
    cast: [],
    languageKnown: [],
    nativeState: [],
    nativeDistrict: [],
    maritalStatusArr: [],
  });

  const [image, setImage] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [previewImage, setPreviewImage] = useState("/images/userdefault.svg");

  const [inputChildFormData, setInputChildFormData] = useState([childForm]);

  const [selectedOption, setSelectedOption] = useState({
    SelectedRegilion: null,
    SelectedCategory: null,
    SelectedTonuge: null,
    SelectedLanguage: null,
    SelectedState: null,
    SelectedDistrict: null,
  });

  const [isLoading, setIsLoading] = useState({
    regilionsLoading: false,
    categoryLoading: false,
    languageLoading: false,
    stateLoading: false,
    districtLoading: false,
  });

  const [currentAddress, setCurrentAddress] = useState({
    full_add: "",
    full_add_oth: "",
    pincode: "",
  });

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    //get Regilion list
    fetchReligion();
    setFormField();
  }, []);

  function openModal() {
    setIsOpen(true);
  }
  const getCanvasBlob = async (canvas) => {
    return new Promise(function (resolve, reject) {
      canvas.toBlob(function (blob) {
        resolve(blob)
      }, 'image/jpeg')
    })
  }

  const closeModal = async (type) => {
    setLoading(true)
    if (type === "done") {
      const canvas = await getCroppedImg(image, croppedArea);
      getCanvasBlob(canvas).then((val) => {

        let form = new FormData();
        form.append("image", val);
        form.append('epic_no', epic_id);

        var requestOptions = {
          method: "POST",
          body: form,
          // redirect: "follow",
        };

        let url;
        if (window.location.hostname === "localhost") {
          url = "http://localhost:8080/v1";
        } else {
          url = "https://api.abhiyaaan.in/v1";
        }
        fetch(`${url}/voter/upload_image`, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            result = JSON.parse(result);
            if (result.resultFlag == "1") {
              setPreviewImage(result.response);
            }
          }).catch((error) => console.log("error", error));

      });
    }
    setIsOpen(false);
    setLoading(false)
  };

  const setFormField = async () => {
    // epic_id
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) { }

    let reqobject = {
      action: "/voter/search",
      epic_no: epic_id,
    };

    try {
      // let resp = await axiosGet("/voter/get_jansampark_count", params);
      const resp = await axiosPost(reqobject, checkuser);

      if (resp.data.resultFlag) {
        const { personal } = resp.data.response;

        let updatedData = {};
        updatedData["is_nri_voter"] = personal["is_nri_voter"];
        updatedData["aadhar_no"] = personal["aadhar_no"] ? personal["aadhar_no"] : "";
        updatedData["passport_no"] = personal["passport_no"];
        updatedData["name_en"] = personal["name_en"];
        updatedData["name_oth"] = personal["name_oth"];
        updatedData["dob"] = formatDate(personal["dob"]);
        updatedData["age"] = personal["age"];
        updatedData["gender"] = personal["gender"];
        updatedData["rln_name_en"] = personal["rln_name_en"];
        updatedData["rln_epic_no"] = personal["rln_epic_no"];
        // updatedData["rln_name_oth"] = personal["rln_name_oth"];
        updatedData["cast"] = personal["cast"];
        updatedData["sub_cast"] = personal["sub_cast"];
        updatedData["house_no"] = personal["house_no"];
        updatedData["image"] = personal["image"];
        updatedData["booth_code"] = personal["booth_code"];
        updatedData["vote_at"] = personal["vote_at"];
        updatedData["location"] = personal["location"];

        updatedData["serial_no"] = personal["serial_no"];

        updatedData["occupation"] = personal["occupation"];
        updatedData["vs_ac_no"] = personal["vs_ac_no"];
        updatedData["part_no"] = personal["part_no"];
        updatedData["section_no"] = personal["section_no"];
        // religion
        if (personal["religion"]) {
          let religion = [];
          religion = {
            value: personal["religion"],
            label: personal["religion"],
          };
          setSelectedOption((prevState) => ({
            ...prevState,
            SelectedRegilion: religion,
          }));
        }

        // category
        if (personal["category"]) {
          let category = [];
          category = {
            value: personal["category"],
            label: personal["category"],
          };
          setSelectedOption((prevState) => ({
            ...prevState,
            SelectedCategory: category,
          }));
        }
        //MOTHRE TONGUE
        if (personal["mother_tongue"]) {
          let mother_tongue = [];
          mother_tongue = {
            value: personal["mother_tongue"],
            label: personal["mother_tongue"],
          };
          setSelectedOption((prevState) => ({
            ...prevState,
            SelectedTonuge: mother_tongue,
          }));
        }

        //Relation Type
        if (personal["rln_type"]) {
          let rln_type = [];
          rln_type = {
            value: personal["rln_type"],
            label: personal["rln_type"],
          };
          setSelectedOption((prevState) => ({
            ...prevState,
            SelectedRelationType: rln_type,
          }));
        }

        //marital status
        if (personal["marital_status"]) {
          let marital_status = [];
          marital_status = {
            value: personal["marital_status"],
            label: personal["marital_status"],
          };
          setSelectedOption((prevState) => ({
            ...prevState,
            SelectedMaritalStatus: marital_status,
          }));
        }

        if (personal["country"]) {
          let country = [];
          country = {
            value: personal["country"],
            label: personal["country"],
          };
          setSelectedOption((prevState) => ({
            ...prevState,
            SelectedCountry: country,
          }));
        }

        // language_known
        if (personal["language_known"]) {
          let language_known = [];

          if (personal["language_known"].length > 0) {
            personal["language_known"].forEach((lang) => {
              language_known.push({ value: lang.value, label: lang.label });
            });
          }
          setSelectedOption((prevState) => ({
            ...prevState,
            SelectedLanguage: language_known,
          }));
        }

        // address
        if (personal["add_curr"]) {
          setCurrentAddress((prevState) => ({
            ...prevState,
            full_add: personal["add_curr"]["full_add"],
            full_add_oth: personal["add_curr"]["full_add_oth"],
            pincode: personal["add_curr"]["pincode"],
          }));

          if (personal["add_curr"]["state_code"]) {
            let state_code = [];
            state_code = {
              value: personal["add_curr"]["state_code"],
              label: personal["add_curr"]["state_code"],
            };
            setSelectedOption((prevState) => ({
              ...prevState,
              SelectedState: state_code,
            }));
          }
          if (personal["add_curr"]["dist_code"]) {
            let dist_code = [];
            dist_code = {
              value: personal["add_curr"]["dist_code"],
              label: personal["add_curr"]["dist_code"],
            };
            setSelectedOption((prevState) => ({
              ...prevState,
              SelectedDistrict: dist_code,
            }));
          }
        }

        // children

        if (personal["children"]) {
          let newArr = inputChildFormData;
          for (let i = 1; i < personal["children"].length; i++) {
            newArr.push(childForm);
          }

          let childState = inputChildFormData.slice();
          for (let i = 0; i < personal["children"].length; i++) {
            // console.log(personal['children'][i]);

            let updateChild = childState[i];
            let formElement = {
              // ...updateChild,
              name: personal["children"][i]["name"],
              gender: personal["children"][i]["gender"],
              age: personal["children"][i]["age"],
              childrenOccupation: personal["children"][i]["childrenOccupation"],
              child_aadhar: personal["children"][i]["child_aadhar"],
              child_voter: personal["children"][i]["child_voter"],
            };
            childState[i] = formElement;
          }
          setInputChildFormData([...childState]);
          setInputFormData((prevState) => {
            return {
              ...prevState,
              showChildInfo: true,
            };
          });
        }
        setInputFormData_1((prevState) => ({
          ...prevState,
          ...updatedData,
        }));
        setPreviewImage(personal.image)
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReligion = async () => {
    setIsLoading({ regilionsLoading: true });

    let religionsListData = await religionsList();
    setInputFormData_array((prevState) => ({
      ...prevState,
      religion: religionsListData,
    }));
    //category api
    let categoryListData = await categoryList();
    setInputFormData_array((prevState) => ({
      ...prevState,
      category: categoryListData,
    }));

    //language api
    let languageListData = await languageList();
    setInputFormData_array((prevState) => ({
      ...prevState,
      languageKnown: languageListData,
    }));

    //State list api
    let stateListData = await stateList();
    setInputFormData_array((prevState) => ({
      ...prevState,
      nativeState: stateListData,
    }));

    //Realation Type
    let relations = await relationType();
    setInputFormData_array((prevState) => ({
      ...prevState,
      familyMemberRelationship: relations,
    }));

    //Realation Type
    let marital = await maritalStatus();
    setInputFormData_array((prevState) => ({
      ...prevState,
      maritalStatusArr: marital,
    }));

    setIsLoading({ regilionsLoading: false });
  };

  const update_form = (e) => {
    const { name, value } = e.target;
    if (name === "dob") {
      let age = ageCalculat(new Date(value));
      console.log(age);
      setInputFormData_1((prevState) => ({
        ...prevState,
        age: age,
      }));
    }
    setInputFormData_1((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const ageCalculat = (dob) => {
    let cdate = new Date();
    var diff = cdate.getTime() - dob.getTime();
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
  };

  const update_address = (e) => {
    const { name, value } = e.target;
    setCurrentAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };
  //Crop Image Handler
  const fileChangedHandler = async (e) => {
    // console.log(e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        // console.log(reader.result);
        setImage(reader.result);
        openModal();
      });
    }
  };

  // const fileChangedHandler = async (event) => {
  //   try {
  //     const options = {
  //       maxSizeMB: 5,
  //       maxWidthOrHeight: 500,
  //       useWebWorker: true,
  //     };
  //     const imageFile = event.target.files[0];

  //     //compressing file
  //     const compressedFile = await imageCompression(imageFile, options);
  //     var formdata = new FormData();
  //     formdata.append("filename", compressedFile, compressedFile.name);
  //     formdata.append('epic_no',epic_id);
  //     var requestOptions = {
  //       method: "POST",
  //       body: formdata,
  //       redirect: "follow",
  //     };
  //     let url;
  //     if (window.location.hostname === "localhost") {
  //       url = "http://localhost:8080/v1";
  //     } else {
  //       url = "https://api.abhiyaaan.in/v1";
  //     }

  //     fetch(`${url}/voter/upload_image`, requestOptions)
  //       .then((response) => response.text())
  //       .then((result) => {
  //         result = JSON.parse(result);
  //         if (result.resultFlag == "1") {
  //           setPreviewImage(result.response);
  //         }
  //       })
  //       .catch((error) => console.log("error", error));
  //   } catch (error) {}
  // };

  const updateChildForm = (element, position) => {
    let childState = inputChildFormData.slice();

    let updateChild = childState[position];
    let formElement = {
      ...updateChild,
      [element.target.name]: element.target.value,
    };

    childState[position] = formElement;
    setInputChildFormData([...childState]);
  };

  const updateChildFormRadio = (element, position) => {
    const { name, value } = element.target;
    console.log(name, value);
    let childState = inputChildFormData.slice();

    let updateChild = childState[position];
    let formElement = {
      ...updateChild,
      // [element.target.name]: element.target.id,
      ["gender"]: value,
    };

    childState[position] = formElement;
    setInputChildFormData([...childState]);
  };

  const toggleChildSection = (event) => {
    event.preventDefault();

    setInputFormData((prevState) => {
      return {
        ...prevState,
        showChildInfo: !prevState.showChildInfo,
      };
    });
  };

  const addMoreChild = () => {
    let newArr = inputChildFormData;
    newArr.push(childForm);
    setInputFormData((prevState) => {
      return {
        ...prevState,
      };
    });
  };

  const removeChildArr = (position) => {
    let newArr = inputChildFormData;
    newArr.splice(position, 1);
    setInputFormData((prevState) => {
      return {
        ...prevState,
      };
    });
  };

  const handleChange = async (selectedOpt, type) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      [type]: selectedOpt,
    }));

    if (type === "SelectedState") {
      let ditrictListData = await districtList(selectedOpt.value);
      setInputFormData_array((prevState) => ({
        ...prevState,
        nativeDistrict: ditrictListData,
      }));
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    let personal = {};

    personal = inputFormData_1;
    personal["is_nri_voter"] = Number(inputFormData_1["is_nri_voter"]);
    personal["add_curr"] = {
      country:
        selectedOption["SelectedCountry"] &&
        selectedOption["SelectedCountry"]["value"],
      state_code:
        selectedOption["SelectedState"] &&
        selectedOption["SelectedState"]["value"],
      dist_code:
        selectedOption["SelectedDistrict"] &&
        selectedOption["SelectedDistrict"]["value"],
      full_add: currentAddress["full_add"],
      full_add_oth: currentAddress["full_add_oth"],
      pincode: currentAddress["pincode"],
    };
    personal["country"] =
      selectedOption["SelectedCountry"] &&
      selectedOption["SelectedCountry"]["value"];
    personal["marital_status"] =
      selectedOption["SelectedMaritalStatus"] &&
      selectedOption["SelectedMaritalStatus"]["value"];
    personal["rln_type"] =
      selectedOption["SelectedRelationType"] &&
      selectedOption["SelectedRelationType"]["value"];

    personal["religion"] =
      selectedOption["SelectedRegilion"] &&
      selectedOption["SelectedRegilion"]["value"];
    personal["category"] =
      selectedOption["SelectedCategory"] &&
      selectedOption["SelectedCategory"]["value"];
    personal["language_known"] = selectedOption["SelectedLanguage"]
      ? selectedOption["SelectedLanguage"]
      : [];
    personal["mother_tongue"] =
      selectedOption["SelectedTonuge"] &&
      selectedOption["SelectedTonuge"]["value"];

    personal["children"] = inputChildFormData;
    personal["image"] = previewImage;

    // send in api
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) { }

    let reqobject = {
      action: "/voter/save_voter_data",
      data: {
        epic_no: epic_id,
        personal,
      },
    };
    try {
      const resp = await axiosPost(reqobject, checkuser);
      if (resp.data.resultFlag) {
        AlertBox("Success Message", resp.data.message);
      } else {
        AlertBox("Error Message", resp.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      {/* <button onClick={openModal}>Open Modal</button> */}

      <Modal
        // appElement={el}
        isOpen={isOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Crop profile image"
        ariaHideApp={false}
      >
        <div className="container-cropper">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            showGrid={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => onZoomChange(zoom)}
          // classes={{ container: "slider" }}
          />
          <div className="container-button">
            <Button
              variant="contained"
              color="secondary"
              onClick={(type) => closeModal("cancel")}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(type) => closeModal("done")}
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
      <VoterHeaderMenu>
        {/* show loader */}
        <LoaderComponent displayType={loading} />
        <form id="personal_details" encType="multipart/form-data">
          <div className="voter_container">
            <div className="voter_question2">
              {/* <h6>Picture</h6> */}
              <img src={previewImage ? previewImage : "/images/userdefault.svg"} alt="" className="user_profile" />
              <ul>
                <li>
                  <div style={{ position: "relative" }}>
                    <input type="file" name="image" accept="image/*" onChange={fileChangedHandler} />
                    <button>
                      <img src="/images/gallery.svg" alt="" />
                      Upload From Gallery
                    </button>
                  </div>
                </li>
                <li>
                  <div style={{ position: "relative" }}>
                    <input type="file" name="image" accept="image/*" onChange={fileChangedHandler} />
                    <button>
                      <img src="/images/camera.svg" alt="" />
                      Click
                    </button>
                  </div>
                </li>
              </ul>

              <h6>Name In English*</h6>
              <input
                type="text"
                name="name_en"
                placeholder="Name In English"
                value={inputFormData_1.name_en}
                onChange={update_form}
              />

              <h6>Name In Other*</h6>
              <input
                type="text"
                name="name_oth"
                placeholder="Name In Other"
                value={inputFormData_1.name_oth}
                onChange={update_form}
              />
            </div>

            <div className="voter_question2">
              <h6>Gender*</h6>
              <ul className="gender_listing">
                <li>
                  <label className="personal_container">
                    Male
                    <input
                      type="radio"
                      id="male"
                      value="M"
                      name="gender"
                      checked={inputFormData_1.gender === "M" && true}
                      onChange={(element) => update_form(element)}
                    />
                    <span className="personal_checkmark"></span>
                  </label>
                </li>

                <li>
                  <label className="personal_container">
                    Female
                    <input
                      type="radio"
                      id="female"
                      value="F"
                      name="gender"
                      checked={inputFormData_1.gender === "F" && true}
                      onChange={(element) => update_form(element)}
                    />
                    <span className="personal_checkmark"></span>
                  </label>
                </li>

                <li>
                  <label className="personal_container">
                    Others
                    <input
                      type="radio"
                      id="other"
                      value="O"
                      name="gender"
                      checked={inputFormData_1.gender === "O" && true}
                      onChange={(element) => update_form(element)}
                    />
                    <span className="personal_checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="voter_question2">
              <h6>Marital status</h6>
              <div className="select_margin">
                <Select
                  value={selectedOption.SelectedMaritalStatus}
                  onChange={(e) => handleChange(e, "SelectedMaritalStatus")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={inputFormData_array.maritalStatusArr}
                />
              </div>
            </div>

            {/* <div className="voter_question2">
              <h6>Marital status</h6>
              <input
                type="text"
                name="marital"
                placeholder="Marital status"
                value={inputFormData_1.marital}
                onChange={update_form}
              />
            </div> */}

            <div className="voter_question2">
              <h6>Relation Type</h6>
              <div className="select_margin">
                <Select
                  value={selectedOption.SelectedRelationType}
                  onChange={(e) => handleChange(e, "SelectedRelationType")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={inputFormData_array.familyMemberRelationship}
                />
              </div>
            </div>
            {(selectedOption.SelectedRelationType &&
              selectedOption.SelectedRelationType.value) === "father" && (
                <React.Fragment>
                  <div className="voter_question2">
                    <h6>Father Name</h6>
                    <input
                      type="text"
                      name="rln_name_en"
                      placeholder="Father Name"
                      value={inputFormData_1.rln_name_en}
                      onChange={update_form}
                    />
                  </div>
                  <div className="voter_question2">
                    <h6>Father Epic Number</h6>
                    <input
                      type="text"
                      name="rln_epic_no"
                      placeholder="Father Epic Number"
                      value={inputFormData_1.rln_epic_no}
                      onChange={update_form}
                    />
                  </div>
                </React.Fragment>
              )}
            {(selectedOption.SelectedRelationType &&
              selectedOption.SelectedRelationType.value) === "mother" && (
                <React.Fragment>
                  <div className="voter_question2">
                    <h6>Mother Name</h6>
                    <input
                      type="text"
                      name="rln_name_en"
                      placeholder="Mother Name"
                      value={inputFormData_1.rln_name_en}
                      onChange={update_form}
                    />
                  </div>
                  <div className="voter_question2">
                    <h6>Mother Epic Number</h6>
                    <input
                      type="text"
                      name="rln_epic_no"
                      placeholder="Mother Epic Number"
                      value={inputFormData_1.rln_epic_no}
                      onChange={update_form}
                    />
                  </div>
                </React.Fragment>
              )}
            {(selectedOption.SelectedRelationType &&
              selectedOption.SelectedRelationType.value) === "spouse" && (
                <React.Fragment>
                  <div className="voter_question2">
                    <h6>Spouse Name</h6>
                    <input
                      type="text"
                      name="rln_name_en"
                      placeholder="Spouse Name"
                      value={inputFormData_1.rln_name_en}
                      onChange={update_form}
                    />
                  </div>
                  <div className="voter_question2">
                    <h6>Spouse Epic Number</h6>
                    <input
                      type="text"
                      name="rln_epic_no"
                      placeholder="Spouse Epic Number"
                      value={inputFormData_1.rln_epic_no}
                      onChange={update_form}
                    />
                  </div>
                </React.Fragment>
              )}

            <div className="voter_question2">
              <h6>Religion*</h6>
              <div className="select_margin">
                <Select
                  isLoading={isLoading.regilionsLoading}
                  value={selectedOption.SelectedRegilion}
                  onChange={(e, type) => handleChange(e, "SelectedRegilion")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={inputFormData_array.religion}
                />
              </div>
            </div>

            <div className="voter_question2">
              <h6>Category*</h6>
              <div className="select_margin">
                <Select
                  isLoading={isLoading.categoryLoading}
                  value={selectedOption.SelectedCategory}
                  onChange={(e, type) => handleChange(e, "SelectedCategory")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={inputFormData_array.category}
                />
              </div>
            </div>

            <div className="voter_question2">
              <h6>Cast*</h6>
              <input
                type="text"
                name="cast"
                placeholder="Cast"
                value={inputFormData_1.cast}
                onChange={update_form}
              />
            </div>

            <div className="voter_question2">
              <h6>Sub Caste*</h6>
              <input
                type="text"
                name="sub_cast"
                placeholder="Sub Cast"
                value={inputFormData_1.sub_cast}
                onChange={update_form}
              />
            </div>

            <div className="voter_question2">
              <h6>Languages Known*</h6>
              <div className="select_margin">
                <Select
                  isMulti={true}
                  isLoading={isLoading.languageLoading}
                  value={selectedOption.SelectedLanguage}
                  onChange={(e, type) => handleChange(e, "SelectedLanguage")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={inputFormData_array.languageKnown}
                />
              </div>
            </div>

            <div className="voter_question2">
              <h6>Mother Tongue*</h6>
              <div className="select_margin">
                <Select
                  value={selectedOption.SelectedTonuge}
                  onChange={(e, type) => handleChange(e, "SelectedTonuge")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={inputFormData_array.languageKnown}
                />
              </div>
            </div>

            <div className="voter_question2">
              <h6>Date of Birth*</h6>
              <input
                type="date"
                name="dob"
                placeholder=""
                value={inputFormData_1.dob}
                onChange={update_form}
              />
            </div>

            <div className="voter_question2">
              <h6>House Number</h6>
              <input
                type="text"
                name="house_no"
                placeholder="House Number"
                value={inputFormData_1.house_no}
                onChange={update_form}
              />
            </div>

            <div className="voter_question2">
              <h6>Native State*</h6>
              <div className="select_margin">
                <Select
                  isLoading={isLoading.stateLoading}
                  value={selectedOption.SelectedState}
                  onChange={(e, type) => handleChange(e, "SelectedState")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={inputFormData_array.nativeState}
                />
              </div>
            </div>

            <div className="voter_question2">
              <h6>Native District*</h6>
              <div className="select_margin">
                <Select
                  isLoading={isLoading.districtLoading}
                  value={selectedOption.SelectedDistrict}
                  onChange={(e, type) => handleChange(e, "SelectedDistrict")}
                  className="basic-single"
                  classNamePrefix="select"
                  options={inputFormData_array.nativeDistrict}
                />
              </div>
            </div>

            <div className="voter_question2">
              <h6>Native Address*</h6>
              <input
                type="text"
                name="full_add"
                placeholder="Native Address*"
                value={currentAddress.full_add}
                onChange={update_address}
              />
            </div>
            <div className="voter_question2">
              <h6>Native Pincode*</h6>
              <input
                type="number"
                name="pincode"
                placeholder="Native Pincode"
                value={currentAddress.pincode}
                onChange={update_address}
              />
            </div>

            {/* <div className="voter_question2">
              <h6>Father's Name*</h6>
              <input
                type="text"
                name="passport_no"
                placeholder="Father's Name"
                value={inputFormData_1.passport_no}
                onChange={update_form}
              />
            </div> */}

            {/* <div className="voter_question2">
              <h6>Occupation</h6>
              <input
                type="text"
                name="passport_no"
                placeholder="Occupation"
                value={inputFormData_1.passport_no}
                onChange={update_form}
              />
            </div> */}

            {/* <div className="voter_question2">
              <h6>Mother's Name*</h6>
              <input
                type="text"
                name="passport_no"
                placeholder="Mother's Name"
                value={inputFormData_1.passport_no}
                onChange={update_form}
              />
            </div> */}

            <div className="voter_question2">
              <h6>Occupation</h6>
              <input
                type="text"
                name="passport_no"
                placeholder="Occupation"
                value={inputFormData_1.passport_no}
                onChange={update_form}
              />
            </div>

            <div className="voter_question2">
              <h6>Aadhar Number*</h6>
              <input
                type="text"
                name="aadhar_no"
                placeholder="Aadhar Number"
                value={inputFormData_1.aadhar_no}
                onChange={update_form}
              />
            </div>

            <div className="voter_question2">
              <h6>Vidhansabha account number</h6>
              <input
                type="text"
                name="vs_ac_no"
                placeholder="Vidhansabha account number"
                value={inputFormData_1.vs_ac_no}
                onChange={update_form}
              />
            </div>
            <div className="voter_question2">
              <h6>Part Number</h6>
              <input
                type="text"
                name="part_no"
                placeholder="Part Number"
                value={inputFormData_1.part_no}
                onChange={update_form}
              />
            </div>
            <div className="voter_question2">
              <h6>Section Number</h6>
              <input
                type="text"
                name="section_no"
                placeholder="Section Number"
                value={inputFormData_1.section_no}
                onChange={update_form}
              />
            </div>
            <div className="voter_question2">
              <h6>Booth Code</h6>
              <input
                type="text"
                name="booth_code"
                placeholder="Booth Code"
                value={inputFormData_1.booth_code}
                onChange={update_form}
                readOnly
              />
            </div>

            <div className="voter_question2">
              <h6>Serial Number</h6>
              <input
                type="text"
                name="serial_no"
                placeholder="Serial Number"
                value={inputFormData_1.serial_no}
                onChange={update_form}
              />
            </div>

            <div className="voter_question2">
              <h6>Location</h6>
              <input
                type="text"
                name="location"
                placeholder="Location"
                value={inputFormData_1.location}
                onChange={update_form}
              />
            </div>
            <div className="voter_question2">
              <h6>Vote At</h6>
              <input
                type="text"
                name="vote_at"
                placeholder="Vote At"
                value={inputFormData_1.vote_at}
                onChange={update_form}
              />
            </div>
            <div className="child_btn">
              <button onClick={toggleChildSection}>
                {inputFormData.showChildInfo
                  ? "Hide Child Info"
                  : "Show Child Info"}
              </button>
            </div>
            {inputFormData.showChildInfo ? (
              <div className="mainchild_div">
                <div>
                  <img
                    src="/images/pluscircle.svg"
                    className="plus_circle"
                    alt=""
                    onClick={addMoreChild}
                  />
                </div>
                {inputChildFormData.map((childFormData, i) => {
                  return (
                    <div key={i}>
                      <div style={{ position: "relative" }}>
                        {i + 1 >= 2 ? (
                          <button
                            className="minus_btn2"
                            onClick={() => removeChildArr(i)}
                          >
                            <img src="/images/minus.svg" alt="" />
                          </button>
                        ) : null}
                      </div>
                      <div>
                        <ChildInfo
                          rowPosition={i}
                          formData={childFormData}
                          updateChildForm={updateChildForm}
                          updateChildFormRadio={updateChildFormRadio}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}

            <div className="child_btn">
              <button onClick={submitForm}>update</button>
            </div>
          </div>
        </form>
      </VoterHeaderMenu>
    </div>
  );
}

export { PersonalDetails };
