import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// auth
import Auth from "./hoc/auth";

// login
import Login from "./pages/login/LoginPage";

// home
import HomePage from "./pages/home/HomePage";
// login2

// otp
import OtpComponent from "./components/otp/OtpComponent";

// scheme
// import Scheme from "./components/voter/scheme/Scheme";

// voter / sangthan
import SangathanPage from "./pages/voter/SangathanPage";

import LoaderComponent from "./components/loader/LoaderComponent";

import VoterList from "./components/voter/VoterList";

import VoterListData from "./components/voter/VoterListData";
import BoothDetailsPage from "./pages/booth/BoothDetailsPage";
import BoothListPage from "./pages/booth/BoothListPage";
import KaryakartaPage from "./pages/karyakarta/KaryakartaPage";

// my booth page
import MyBoothDetailsPage from "./pages/booth/MyBoothDetailsPage";

// route map
import MapRoutePage from "./pages/map/MapRoutePage";

// sector
import SectorPage from "./pages/sector/SectorPage";
import SectorDetails from "./components/sector/SectorDetails";
import SectorBoothLists from "./components/sector/SectorBoothLists";

// voter details
import Personal from "./pages/voterDetails/Personal";
import Communication from "./pages/voterDetails/Communication";
import Education from "./pages/voterDetails/Education";
import Political from "./pages/voterDetails/Political";

// voter details list
import PersonalList from "./pages/voterDetails/PersonalList";
import CommunicationList from "./pages/voterDetails/CommunicationList";
import EducationList from "./pages/voterDetails/EducationList";
import PoliticalList from "./pages/voterDetails/PoliticalList";

//Karyakarta Create
import KarykartaCreate from "./pages/karyakarta/KarykartaCreate";

// Karyakarta List
import KaryakartaList from "./components/voter/karykarta/KarykartaList";
import KaryakartaInfo from "./components/voter/karykarta/KarykartaInfo";

// KaryakartaStatic
import Karyakarta from "./components/voter/Karyakarta";
import KaryakartaPopup from "./components/voter/KaryakartaPopup";

// not found page
import SurveyList from "./components/voter/SurveyList";
import PersonalDetailsList from "./components/voterDetail/personal/PersonalDetailsList";
import CommunicationDetailsList from "./components/voterDetail/communication/CommunicationDetailsList";
import EducationalDetailsList from "./components/voterDetail/educational/EducationalDetailsList";
import PoliticalDetailsList from "./components/voterDetail/polical/PoliticalDetailsList";
import Loksabha from "./components/voterDetail/polical/Loksabha";
import AssignVoters from "./components/voter/assign/AssignVoters";
import UnssignVoters from "./components/voter/assign/UnssignVoters";
import AssignedVoterList from "./components/voter/karykarta/AssignedVoterList";
import FindVoters from "./components/search/FindVoters";
import FindVotersList from "./components/search/FindVotersList";

import MandalComponent from "./components/zila/MandalComponent";

// not found page
import NotFoundPage from "./components/notfound/NotFoundPage";

// electroll list
import ElectoralList from "./components/electoral/ElectoralList";

const Routes = () => {
  return (
    <div className="">
      <LoaderComponent />

      <Router>
        <Switch>
          {/* <Header /> */}
          <Route path="/" exact component={Auth(Login)} />
          <Route path="/login" exact component={Auth(Login)} />
          <Route path="/home" exact component={Auth(HomePage)} />

          <Route path="/otp" exact component={OtpComponent} />
          <Route path="/sangathan" exact component={Auth(SangathanPage)} />
          <Route path="/voter/:vid" exact component={Auth(SangathanPage)} />
          <Route path="/survey-list/:vid" exact component={Auth(SurveyList)} />
          {/* <Route path="/voter" exact component={Auth(Voterdetail)} /> */}
          <Route path="/loader" exact component={Auth(LoaderComponent)} />
          <Route path="/menulist" exact component={Auth(VoterList)} />
          <Route path="/voterlistdata" exact component={Auth(VoterListData)} />
          <Route path="/routemap" exact component={Auth(MapRoutePage)} />

          <Route path="/boothlist" exact component={Auth(BoothListPage)} />
          <Route path="/find-voters" exact component={Auth(FindVoters)} />
          <Route
            path="/find-voters-list"
            exact
            component={Auth(FindVotersList)}
          />
          <Route
            path="/assign-voters/:boothid/:aid"
            exact
            component={Auth(AssignVoters)}
          />
          <Route
            path="/un-assign-voters/:boothid/:aid"
            exact
            component={Auth(UnssignVoters)}
          />
          <Route
            path="/karyakartalist/:booth_code/:abhiyaan_id?"
            exact
            component={Auth(KaryakartaList)}
          />

          <Route
            path="/karyakartainfo/:booth_code/:abhiyaan_id?"
            exact
            component={Auth(KaryakartaInfo)}
          />
          <Route
            path="/assigned-voters-list/:booth_code/:agent_id?"
            exact
            component={Auth(AssignedVoterList)}
          />

          <Route
            path="/boothlists/:sector_code"
            exact
            component={Auth(SectorBoothLists)}
          />
          {/* boothlists/:sector_code SectorBoothLists */}

          <Route
            path="/boothlistdetail/:code"
            exact
            component={Auth(BoothDetailsPage)}
          />
          <Route
            path="/myboothdetails/:code"
            exact
            component={Auth(MyBoothDetailsPage)}
          />
          <Route
            path="/karyakarta/:code/:abhiyaan_id"
            exact
            component={Auth(KaryakartaPage)}
          />

          <Route
            path="/karykartacreate"
            exact
            component={Auth(KarykartaCreate)}
          />

          <Route
            path="/boothdetails/:id"
            exact
            component={Auth(SectorDetails)}
          />

          <Route path="/sectorlist" exact component={Auth(SectorPage)} />

          <Route
            path="/sectorlist/:mandal_code"
            exact
            component={Auth(SectorPage)}
          />
          <Route path="/mandallist" exact component={Auth(MandalComponent)} />

          {/* voter details listing route */}

          <Route
            path="/voterdetail/personal/:epic_id"
            exact
            component={Auth(Personal)}
          />
          <Route
            path="/voterdetail/personal/list/:epic_id"
            exact
            component={Auth(PersonalList)}
          />
          <Route
            path="/voterdetail/communication/list/:epic_id"
            exact
            component={Auth(CommunicationList)}
          />
          <Route
            path="/voterdetail/education/list/:epic_id"
            exact
            component={Auth(EducationList)}
          />
          <Route
            path="/voterdetail/political/list/:epic_id"
            exact
            component={Auth(PoliticalList)}
          />
          {/* Edit voter details route */}
          {/* <Route
            path="/voterdetail/personal/:epic_id"
            exact
            component={Auth(Personal)}
          /> */}
          <Route
            path="/voterdetail/communication/:epic_id"
            exact
            component={Auth(Communication)}
          />
          <Route
            path="/voterdetail/education/:epic_id"
            exact
            component={Auth(Education)}
          />
          <Route
            path="/voterdetail/political/:epic_id"
            exact
            component={Auth(Political)}
          />

          <Route path="/electoral-list" exact component={Auth(ElectoralList)} />

          <Route path="/karyakarta" exact component={Karyakarta} />
          <Route path="/karyakartapopup" exact component={KaryakartaPopup} />

          {/* <Route
            path="/voterDetailListing"
            exact
            component={VoterDetailListing}
          /> */}
          <Route
            path="/personaldetailslist"
            exact
            component={PersonalDetailsList}
          />
          <Route
            path="/communicationdetailslist"
            exact
            component={CommunicationDetailsList}
          />
          <Route
            path="/educationdetailslist"
            exact
            component={EducationalDetailsList}
          />
          <Route
            path="/politicaldetailslist"
            exact
            component={PoliticalDetailsList}
          />
          <Route path="/loksabha" exact component={Loksabha} />

          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </div>
  );
};

export default Routes;
