import React from "react";
import Layout from "../../hoc/Layout";
import HomeComponent from "../../components/home/HomeComponent";


function Homepage() {
  return (
    <Layout>
      <HomeComponent />
    </Layout>
  );
}

export default Homepage;
