import React, { useEffect, memo } from "react";
import { connect } from "react-redux";
import { getQuestion } from "../../../actions/question";
import LoaderComponent from "../../loader/LoaderComponent";
import OptionsList from "./OptionsList";
import { appLanguageData } from "../../../utils/language";

function Questions(props) {
  useEffect(() => {
    props.getQuestion();
  }, []);

  return (
    <div className="voter_question">
      {props.questions.loading ? <LoaderComponent /> : null}

      <h5>Question</h5>

      {props.questions.data &&
        props.questions.data.length > 0 &&
        props.questions.data.map((question, i) => {
          return (
            <div key={i}>
              <h6>{appLanguageData(question.json_language_data, "title")}</h6>
              <OptionsList
                questionId={question["_id"]}
                questopnOption={props.questopnOption}
                formUpdate={props.formUpdate}
                optionDataNew={props.optionDataNew}
              />
            </div>
          );
        })}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    questions: state.questioins,
  };
};

export default memo(
  connect(mapStateToProps, { getQuestion: () => getQuestion() })(Questions)
);
