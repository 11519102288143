import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import VoterHeaderComponent from "./voterheader";
import { cleanVoterState } from "../../actions/voter";
import "./VoterList.css";

const menuArray = {
  my_booth_details: {
    label: "My Booth Details",
    link: "/myboothdetails",
    img: "/images/booth.svg",
    role: ["5fc9c59bf76d8384c9fb7e09"],
  },
  route_karykartalist: {
    label: "Karykarta List",
    link: "/karyakartalist",
    img: "/images/karyakarta.svg",
    role: [
      "5fc9c59bf76d8384c9fb7e09",
      // "5fd1b9c1392edde23d731be9",
      "5fc9c4e8f76d8384c9fb7e08",
    ],
  },
  my_panna: {
    label: "My Panna",
    link: "/voterlistdata",
    img: "/images/panna.svg",
    role: [
      "5fc9c59bf76d8384c9fb7e09",
      "5fd1b9c1392edde23d731be9",
      "5fc8b238fbceb404352cc352",
    ],
  },
  electrol_list: {
    label: "Electrol List",
    link: "/electoral-list",
    img: "/images/panna.svg",
    role: ["5fc9c59bf76d8384c9fb7e09", "5fd1b9c1392edde23d731be9"],
  },

  route_map: {
    label: "Route Map",
    link: "/routemap",
    img: "/images/map.svg",
    role: ["5fc9c59bf76d8384c9fb7e09", "5fd1b9c1392edde23d731be9"],
  },

  find_voter: {
    label: "Find Voter",
    link: "/find-voters",
    img: "/images/voterlist.svg",
    role: ["5fc9c59bf76d8384c9fb7e09", "5fd1b9c1392edde23d731be9"],
  },

  karykarta_create_list: {
    label: "Add Karykarta",
    link: "/karykartacreate",
    img: "/images/karyakarta.svg",
    role: [
      "5fc9c59bf76d8384c9fb7e09",
      // "5fd1b9c1392edde23d731be9",
      "5fc9c4e8f76d8384c9fb7e08",
    ],
  },

  // find_voter: {
  //   label: "Find Voter",
  //   link: "/sangathan",
  //   img: "/images/voterlist.svg",
  //   role: ["5fc9c59bf76d8384c9fb7e09", "5fd1b9c1392edde23d731be9"],
  // },

  // voter_list: {
  //   label: "Voter List",
  //   link: "/sangathan",
  //   img: "/images/voterlist.svg",
  //   role: ["5fc9c59bf76d8384c9fb7e09", "5fd1b9c1392edde23d731be9"],
  // },

  // voter_details: {
  //   label: "Voter Details",
  //   link: "/voterdetail/personal",
  //   img: "/images/vote.svg",
  //   role: ["5fc9c59bf76d8384c9fb7e09", "5fd1b9c1392edde23d731be9"],
  // },

};

const VoterList = ({ VoterListing, cleanVoterState }) => {
  const [userData, setUserData] = useState({
    name: "",
    designition: "",
    polling_station_name: "",
    no_of_karyakarta: "",
  });
  useEffect(() => {
    cleanVoterState();
    try {
      let user_data = JSON.parse(localStorage.getItem("user_data"));
      setUserData({
        name: user_data.name,
        designition: user_data.designition,
        polling_station_name:
          user_data.booth_detail.name && user_data.booth_detail.name,
        no_of_karyakarta: user_data.no_of_karyakarta,
      });
    } catch (error) { }
  }, []);

  const RenderMenu = () => {
    let userRole = localStorage.getItem("user_role")
      ? localStorage.getItem("user_role")
      : "";
    let user_data = localStorage.getItem("user_data");
    let parse_booth_code = JSON.parse(user_data);
    let booth_code = parse_booth_code ? parse_booth_code["booth_code"] : null;
    if (!localStorage.getItem("user_role")) {
      localStorage.setItem("user_role", userRole);
    }
    if (Object.entries(menuArray).length > 0) {
      return Object.values(menuArray).map((el, key) => {
        return el.role.includes(userRole) ? (
          <Link
            to={
              el.label === "My Booth Details" || el.label === "Karykarta List"
                ? `${el.link}/${booth_code}`
                : el.link
            }
            key={key}
          >
            <ul>
              <li>
                <img src={el.img} alt="" />
                <h5>{el.label}</h5>
              </li>

              {/* <li>
                <img src="/images/next.svg" alt="" />
              </li> */}
            </ul>
          </Link>
        ) : (
            ""
          );
      });
    } else {
      return "";
    }
  };

  return (
    <div>
      <div className="">
        <VoterHeaderComponent hideBackIcon={true} />

        <ul className="boothpre_detail">
          <li>
            <h5 className="boothpre_label">My Panna:</h5>
          </li>
          <li>
            <h5>
              {userData.name &&
                userData.name.charAt(0).toUpperCase() + userData.name.slice(1)}
            </h5>
          </li>
          <li>
            <h5 className="boothpre_label">Designation :</h5>
          </li>
          <li>
            <h5>{userData.designition}</h5>
          </li>
          <li>
            <h5 className="boothpre_label">Polling Station Name :</h5>
          </li>
          <li>
            <h5>{userData.polling_station_name}</h5>
          </li>
          <li>
            <h5 className="boothpre_label">No of Karyakarta :</h5>
          </li>
          <li>
            <h5>{userData.no_of_karyakarta}</h5>
          </li>
        </ul>

        <div className="voter_container">
          <div className="voter_listing">
            {RenderMenu()}

            {/* <Link to="/voterlistdata" style={{ display: "none" }}>
              <ul>
                <li>
                  <img src="/images/vote.svg" alt="" />
                </li>
                <li>
                  <h5>My Panna</h5>
                </li>
                <li>
                  <img src="/images/next.svg" alt="" />
                </li>
              </ul>
            </Link>

            <Link to="/sangathan" style={{ display: "none" }}>
              <ul>
                <li>
                  <img src="/images/vote.svg" alt="" />
                </li>
                <li>
                  <h5>Voter List</h5>
                </li>
                <li>
                  <img src="/images/next.svg" alt="" />
                </li>
              </ul>
            </Link>

            <Link to="/routemap" style={{ display: "none" }}>
              <ul>
                <li>
                  <img src="/images/travel.svg" alt="" />
                </li>
                <li>
                  <h5>Route Map</h5>
                </li>
                <li>
                  <img src="/images/next.svg" alt="" />
                </li>
              </ul>
            </Link> */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { cleanVoterState: () => cleanVoterState() })(
  VoterList
);
