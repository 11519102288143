import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VoterHeaderComponent from "../voter/voterheader";
import { connect } from "react-redux";
import { getMandalList } from "../../actions/zila";
import LoaderComponent from "../loader/LoaderComponent";
import { __ucLetter } from "../../utils/language";

function MandalComponent(props) {
  const [mandalList, setMandalList] = useState([]);
  const [countList, setCountList] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMandal();
  }, []);

  function getMandal() {
    setLoading(true);

    let userInfo;
    try {
      userInfo = localStorage.getItem("user_data");
    } catch (error) { }

    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }

    getMandalList(userInfo && { district_code: userInfo['district_code'] })
      .then((res) => {
        if (res.resultFlag) {
          setLoading(false);
          setMandalList(res.response && res.response.existData);
          setCountList(res.response && res.response.sectorObj)
        } else {
          setLoading(false);
          alert("Some thing wrong")
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  function setMandalCode(mandal_code) {
    let obj = {};
    obj = {
      ...JSON.parse(localStorage.getItem("user_data")),
    };
    obj['mandal_code'] = mandal_code;
    localStorage.setItem("user_data", JSON.stringify(obj));
  }
  // console.log("countList ", countList);
  return (
    <div>
      <VoterHeaderComponent hideBackIcon={true} />

      <LoaderComponent displayType={loading} />
      <div className="voter_container">
        <div className="sevabooth_container">
          {mandalList && mandalList.length > 0 && mandalList.map((mandalData) => (
            <React.Fragment key={mandalData._id}>
              {/* <Link to={`/boothdetails/${mandalData._id}`}> */}
              <ul className="sevabooth_list">
                <li>
                  <h5>Name</h5>
                  <h5>Mandal Code</h5>
                  <h5>Number of sector:</h5>
                </li>
                <li className="booth_number">
                  <h5>{__ucLetter(mandalData.name)}</h5>
                  <h5>{mandalData.code}</h5>
                  <div onClick={() => setMandalCode(mandalData.code)}>

                    {
                      countList && Object.keys(countList) && Object.keys(countList).length > 0 && countList[mandalData.code] && (
                        <h5>
                          <span style={{ paddingRight: "10px" }}>
                            {
                              countList[mandalData.code]
                            }
                          </span>

                          <Link to={`/sectorlist/${mandalData.code}`}>
                            View detail <img src="/images/chev_right.svg" alt="" />
                          </Link>
                        </h5>
                      )}



                    {/* <h5>
                      <Link to={`/sectorlist/${mandalData.code}`}>
                        View detail <img src="/images/chev_right.svg" alt="" />
                      </Link>
                    </h5> */}
                  </div>

                </li>
                {/* <li>
                        <img src="/images/next2.svg" alt="" />
                      </li> */}
              </ul>
              {/* </Link> */}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <VoterHeaderComponent hideBackIcon={true} />

      {/* show loder */}
      <LoaderComponent displayType={props.sector.loading} />

      <div className="voter_container">
        <div className="sevabooth_container">
          {/* <h4>Jansampark</h4> */}

          {/* show data */}
          {props.sector &&
            props.sector.data &&
            props.sector.data.getdata &&
            props.sector.data.getdata.length > 0
            ? props.sector.data.getdata.map((mandalData) => (
              <React.Fragment key={mandalData._id}>
                {/* <Link to={`/boothdetails/${mandalData._id}`}> */}
                <ul className="sevabooth_list">
                  <li>
                    <h5>Name</h5>
                    <h5>Sector Code</h5>
                    <h5>Number of Booth:</h5>
                  </li>
                  <li className="booth_number">
                    <h5>{mandalData.name}</h5>
                    <h5>{mandalData.code}</h5>

                    {props.sector.data.boothObj[mandalData.code] && (
                      <h5>
                        {props.sector.data.boothObj &&
                          Object.keys(props.sector.data.boothObj).length >
                          0 && (
                            <span style={{ paddingRight: "10px" }}>
                              {
                                props.sector.data.boothObj[mandalData.code]
                                  .count
                              }
                            </span>
                          )}

                        {/* <Link to={`/boothlistdetail/${boothCode}`}> */}
                        {/* <Link to={`/karyakartalist/${boothCode}`}> */}
                        <Link to={`/boothlists/${mandalData.code}`}>
                          View detail{" "}
                          <img src="images/chev_right.svg" alt="" />
                        </Link>
                      </h5>
                    )}
                  </li>
                  {/* <li>
                        <img src="/images/next2.svg" alt="" />
                      </li> */}
                </ul>
                {/* </Link> */}
              </React.Fragment>
            ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default MandalComponent;
// const mapStateToProps = (state) => {
//   return {
//     sector: state.sector,
//   };
// };

// export default connect(mapStateToProps, {
//   getSectorList: (userParams) => getSectorList(userParams),
//   clearSectorList: () => clearSectorList(),
// })(SectorListComponent);
