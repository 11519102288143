import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { afterLoginRoute, routeCheckForUser } from "../utils/helper";

export default function (ComposeComponent) {
  const AuthenticationCheck = () => {
    const history = useHistory();

    useEffect(() => {
      // check extra auth on every render page
      document.getElementById("full_loader").style.display = "block";

      const checkAuth = async () => {
        // console.log("pathname ", history.location.pathname);
        let checkuser = localStorage.getItem("auth_key");
        document.getElementById("full_loader").style.display = "none";
        if (!checkuser) {
          let route = history.location.pathname === "/" ? "/" : "/login";
          history.push(route);
        } else {
          let userData = localStorage.getItem("user_data");
          userData = typeof userData === "string" ? JSON.parse(userData) : userData;
          if (
            history.location.pathname === "/login" ||
            history.location.pathname === "/"
          ) {
            let route = "/menulist";
            if(userData && userData.designition) {
              route = afterLoginRoute(userData.designition)
            }
            history.push(route);
          } else {
            if(userData && userData.designition) {
              let isRoute = routeCheckForUser(history.location.pathname, userData.designition);
              if(!isRoute) {
                history.goBack();
              }
            }
          }
        }
      };
      checkAuth();
    }, []);
    return <ComposeComponent />;
  };

  return AuthenticationCheck;
}
