import React from "react";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <div>
      <div className="no_data">
        <img src="images/data.svg" style={{width : '40px'}} alt="" />
        <h4>No Data Found page 
      <Link to="/"> Go Home</Link></h4>
      </div>
    </div>
  );
}

export default NotFoundPage;
