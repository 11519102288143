const getLabelFromLangArr = (langArr, key) => {
  let lang = localStorage.getItem("lang");
  if (!lang) {
    localStorage.setItem("lang", "en");
    lang = "en";
  }
  let langObj = langArr[lang];
  let label = langObj && langObj[key] ? langObj[key] : "";
  return label;
};
export default getLabelFromLangArr;

let roleRouteObj = {
  "zila president": { defaultRoute: "mandallist" },
  "mandal president": { defaultRoute: "sectorlist" },
  "sector president": { defaultRoute: "boothlist" },
  "booth president": { defaultRoute: "menulist" },
  karyakarta: { defaultRoute: "menulist" },
};
export const afterLoginRoute = (value) => {
  value = value ? value.toLowerCase() : "";
  let route = roleRouteObj[value]
    ? roleRouteObj[value].defaultRoute
    : "/menulist";
  return route;
};

let routeListRoleArr = [
  // vouter listing rout
  {
    route: "/electoral-list",
    role: ["booth president", "karyakarta"],
  },

  {
    route: "/voterdetail/personal/list",
    role: ["booth president", "karyakarta"],
  },
  {
    route: "/voterdetail/communication/list",
    role: ["booth president", "karyakarta"],
  },
  {
    route: "/voterdetail/education/list",
    role: ["booth president", "karyakarta"],
  },
  {
    route: "/voterdetail/political/list",
    role: ["booth president", "karyakarta"],
  },
  // voter details rout
  { route: "/voterdetail/personal", role: ["booth president", "karyakarta"] },
  {
    route: "/voterdetail/communication",
    role: ["booth president", "karyakarta"],
  },
  { route: "/voterdetail/education", role: ["booth president", "karyakarta"] },
  { route: "/voterdetail/political", role: ["booth president", "karyakarta"] },

  { route: "/sangathan", role: ["booth president", "karyakarta"] },
  { route: "/voter/", role: ["booth president", "karyakarta"] },
  { route: "/survey-list/", role: ["booth president", "karyakarta"] },
  // { route: "/survey/", role: ["booth president", "karyakarta"] },
  { route: "/menulist", role: ["booth president", "karyakarta"] },
  { route: "/voterlistdata", role: ["booth president", "karyakarta"] },
  { route: "/routemap", role: ["booth president", "karyakarta"] },
  { route: "/myboothdetails", role: ["booth president", "karyakarta"] },
  { route: "/assign-voters", role: ["booth president"] },
  { route: "/un-assign-voters", role: ["booth president"] },
  { route: "/karykartacreate", role: ["booth president", "karyakarta"] },
  { route: "/find-voters", role: ["booth president", "karyakarta"] },
  {
    route: "/karyakartalist",
    role: [
      "zila president",
      "booth president",
      "karyakarta",
      "sector president",
      "mandal president",
    ],
  },

  {
    route: "/karyakartainfo",
    role: [
      "zila president",
      "booth president",
      "karyakarta",
      "sector president",
      "mandal president",
    ],
  },

  {
    route: "/assigned-voters-list",
    role: [
      "zila president",
      "booth president",
      "karyakarta",
      "sector president",
      "mandal president",
    ],
  },

  {
    route: "/boothlist",
    role: [
      "zila president",
      "sector president",
      "mandal president",
      "booth president",
    ],
  },
  {
    route: "/boothlists",
    role: [
      "zila president",
      "sector president",
      "mandal president",
      "booth president",
    ],
  },

  {
    route: "/boothlistdetail/",
    role: [
      "zila president",
      "sector president",
      "mandal president",
      "booth president",
    ],
  },
  {
    route: "/karyakarta/",
    role: [
      "sector president",
      "booth president",
      "mandal president",
      "zila president",
    ],
  },

  {
    route: "/boothdetails",
    role: [
      "sector president",
      "mandal president",
      "zila president",
      "booth president",
    ],
  },
  {
    route: "/sectorlist",
    role: [
      "mandal president",
      "zila president",
      "sector president",
      "booth president",
    ],
  },
  {
    route: "/mandallist",
    role: [
      "zila president",
      "sector president",
      "mandal president",
      "booth president",
    ],
  },
];

export const routeCheckForUser = (pathname, role) => {
  role = role.toLowerCase();
  let filteredData = routeListRoleArr.filter(
    (el) => pathname.indexOf(el.route) !== -1
  );
  if (filteredData.length > 0) {
    filteredData = filteredData[0];
    // console.log("isRoute", filteredData.role.includes(role));
    if (filteredData.role.includes(role)) {
      return true;
    }
  }
  return false;
};
