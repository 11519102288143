import { axiosPost, axiosGet } from "../utils/commonAxios";

import {
  QUESTION_PENDING,
  QUESTION_SUCCESS,
  QUESTION_ERROR,
} from "./types";

export const getQuestion = () => {
  return async (diapatch, getState) => {
    // dispatch another pending
    diapatch({
      type: QUESTION_PENDING,
    });

    // let bodyObj = {};

    // let checkuser = {};
    // try {
    //   checkuser["token"] = localStorage.getItem("auth_key");
    // } catch (error) {}

    // let reqobject = {
    //   action: "",
    //   ...bodyObj,
    // };

    try {
      const response = await axiosGet("/questions/list");
      // dispatch another success

      diapatch({
        type: QUESTION_SUCCESS,
        payload: response.data.response,
      });
    } catch (error) {
      // dispatch another error
      diapatch({
        type: QUESTION_ERROR,
        payload: "Some thing wrong",
      });
    }
  };
};

export const getQuestionOptions = async (questionId) => {

  let bodyObj = {};

    bodyObj["question_id"] = questionId;

    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) {}

    let reqobject = {
      action: "/options/list",
      ...bodyObj,
    };

    try {
      const response = await axiosPost(reqobject, checkuser);
      // dispatch another success
      return response.data.response
    } catch (error) {
      // dispatch another error
      return error
    }

  
  // return async (diapatch, getState) => {
  //   // dispatch another pending
  //   diapatch({
  //     type: QUESTION_OPTIONS_PENDING,
  //   });

  //   let bodyObj = {};

  //   bodyObj["question_id"] = questionId;

  //   let checkuser = {};
  //   try {
  //     checkuser["token"] = localStorage.getItem("auth_key");
  //   } catch (error) {}

  //   let reqobject = {
  //     action: "/options/list",
  //     ...bodyObj,
  //   };

  //   try {
  //     const response = await axiosPost(reqobject, checkuser);
  //     // dispatch another success

  //     diapatch({
  //       type: QUESTION_OPTIONS_SUCCESS,
  //       payload: response.data.response,
  //     });
  //   } catch (error) {
  //     // dispatch another error
  //     diapatch({
  //       type: QUESTION_OPTIONS_ERROR,
  //       payload: "Some thing wrong",
  //     });
  //   }
  // };
};
