import { connect } from "react-redux";
import { CommunicationDetails } from "./CommunicationDetails";

const mapStateToProps = (state) => {
  // console.log(state);
  return {}
 }

const mapDispatchToProps = () => ({});

export const CommunicationDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationDetails);