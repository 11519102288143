import React, { useState, useEffect } from "react";
import { VoterHeaderMenu } from "../VoterHeaderMenu";
import { axiosPost } from "../../../utils/commonAxios";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { AlertBox } from "../../../components/common/AlertBox"

function CommunicationDetails() {
  const { epic_id } = useParams();
  const [communicationDetails, setCommunicationDetails] = useState({
    add_curr: "",
    currentCity: "",
    pincode: "",
    email: "",
    landline: "",
  });

  const [stdCode, setStdCode] = useState("");

  const [mobNumber, setMobNumber] = useState("");
  const [mobileNumberArr, setMobileNumberArr] = useState([mobNumber]);

  const [landLineNumber, setLandLineNumber] = useState("");
  const [landNumberArr, setLandNumberArr] = useState([landLineNumber]);

  useEffect(() => {
    setFormField();
  }, []);

  const addMoreMoreMobile = () => {
    let newArr = mobileNumberArr;
    newArr.push(mobNumber);
    setCommunicationDetails((prevState) => {
      return {
        ...prevState,
      };
    });
  };

  const updateMobile = (element, position) => {
    let tempArr = mobileNumberArr.slice();

    let tempObj = tempArr[position];
    let formElement = {
      ...tempObj,
      [element.target.name]: element.target.value,
    };

    tempArr[position] = formElement;
    setMobileNumberArr([...tempArr]);
  };

  // const addMoreMoreLand = () => {
  //   let newArr = landNumberArr
  //   newArr.push(landLineNumber)
  //   setCommunicationDetails((prevState) => {
  //     return {
  //       ...prevState,
  //     }
  //   })
  // }

  // const updateLandLine = (element, position) => {
  //   let tempArr = landNumberArr.slice()

  //   let tempObj = tempArr[position]
  //   let formElement = { ...tempObj, [element.target.name]: element.target.value }

  //   tempArr[position] = formElement
  //   setLandNumberArr([...tempArr]);
  // }

  const updateForm = (e) => {
    const { name, value } = e.target;
    setCommunicationDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const updateStdCode = (e) => {
    const { name, value } = e.target;
    setStdCode(value);
  };

  const removeMobArr = (position) => {
    let newArr = mobileNumberArr;
    newArr.splice(position, 1);
    setCommunicationDetails((prevState) => {
      return {
        ...prevState,
      };
    });
  };

  const removeLanArr = (position) => {
    let newArr = landNumberArr;
    newArr.splice(position, 1);
    setCommunicationDetails((prevState) => {
      return {
        ...prevState,
      };
    });
  };

  const setFormField = async () => {
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) { }

    let reqobject = {
      action: "/voter/search",
      epic_no: epic_id,
    };

    try {
      const resp = await axiosPost(reqobject, checkuser);
      if (resp.data.resultFlag) {
        const { communication } = resp.data.response;

        let updatedData = {};

        // Mobile number
        if (communication["mobile"]) {
          // let newArr = mobileNumberArr;
          // for (let i = 1; i < communication["mobile"].length; i++) {
          //   newArr.push(mobNumber);
          // }
          let mobileState = mobileNumberArr.slice();
          for (let i = 0; i < communication["mobile"].length; i++) {
            let formElement = {
              mobilenumber: communication["mobile"][i],
            };
            mobileState[i] = formElement;
          }
          setMobileNumberArr([...mobileState]);
        }
        updatedData["landline"] = communication["landline"];

        if (communication["add_curr"]) {
          updatedData["add_curr"] = communication["add_curr"]["full_add"];
          updatedData["currentCity"] = communication["add_curr"]["dist_code"];
          updatedData["pincode"] = communication["add_curr"]["pincode"];
        }
        updatedData["email"] = communication["email"];
        updatedData["fb_id"] = communication["fb_id"];
        updatedData["insta_id"] = communication["insta_id"];
        updatedData["twitter_id"] = communication["twitter_id"];

        setCommunicationDetails((prevState) => ({
          ...prevState,
          ...updatedData,
        }));
        setStdCode(communication["std_code"]);
      } else {
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();

    let communication = {};
    // communication = communicationDetails;
    let add_curr = {
      state_code: "",
      dist_code: communicationDetails["currentCity"],
      full_add: communicationDetails["add_curr"],
      full_add_oth: communicationDetails["add_curr"],
      pincode: communicationDetails["pincode"],
    };

    communication["add_curr"] = add_curr;
    communication["email"] = communicationDetails["email"];
    communication["fb_id"] = communicationDetails["fb_id"];
    communication["twitter_id"] = communicationDetails["twitter_id"];
    communication["insta_id"] = communicationDetails["insta_id"];

    let tempMobArr = [];

    mobileNumberArr.forEach((mob) => {
      tempMobArr.push(mob.mobilenumber);
    });

    communication["mobile"] = tempMobArr;
    communication["landline"] = communicationDetails["landline"];
    communication["std_code"] = stdCode;

    // send in api
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) { }

    let reqobject = {
      action: "/voter/save_voter_data",
      data: {
        epic_no: epic_id,
        communication,
      },
    };

    try {
      const resp = await axiosPost(reqobject, checkuser);
      if (resp.data.resultFlag) {
        AlertBox("Success message", resp.data.message);
      } else {
        AlertBox("Error message", resp.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <VoterHeaderMenu>
        <div className="voter_container">
          <div className="voter_question2 plus_position">
            <h6>Mobile No*</h6>
            <div style={{ position: "relative" }}>
              {mobileNumberArr.map((mobile, i) => {
                return (
                  <div key={i} style={{ position: "relative" }}>
                    <div>
                      <input
                        type="number"
                        className="picture_input"
                        name="mobilenumber"
                        defaultValue={mobile.mobilenumber}
                        placeholder="Mobile No"
                        onChange={(element) => updateMobile(element, i)}
                      />
                    </div>
                    {i + 1 >= 2 ? (
                      <button
                        className="minus_btn"
                        onClick={() => removeMobArr(i)}
                      >
                        <img src="/images/minus.svg" alt="" />
                      </button>
                    ) : null}
                  </div>
                );
              })}
              <img
                src="/images/pluscircle.svg"
                className="plus_circle"
                alt=""
                onClick={addMoreMoreMobile}
              />
            </div>
          </div>

          <div className="voter_question2 plus_position">
            <h6>Landline number</h6>

            <div style={{ position: "relative" }}>
              <div>
                <input
                  type="number"
                  className="picture_input"
                  name="landline"
                  value={communicationDetails.landline}
                  placeholder="Land Line No"
                  onChange={updateForm}
                />
              </div>
              {/* <input
                type="number"
                className="land_codet"
                name="std"
                value={stdCode}
                placeholder="Std code"
                onChange={updateStdCode}
              />

              <span className="select_border">|</span> */}
            </div>
          </div>
          {/* New changes code will add later */}
          {/* <div className="voter_question2">
              <h6>State*</h6>
              <Select
                isLoading={isLoading.stateLoading}
                value={selectedOption.SelectedState}
                onChange={(e, type) => handleChange(e, "SelectedState")}
                className="basic-single"
                classNamePrefix="select"
                options={inputFormData_array.nativeState}
              />
            </div>

            <div className="voter_question2">
              <h6>District*</h6>
              <Select
                isLoading={isLoading.districtLoading}
                value={selectedOption.SelectedDistrict}
                onChange={(e, type) => handleChange(e, "SelectedDistrict")}
                className="basic-single"
                classNamePrefix="select"
                options={inputFormData_array.nativeDistrict}
              />
            </div> */}
          <div className="voter_question2">
            <h6>Current Address*</h6>
            <input
              type="text"
              placeholder="Current Address"
              name="add_curr"
              value={communicationDetails.add_curr}
              onChange={updateForm}
            />
          </div>

          <div className="voter_question2">
            <h6>Current City*</h6>
            <div className="select_margin">
              {/* <Select
                isLoading={isLoading.regilionsLoading}
                value={selectedOption.SelectedRegilion}
                onChange={(e, type) => handleChange(e, "SelectedRegilion")}
                className="basic-single"
                classNamePrefix="select"
                options={inputFormData_array.religion}
              /> */}

              <select>
                <option>Swatch Bharat Abhiyan</option>
                <option>Swatch Bharat Abhiyan</option>
                <option>Swatch Bharat Abhiyan</option>
              </select>
            </div>
          </div>

          <div className="voter_question2">
            <h6>Pincode*</h6>
            <input
              type="number"
              placeholder="Pincode*"
              name="pincode"
              value={communicationDetails.pincode}
              onChange={updateForm}
            />
          </div>

          <div className="voter_question2">
            <h6>Email</h6>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={communicationDetails.email}
              onChange={updateForm}
            />
          </div>

          {/* <div className="voter_question2">
            <h6>Facebook</h6>
            <input
              type="text"
              placeholder="Facebook"
              name="fb_id"
              value={communicationDetails.fb_id}
              onChange={updateForm}
            />
          </div>
          <div className="voter_question2">
            <h6>Twitter</h6>
            <input
              type="text"
              placeholder="Twitter"
              name="twitter_id"
              value={communicationDetails.twitter_id}
              onChange={updateForm}
            />
          </div>
          <div className="voter_question2">
            <h6>Instagram</h6>
            <input
              type="text"
              placeholder="Instagram"
              name="insta_id"
              value={communicationDetails.insta_id}
              onChange={updateForm}
            />
          </div> */}

          <div className="child_btn">
            <button onClick={submitForm}>UPDATE</button>
          </div>
        </div>
      </VoterHeaderMenu>
    </div>
  );
}

export { CommunicationDetails };

{
  /* {landNumberArr.map((landnum, i) => (
              <div key={i}>
                <div style={{ position: 'relative' }}>
                  <div>
                    <input type="number" className="picture_input land_input" name="landNumber" value={landnum.landNumber} placeholder="Land Line No" onChange={(element) => updateLandLine(element, i)} />
                    {
                      (i + 1 >= 2) ? (
                        <button onClick={() => removeLanArr(i)}>delete</button>
                      ) : null
                    }
                  </div>
                  <select className="land_code">
                    <option>011</option>
                    <option>0121</option>
                  </select>
                  <span className="select_border">|</span>
                </div>
              </div>
            ))} */
}

{
  /* <img src="/images/pluscircle.svg" alt="" onClick={addMoreMoreLand} /> */
}
