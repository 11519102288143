import {
  SECTOR_LIST_PENDING,
  SECTOR_LIST_SUCCESS,
  SECTOR_LIST_ERROR,
  SECTOR_LIST_EMPTY,
} from "./types";

import { axiosGet } from "../utils/commonAxios";

export const getSectorList = (params) => {
  return async (dispatch) => {
    dispatch({
      type: SECTOR_LIST_PENDING,
    });

    try {
      let response = await axiosGet("/sector/mandal-sector", params);
      if (response.data.response) {
        dispatch({
          type: SECTOR_LIST_SUCCESS,
          payload: response.data.response,
        });
      }
    } catch (error) {
      dispatch({
        type: SECTOR_LIST_ERROR,
        payload: "Some thing wrong",
      });
    }
  };
};

export const clearSectorList = () => {
  return (dispatch) => {
    dispatch({
      type: SECTOR_LIST_EMPTY,
    });
  };
};
