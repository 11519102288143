import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function HomeComponent(props) {
  useEffect(() => {}, []);

  return (
    <div>
      <div className="bjp_splash">
        <div className="bjpsplash_postion">
          <Link to="/login">
            <img src="/images/bjplogo.svg" alt="" />
          </Link>
          <h4>Bhartiya Janta Party</h4>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  // console.log(state.todo.data)
  return {};
};

export default connect(mapStateToProps, {})(HomeComponent);
