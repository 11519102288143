import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { VoterHeaderMenu } from "../VoterHeaderMenu";
import { axiosPost } from "../../../utils/commonAxios";
import { __ucLetter } from "../../../utils/language";
import LoaderComponent from "../../loader/LoaderComponent";

import "./PersonalDetailsList.css";

function PersonalDetailsList() {
  const { epic_id } = useParams();
  const [personalDateils, setPersonalDetails] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchVoterDetailsData();
  }, []);

  const fetchVoterDetailsData = async () => {
    setLoading(true);
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) {}

    let reqobject = {
      action: "/voter/search",
      epic_no: epic_id,
    };

    try {
      const resp = await axiosPost(reqobject, checkuser);
      // if (resp.data.response === null) {
      //   history.push(`/voterdetail/personal/${epic_id}`);
      // }

      const { personal } = resp.data.response;

      var setData = {};
      setData["aadhar_no"] = personal.aadhar_no;
      setData["age"] = personal.age;
      setData["booth_code"] = personal.booth_code;
      setData["cast"] = personal.cast;
      setData["category"] = personal.category;
      setData["country"] = personal.country;
      setData["dob"] = personal.dob;
      setData["epic_no"] = personal.epic_no;
      setData["father_epic_no"] = personal.father_epic_no;
      setData["father_name"] = personal.father_name;
      setData["gender"] = personal.gender;
      setData["house_no"] = personal.house_no;
      setData["is_nri_voter"] = personal.is_nri_voter;
      setData["location"] = personal.location;
      setData["mother_epic_no"] = personal.mother_epic_no;
      setData["mother_name"] = personal.mother_name;
      setData["mother_tongue"] = personal.mother_tongue;

      setData["name_en"] = personal.name_en;
      setData["name_oth"] = personal.name_oth;
      // setData["native_pincode"] = personal.native_pincode;
      setData["occupation"] = personal.occupation;
      setData["part_no"] = personal.part_no;

      setData["passport_no"] = personal.passport_no;
      setData["religion"] = personal.religion;
      setData["rln_name_en"] = personal.rln_name_en;
      setData["rln_name_oth"] = personal.rln_name_oth;
      setData["rln_type"] = personal.rln_type;
      setData["section_no"] = personal.section_no;
      setData["serial_no"] = personal.serial_no;
      setData["spouse_epic_no"] = personal.spouse_epic_no;
      setData["spouse_name"] = personal.spouse_name;
      setData["sub_cast"] = personal.sub_cast;
      setData["vote_at"] = personal.vote_at;
      setData["vs_ac_no"] = personal.vs_ac_no;
      setData["marital_status"] = personal.marital_status;
      setData['image'] = personal.image;
      try {
        if (personal.language_known) {
          let languages = [];
          personal.language_known.map((lg) => {
            languages.push(lg.value);
          });
          setData["language_known"] = languages.join();
        }
      } catch (error) {
        console.log(error);
      }

      if (personal.add_curr) {
        setData["dist_code"] = personal.add_curr.dist_code;
        setData["full_add"] = personal.add_curr.full_add;
        setData["full_add_oth"] = personal.add_curr.full_add_oth;
        setData["pincode"] = personal.add_curr.pincode;
        setData["state_code"] = personal.add_curr.state_code;
      }

      if (personal.children) {
        setData["children"] = personal.children;
      }

      setPersonalDetails((prevState) => ({
        ...prevState,
        ...setData,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  console.log(personalDateils)
  return (
    <div>
      {/* show loader */}
      <LoaderComponent displayType={loading} />
      <VoterHeaderMenu>
        {/* <div className="approve_btn">
          <button type="button">approve</button>
        </div> */}
        <div className="voter_container">
          <div className="voter_question2">
          
            <img
              src={personalDateils.image ? personalDateils.image : "/images/userdefault.svg"}
              className="user_profile"
              alt=""
            />
            <h5>
              {personalDateils.name_en && __ucLetter(personalDateils.name_en)}{" "}
              <a href={`/voterdetail/personal/${epic_id}`}>
                <img src="/images/edit.svg" alt="" />
              </a>
            </h5>
          </div>
          <div className="personaldetail_list">
            <ul>
              <li>
                <h5>Epic No. :</h5>
              </li>
              <li>
                <h5 className="detail_color">{epic_id.split("-").join("/")} </h5>
              </li>

              <li>
                <h5>Gender :</h5>
              </li>
              <li>
                <h5 className="detail_color">
                  {personalDateils.gender === "F"
                    ? "Female"
                    : personalDateils.gender === "M"
                    ? "Male"
                    : "Other"}
                </h5>
              </li>

              <li>
                <h5>Religion :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.religion}</h5>
              </li>

              <li>
                <h5>Category :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.category}</h5>
              </li>

              <li>
                <h5>Caste :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.cast}</h5>
              </li>

              <li>
                <h5>Sub-Caste :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.sub_cast}</h5>
              </li>

              <li>
                <h5>Languages Known :</h5>
              </li>
              <li>
                <h5 className="detail_color">
                  {personalDateils.language_known}
                </h5>
              </li>

              <li>
                <h5>Mother Tongue :</h5>
              </li>
              <li>
                <h5 className="detail_color">
                  {personalDateils.mother_tongue}
                </h5>
              </li>

              <li>
                <h5>Date of Birth :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.dob}</h5>
              </li>

              <li>
                <h5>Native Pincode :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.pincode} </h5>
              </li>

              <li>
                <h5>Native State :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.state_code} </h5>
              </li>

              <li>
                <h5>Native District :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.dist_code} </h5>
              </li>

              <li>
                <h5>Native Address :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.full_add} </h5>
              </li>
              {personalDateils.father_name && (
                <React.Fragment>
                  <li>
                    <h5>Father's Name :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">
                      {__ucLetter(personalDateils.father_name)}
                    </h5>
                  </li>
                  <li>
                    <h5>Father Epic No. :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">
                      {personalDateils.father_epic_no}
                    </h5>
                  </li>
                </React.Fragment>
              )}

              <li>
                <h5>Occupation :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.occupation} </h5>
              </li>
              {personalDateils.mother_name && (
                <React.Fragment>
                  <li>
                    <h5>Mother's Name :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">
                      {__ucLetter(personalDateils.mother_name)}{" "}
                    </h5>
                  </li>

                  <li>
                    <h5>Mother Epic No. :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">
                      {personalDateils.mother_epic_no}
                    </h5>
                  </li>
                </React.Fragment>
              )}

              <li>
                <h5>Maritial Status :</h5>
              </li>
              <li>
                <h5 className="detail_color">
                  {personalDateils.marital_status}
                </h5>
              </li>

              <li>
                <h5>Aadhaar :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.aadhar_no}</h5>
              </li>

              <li>
                <h5>Voter at :</h5>
              </li>
              <li>
                <h5 className="detail_color">{personalDateils.vote_at}</h5>
              </li>
            </ul>
          </div>
          <hr></hr>
          <div className="personaldetail_list">
            <h3>Child Information</h3>

            {personalDateils.children &&
              personalDateils.children.map((child, i) => {
                return (
                  <React.Fragment key={i}>
                    <ul>
                      <li>
                        <h5>Name</h5>
                      </li>
                      <li>
                        <h5>{__ucLetter(child.name)}</h5>
                      </li>

                      <li>
                        <h5>Gender</h5>
                      </li>
                      <li>
                        <h5>
                          {child.gender === "F"
                            ? "Female"
                            : child.gender === "M"
                            ? "Male"
                            : "Other"}
                        </h5>
                      </li>

                      <li>
                        <h5>DoB</h5>
                      </li>
                      <li>
                        <h5>{child.age}</h5>
                      </li>

                      <li>
                        <h5>Aadhar No.</h5>
                      </li>
                      <li>
                        <h5>{child.child_aadhar}</h5>
                      </li>

                      <li>
                        <h5>Voter No.</h5>
                      </li>
                      <li>
                        <h5>{child.child_voter}</h5>
                      </li>

                      <li>
                        <h5>Occupation</h5>
                      </li>
                      <li>
                        <h5>{child.childrenOccupation}</h5>
                      </li>
                    </ul>
                    <hr />
                  </React.Fragment>
                );
              })}
          </div>
        </div>

        {/* <div className="personallist_popup">
          <div className="compare_data">
            <h4>Compare Data<span>&times;</span></h4>
            <ul>
              <li>
                <h6>20 Jan 2021</h6>
                <h5>Lorem Ipsum</h5>
              </li>
              <li>
                <h6>20 Jan 2021</h6>
                <h5>Lorem Ipsum</h5>
              </li>
              <li>
                <h6>20 Jan 2021</h6>
                <h5>Lorem Ipsum</h5>
              </li>
            </ul>

            <h4>Are You Sure <span>&times;</span></h4>
            <button type="button" className="ok_btn">ok</button>

          </div>
        </div> */}
      </VoterHeaderMenu>
    </div>
  );
}

export default PersonalDetailsList;
