import React from "react";
import LoginComponent from "../../components/login/LoginComponent2";

function LoginPage() {
  return (
    <div className="">
      <LoginComponent />
    </div>
  );
}

export default LoginPage;
