import React from "react";
import BoothDetailsComponent from "./BoothDetailsComponent";

function MyBoothDetailsComponent() {
  return (
    <div>
      <BoothDetailsComponent />
    </div>
  );
}

export default MyBoothDetailsComponent;
