import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VoterHeaderComponent from "../voter/voterheader";
import { connect } from "react-redux";
import { getSectorList, clearSectorList } from "../../actions/sector";
import LoaderComponent from "../loader/LoaderComponent";
import { __ucLetter } from "../../utils/language";

function SectorListComponent(props) {
  // const [boothCode, setBoothCode] = useState("");
  // let { mandal_code } = useParams();
  const [hideBackIcon, setHideBackIcon] = useState(true);


  useEffect(() => {
    getSectorlist();
    setHeaderBackButton();
    return () => {
      props.clearSectorList();
    };
  }, []);

  function getSectorlist() {
    let userInfo;

    try {
      userInfo = localStorage.getItem("user_data");
    } catch (error) { }

    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      // setBoothCode(userInfo.booth_code);
    }

    let userParams = {
      mandal_code: userInfo ? userInfo["mandal_code"] : "",
    };

    // make an api call
    props.getSectorList(userParams);
  }
  // console.log(props.sector);

  function setHeaderBackButton() {
    let userInfo;

    try {
      userInfo = localStorage.getItem("user_data");
    } catch (error) { }

    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }

    if (userInfo && userInfo["designition"].toLowerCase() === 'mandal president') {
      setHideBackIcon(true)
    } else {
      setHideBackIcon(false)
    }

  }

  return (
    <div>
      <VoterHeaderComponent hideBackIcon={hideBackIcon} />

      {/* show loder */}
      <LoaderComponent displayType={props.sector.loading} />

      <div className="voter_container">
        <div className="sevabooth_container">
          {/* <h4>Jansampark</h4> */}

          {/* show data */}
          {props.sector &&
            props.sector.data &&
            props.sector.data.getdata &&
            props.sector.data.getdata.length > 0
            ? props.sector.data.getdata.map((sectorData) => (
              <React.Fragment key={sectorData._id}>
                {/* <Link to={`/boothdetails/${sectorData._id}`}> */}
                <ul className="sevabooth_list">
                  <li>
                    <h5>Name</h5>
                    <h5>Sector Code</h5>
                    <h5>Number of Booth:</h5>
                  </li>
                  <li className="booth_number">
                    <h5>{__ucLetter(sectorData.name)}</h5>
                    <h5>{sectorData.code}</h5>

                    {props.sector.data.boothObj[sectorData.code] && (
                      <h5>
                        {props.sector.data.boothObj &&
                          Object.keys(props.sector.data.boothObj).length >
                          0 && (
                            <span style={{ paddingRight: "10px" }}>
                              {
                                props.sector.data.boothObj[sectorData.code]
                                  .count
                              }
                            </span>
                          )}

                        {/* <Link to={`/boothlistdetail/${boothCode}`}> */}
                        {/* <Link to={`/karyakartalist/${boothCode}`}> */}
                        <Link to={`/boothlists/${sectorData.code}`}>
                          View detail <img src="/images/chev_right.svg" alt="" />
                        </Link>
                      </h5>
                    )}
                  </li>
                  {/* <li>
                        <img src="/images/next2.svg" alt="" />
                      </li> */}
                </ul>
                {/* </Link> */}
              </React.Fragment>
            ))
            : null}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    sector: state.sector,
  };
};

export default connect(mapStateToProps, {
  getSectorList: (userParams) => getSectorList(userParams),
  clearSectorList: () => clearSectorList(),
})(SectorListComponent);
