import React, { useState, useEffect } from "react";
import VoterHeaderComponent from "../../voter/voterheader";
import './Loksabha.css';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';




function Loksabha() {


    return (
        <div>
            <div className="assignvoter_popupbg">
                <div className="assignvoter_popup">
                    <h4>Selected Voters</h4>
                    <div className="assignlist_scroll">
                        <ul>
                            <li>
                                <h6>1.</h6>
                            </li>
                            <li>
                                <h5>
                                    <img src="images/user.svg" alt="" /> Ramesh Kumar
                            </h5>
                                <h5>
                                    <img src="images/home2.svg" alt="" /> HJK/67, Gautam Nagar, Noida
                            </h5>
                            </li>
                            <li>
                                <img src="images/delete.svg" alt="" />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <h6>2.</h6>
                            </li>
                            <li>
                                <h5>
                                    <img src="images/user.svg" alt="" /> Ramesh Kumar
                            </h5>
                                <h5>
                                    <img src="images/home2.svg" alt="" /> HJK/67, Gautam Nagar, Noida
                            </h5>
                            </li>
                            <li>
                                <img src="images/delete.svg" alt="" />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <h6>3.</h6>
                            </li>
                            <li>
                                <h5>
                                    <img src="images/user.svg" alt="" /> Ramesh Kumar
                            </h5>
                                <h5>
                                    <img src="images/home2.svg" alt="" /> HJK/67, Gautam Nagar, Noida
                            </h5>
                            </li>
                            <li>
                                <img src="images/delete.svg" alt="" />
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <h6>4.</h6>
                            </li>
                            <li>
                                <h5>
                                    <img src="images/user.svg" alt="" /> Ramesh Kumar
                            </h5>
                                <h5>
                                    <img src="images/home2.svg" alt="" /> HJK/67, Gautam Nagar, Noida
                            </h5>
                            </li>
                            <li>
                                <img src="images/delete.svg" alt="" />
                            </li>
                        </ul>
                        
                       
                    </div>
                    <div className="otp_container">
                        <ul>
                            <li><a href="#" className="verify_btn">assign</a></li>
                            <li><a href="#" className="cancel_btn">cancel</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <VoterHeaderComponent />
            <div className="voter_container">
                {/* <div className="voter_search">
                    <input type="text" placeholder="Search" />
                    <img src="/images/search_black.svg" alt="" />
                </div> */}

                <div className="input_range_list">
                    <ul>
                        <li className="range_padding">
                            <input type="number" placeholder="50" />
                        </li>
                        <li>
                            <p>to</p>
                        </li>
                        <li>
                            <input type="number" placeholder="1200" />
                        </li>
                    </ul>
                    {/* <input className="" type="range" min="0" /> */}

                    {/* <Range handleStyle={[{}]}
                    min={0}
                    max={20}
                    defaultValue={[0, 20]}
                    trackStyle={[{ backgroundColor: 'rgba(109, 107, 106, 0.34)' }, { backgroundColor: 'rgba(109, 107, 106, 0.34)' }]}
                    handleStyle={[{ backgroundColor: '#6D6B6A' },  { backgroundColor: '#6D6B6A' }]}
                    railStyle={{ backgroundColor: 'rgba(109, 107, 106, 0.34)' }}
                    /> */}
                </div>
                <div className="assignvoter_btn">
                    <ul>
                        <li>
                            <button className="assign_btn2" type="button">Next</button>
                        </li>
                        {/* <li>
                            <button className="unassign_btn2" type="button">Unassign</button>
                        </li> */}
                    </ul>
                </div>
                <div className="loksabha_list">
                    <ul>
                        <li>
                            <h5>1</h5>
                        </li>
                        <li>
                            <h5>2</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">3</h5>
                        </li>
                        <li>
                            <h5>4</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">5</h5>
                        </li>

                        <li>
                            <h5>1</h5>
                        </li>
                        <li>
                            <h5>2</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">3</h5>
                        </li>
                        <li>
                            <h5>4</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">5</h5>
                        </li>

                        <li>
                            <h5>1</h5>
                        </li>
                        <li>
                            <h5>2</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">3</h5>
                        </li>
                        <li>
                            <h5>4</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">5</h5>
                        </li>

                        <li>
                            <h5>1</h5>
                        </li>
                        <li>
                            <h5>2</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">3</h5>
                        </li>
                        <li>
                            <h5>4</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">5</h5>
                        </li>

                        <li>
                            <h5>1</h5>
                        </li>
                        <li>
                            <h5>2</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">3</h5>
                        </li>
                        <li>
                            <h5>4</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">5</h5>
                        </li>

                        <li>
                            <h5>1</h5>
                        </li>
                        <li>
                            <h5>2</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">3</h5>
                        </li>
                        <li>
                            <h5>4</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">5</h5>
                        </li>
                        <li>
                            <h5>1</h5>
                        </li>
                        <li>
                            <h5>2</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">3</h5>
                        </li>
                        <li>
                            <h5>4</h5>
                        </li>
                        <li>
                            <h5 className="active_voter">5</h5>
                        </li>
                        <li>
                            <h5>4</h5>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    );
}

export default Loksabha;
