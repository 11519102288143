import React, { useState, useEffect } from "react";
import { VoterHeaderMenu } from "../VoterHeaderMenu";
import { useParams, useHistory } from "react-router-dom";
import { axiosPost } from "../../../utils/commonAxios";
import LoaderComponent from "../../loader/LoaderComponent";
import "./CommunicationDetailsList.css";

function CommunicationDetailsList() {
  const { epic_id } = useParams();
  const [communication, setCommunication] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    try {
      fetchVoterDetailsData();
    } catch (error) {}
  }, []);

  const fetchVoterDetailsData = async () => {
    setLoading(true);
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) {}

    let reqobject = {
      action: "/voter/search",
      epic_no: epic_id,
    };

    try {
      const resp = await axiosPost(reqobject, checkuser);
      // if (resp.data.response === null) {
      //   history.push(`/voterdetail/communication/${epic_id}`);
      // }
      const { communication } = resp.data.response;
      let setData = {};
      setData["mobile"] = communication.mobile;
      setData["landline"] = communication.landline;
      if (communication.add_curr) {
        setData["full_add"] = communication.add_curr.full_add;
        setData["full_add_oth"] = communication.add_curr.full_add_oth;
        setData["pincode"] = communication.add_curr.pincode;
      }
      setData["email"] = communication.email;
      setData["fb_id"] = communication.fb_id;
      setData["insta_id"] = communication.insta_id;
      setData["twitter_id"] = communication.twitter_id;
      setCommunication((prevState) => ({
        ...prevState,
        ...setData,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <LoaderComponent displayType={loading} />
      <VoterHeaderMenu>
        <div className="voter_container">
          <div className="personaldetail_list">
            <ul>
              <li>
                <h5>Mobile No : :</h5>
              </li>
              <li>
                {communication.mobile &&
                  communication.mobile.map((mobno) => {
                    return <h5 className="detail_color">{mobno}</h5>;
                  })}
              </li>
              <li>
                <h5>Landline number :</h5>
              </li>
              <li>
                <h5 className="detail_color">{communication.landline}</h5>
              </li>

              <li>
                <h5>Current Address :</h5>
              </li>
              <li>
                <h5 className="detail_color">{communication.full_add} </h5>
              </li>
              {communication.current_city && (
                <React.Fragment>
                  <li>
                    <h5>Current City :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">
                      {communication.current_city}
                    </h5>
                  </li>
                </React.Fragment>
              )}

              <li>
                <h5>Pincode :</h5>
              </li>
              <li>
                <h5 className="detail_color">{communication.pincode}</h5>
              </li>

              <li>
                <h5>Email :</h5>
              </li>
              <li>
                <h5 className="detail_color">{communication.email}</h5>
              </li>

              {/* <li>
                <h5>Insta Id :</h5>
              </li>
              <li>
                <h5 className="detail_color">{communication.insta_id}</h5>
              </li>

              <li>
                <h5>Twitter Id :</h5>
              </li>
              <li>
                <h5 className="detail_color">{communication.twitter_id}</h5>
              </li>

              <li>
                <h5>Facebook Id :</h5>
              </li>
              <li>
                <h5 className="detail_color">{communication.fb_id}</h5>
              </li> */}
            </ul>
          </div>
        </div>
      </VoterHeaderMenu>
    </div>
  );
}

export default CommunicationDetailsList;
