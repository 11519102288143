import React from 'react'
import RoutemapComponent from "../../components/map/RoutemapComponent";

function MapRoutePage() {
  return (
    <div>
      <RoutemapComponent />
    </div>
  )
}

export default MapRoutePage
