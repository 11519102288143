import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {axiosPost } from "../../utils/commonAxios";
import { afterLoginRoute } from "../../utils/helper";
import { AlertBox } from "../../components/common/AlertBox"
import "./LoginComponent2.css";
// const roleList = [{ _id: 1, label: "Karyakarta" }, { _id: 2, label: "Member" }];


function LoginComponent2() {
  const defaultTime = 120;
  const [formStep, setFormStep] = useState(1);
  const history = useHistory();
  const [mobile, setmobile] = useState("");
  const [timer, setTimer] = useState({
    minute:0,
    second:0
  });
  const [resend, setResend] = useState(1);

  const mobInputRef = useRef();

  useEffect(() => {
    mobInputRef.current.focus();
  }, []);

  const onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      handleSendOtp(e);
    }
  };

  const onKeyDownHandlerForm2 = e => {
    if (e.keyCode === 13) {
      handleSubmitOtp(e)
    }
  }


  const handleSendOtp = (e) => {
    e.preventDefault();
    let alertMsg = "Please provide a valid mobile number.";
    if (mobile.length === 10) {
      let reqobject = {
        action: "/user/login",
        mobile,
      };
      document.getElementById("full_loader").style.display = "block";
      axiosPost(reqobject).then((result) => {
        document.getElementById("full_loader").style.display = "none";
        if (result && result.data) {
          let data = result.data;
          if (data.resultFlag) {
            setFormStep(2);
            // setTimer(defaultTime);
            setResend(0);
            handleTimer(defaultTime);
          } else {
            let mssg = data.message
              ? data.message
              : "You need to enable JavaScript to run this app.";
            AlertBox("Message", mssg);
          }
        }
      });
    } else {
      mobInputRef.current.focus();
      AlertBox("Message", alertMsg);
    }
  };
  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    let alertMsg = "Please enter OTP, that has been sent to your mobile number.";
    let otp = "";
    let otp1 = document.getElementById("stp1");
    let otp2 = document.getElementById("stp2");
    let otp3 = document.getElementById("stp3");
    let otp4 = document.getElementById("stp4");
    let otp5 = document.getElementById("stp5");
    let otp6 = document.getElementById("stp6");
    otp = otp1 ? otp1.value : "";
    otp += otp2 ? otp2.value : "";
    otp += otp3 ? otp3.value : "";
    otp += otp4 ? otp4.value : "";
    otp += otp5 ? otp5.value : "";
    otp += otp6 ? otp6.value : "";
    if (otp.length === 6) {
      let reqobject = {
        action: "/user/verifyotp",
        otp,
        mobile,
      };
      document.getElementById("full_loader").style.display = "block";
      axiosPost(reqobject).then((result) => {
        document.getElementById("full_loader").style.display = "none";
        if (result && result.data) {
          let data = result.data;
          if (data.resultFlag) {
            console.log("data.responseif::: ",data.response );
            if (data.response) {
              data = data.response;
              localStorage.setItem("user_role", data.designition_id);
              localStorage.setItem("auth_key", data.auth_key);
              localStorage.setItem("user_data", JSON.stringify(data));
              let route = afterLoginRoute(data.designition);
              history.push(route);
            }
          } else {
            AlertBox("Message", data.message);
            let isBlocked = data.response && data.response.is_block ? data.response.is_block : null
            if(isBlocked){
              setFormStep(1);
            }
          }
        }
      });
    } else {

      AlertBox("Message", alertMsg);
    }
  };

  const codeExpired = ()=>{
    AlertBox("Message","Your verification code has been expired so Pls. click on resend code button")
  }

  const resendOtp = () => {
    let alertMsg = "";
    let reqobject = {
      action: "/user/resendotp",
      mobile,
    };
    document.getElementById("full_loader").style.display = "block";
    axiosPost(reqobject).then((result) => {
      document.getElementById("full_loader").style.display = "none";
      if (result && result.data) {
        let data = result.data;
        if (data.resultFlag) {
          setResend(0);
          // setTimer(defaultTime);
          handleTimer(defaultTime);
          alertMsg = "Check you message box you will get another OTP.";
          AlertBox("Message", alertMsg);
        } else {
          AlertBox("Message", data.message);
          let isBlocked = data.response && data.response.is_block  ? data.response.is_block : null
          if(isBlocked){
            setFormStep(1);
          }
        }
      }
    });
  };

  const handleTimer = (time) => {
    let minute = Math.floor(time /60);
    let second = Math.floor(time - minute * 60);
    if(time > 0){
      let timernew = time - 1;
      if(timernew === 0){
        setResend(1)
      }else{
        setTimeout(() =>{
          handleTimer(timernew);
        },1000)
        setResend(0)
      }
      setTimer({minute:minute, second:second})
    }
    
  };

  const handleCancelVerify = () => {
    setFormStep(1);
    setTimer(0);
  };
  const changeInputFocus = (element, nxtId, prevId) => {
    let elementDocbyId = document.getElementById(element.target.id);
    if (elementDocbyId) {
      elementDocbyId.value = elementDocbyId.value.substring(0, 1);
    }
    if (element.keyCode === 8) {
      nxtId = prevId;
    }
    if (document.getElementById(nxtId)) {
      document.getElementById(nxtId).focus();
    }
  };

  return (
    <div>
      {formStep === 1 && (
        <div>
          <form onKeyDown={onKeyDownHandler} >
            <div className="bjp_headsection">
              <img src="/images/modi4.jpg" alt="" />
            </div>
            <div className="voter_container">
              <div className="bjplogin_page">
                <h5>Jan Sampark</h5>
                
                <div className="phone_postion">
                  <input
                    type="number"
                    ref={mobInputRef}
                    placeholder="Mobile Number"
                    onChange={(e) => setmobile(e.target.value)}
                  />
                  <img src="/images/phone.svg" alt="" />
                </div>

                <a href="#" onClick={handleSendOtp}>
                  login
                </a>
              </div>
            </div>
          </form>
        </div>
      )}

      {formStep === 2 && (
        <form onKeyDown={onKeyDownHandlerForm2} >
          <div className="voter_container">
            <div className="otp_container">
              <h4>Enter the verify code</h4>
              <p>
                We sent a verifycation code to<br/>
                <span>+91 {mobile.substring(0, 5)}XXXX</span>
              </p>
              <form className="otp" autoComplete="off" noValidate="">
                <fieldset>
                  <input
                    maxLength="1"
                    className="active_input"
                    type="number"
                    id="stp1"
                    onKeyUp={(e) => changeInputFocus(e, "stp2", "stp1")}
                  />
                  <input
                    maxLength="1"
                    type="number"
                    id="stp2"
                    onKeyUp={(e) => changeInputFocus(e, "stp3", "stp1")}
                  />
                  <input
                    maxLength="1"
                    type="number"
                    id="stp3"
                    onKeyUp={(e) => changeInputFocus(e, "stp4", "stp2")}
                  />
                  <input
                    maxLength="1"
                    type="number"
                    id="stp4"
                    onKeyUp={(e) => changeInputFocus(e, "stp5", "stp3")}
                  />
                  <input
                    maxLength="1"
                    type="number"
                    id="stp5"
                    onKeyUp={(e) => changeInputFocus(e, "stp6", "stp4")}
                  />
                  <input
                    maxLength="1"
                    type="number"
                    id="stp6"
                    onKeyUp={(e) => changeInputFocus(e, "stp6", "stp5")}
                  />
                </fieldset>
              </form>
              {!resend && (
               
                <h5>
                  The verify code will be expire in 
                  {" "}{timer.minute < 10 ? "0" + timer.minute : timer.minute}{":"}
                  {timer.second < 9 ? "0" + timer.second : timer.second}
                </h5>
              )}
              {!!resend && (
                <h6>
                  <a href="#" onClick={() => resendOtp()}>
                    <img src="/images/refresh.svg" alt="" />
                  Resend Code
                </a>
                </h6>
              )}
              <ul>
                <li>
                  {!!resend ? (
                    <a href="#" onClick={()=>codeExpired()}>verify</a>
                  ): <a href="#" className="verify_btn" onClick={handleSubmitOtp}>verify</a>}
                  
                </li>
                <li>
                  <a href="#" className="cancel_btn" onClick={handleCancelVerify}>
                    cancel
                </a>
                </li>
              </ul>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default LoginComponent2;















// roll select code backup

  // useEffect(() => {
  //   getDesignation();
  // }, []);

  // const getDesignation = async () => {
  //   let res = await axiosGet("/designation/list");
  //   if (res.data.response && res.data.response) {
  //     setRoleList(res.data.response);
  //     let firstRoleId = res.data.response[0] ? res.data.response[0]._id : "";
  //     setRole(firstRoleId);
  //   }
  // }


{/* <div className="member_select">
  <select name="role" id="role"
    defaultValue={role}
    onChange={e => setRole(e.target.value)}
  >
    {roleList.map((el, key) => {
      // let selected = false;
      // if(role === el) {
      //   selected = true;
      // }
      return <option value={el._id} key={key}>{getLabelFromLangArr(el.json_language_data, "name")}</option>
    })}
  </select>
  <img src="/images/chevrondown.svg" alt="" />
</div> */}
  {/* <input
  type="text"
  placeholder="Name"
  onChange={(e) => setName(e.target.value)}
/> */}