import React from 'react'
import { appLanguageData } from "../../utils/language";

function BoothSelctcComponent(props) {
  return props.abhiyaanList && props.abhiyaanList.length > 0 ? (
    <div className="booth_select">
      <select onChange={props.handleSelectChange}>
        {/* <option value="">Select option</option> */}
        {
          props.abhiyaanList.map((abhiyaanData, i) => {
            let list = appLanguageData(abhiyaanData.json_language_data, "abhiyaan_name")
            return (
              <option
                key={i}
                value={abhiyaanData["_id"]}
              >{list}</option>
            )
          })}
      </select>
    </div>
  ) : null
}

export default BoothSelctcComponent
