import React from "react";
import "./AssignVoterPopUp.css";

export default function AssignVoterPopUp({ data, closeAssignPopUp, setFilterData, confirmationBox }) {

  return (
    <div className="assignvoter_popupbg">
      <div className="assignvoter_popup">
        <h4>Selected Voters</h4>
        <div className="assignlist_scroll">
          {
            data.map((userData, i) => {
              if (userData.activeClass) {
                return (
                  <ul key={i}>
                    <li>
                      <h6>{userData.serial_no}.</h6>
                    </li>
                    <li>
                      <h5>
                        <img src="/images/user.svg" alt="" /> {userData.name}
                      </h5>
                      <h5>
                        {userData.voter_data && userData.voter_data.length > 0 && userData.voter_data[0].personal ?
                          userData.voter_data[0].personal.add_curr &&
                          <>
                            <img src="/images/home2.svg" alt="" />
                            {userData.voter_data[0].personal.add_curr.full_add}
                          </>
                          : null}
                      </h5>
                    </li>
                    <li onClick={() => setFilterData(userData)}>
                      <img src="/images/delete.svg" alt="" />
                    </li>
                  </ul>
                )
              }
            })
          }

        </div>
        <div className="otp_container2">
          <ul>
            {/* <li><a href="#" className="verify_btn">assign</a></li> */}
            {/* <li><a href="#" className="cancel_btn">cancel</a></li> */}
            <li>
              <button className="verify_btn2" onClick={() => confirmationBox()}>assign</button>
            </li>
            <li>
              <button className="cancel_btn2" onClick={() => closeAssignPopUp()}>cancel</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}