import {
    KARYAKARTA_LIST_COUNT_PENDING,
    KARYAKARTA_LIST_COUNT_SUCCESS,
    KARYAKARTA_LIST_COUNT_ERROR,
    KARYAKARTA_LIST_COUNT_EMPTY
} from '../actions/types'

const INITIAL_STATE = {
    loading: false,
    success: false,
    notFound: false,
    errorMessage: null,
  };

export default (state = INITIAL_STATE, action)=>{
    switch(action.type){
        case KARYAKARTA_LIST_COUNT_PENDING:
            return {
                ...state,
                success: false,
                loading: true,
                notFound: false,
                errorMessage: null,
              };
            
        case KARYAKARTA_LIST_COUNT_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                errorMessage: null,
                notFound: false,
                data: action.payload,
            };
        case KARYAKARTA_LIST_COUNT_ERROR:
            return {
                ...state,
                success: true,
                loading: false,
                notFound: false,
                errorMessage: action.payload,
            };
        case KARYAKARTA_LIST_COUNT_EMPTY:
            return {
                ...state,
                success: true,
                loading: false,
                errorMessage: null,
                notFound: false,
                data: [],
            };
        default:
            return state;
    }
}