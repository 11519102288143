import React, { useState, memo } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import VoterHeaderComponent from "../voter/voterheader";
import LoaderComponent from "../loader/LoaderComponent";
import { removeHiphensinEpicNo } from "../../utils/misc";
import { getSchemeDetailsByVoter } from "../../actions/voter";
import { appLanguageData } from "../../utils/language";
import ListView from '../voter/ListView'
import GridView from '../voter/GridView'

const FindVotersList = (props) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [datalist, setDatalist] = useState([]);
  const [datalistAll, setDatalistAll] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(limit);
  const [markDoneObj, setMarkDoneObj] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [schemeDetails, setSchemeDetails] = useState([]);
  const [viewType, setViewType] = useState('list')
  const [listImage,setListImage] = useState('images/list_active.svg')
  const [gridImage,setGridImage] = useState('images/grid_black.svg')

  const { booth_code, agent_id } = useParams();
  const history = useHistory();

  useEffect(() => {
    // console.log(props.votersList.success);
    if (props.votersList.success === false) {
      history.push("/find-voters");
    }
    let countData = props.votersList.data && props.votersList.data.length;
  
    setDatalistAll(props.votersList.data);
    setTotalCount(countData);
    setData(props.votersList.data);
    setLoading(false)
  }, []);

  const setData = async data=>{
    
    if(data){
      ////Long Way 
      // let values;
      // if(Object.keys(datalist).length > 0){
      //   let thirtyData = data.splice(0,limit);
      //   values = datalist.concat(thirtyData);
      // }else{
      //   values = data.splice(0,limit)
      // }
      // await setDatalist(values);

      //short way
      await setDatalist(datalist.concat(data.splice(0,limit)));

    }
    
  }

  const loadMore = () => {
    setPage(page + 1);
    setOffset(offset + limit);
    setData(datalistAll);
  };

  const closeSchemePopup = () => {
    setShowPopup(false);
  };

  const openSchemePopup = (schemeBeneficery) => {
    if (
      schemeBeneficery &&
      schemeBeneficery[0] &&
      schemeBeneficery[0]["schemes_beneficary"] &&
      Object.keys(schemeBeneficery[0]["schemes_beneficary"]).length > 0
    ) {
      setLoading(true);
      setShowPopup(true);
      // console.log(Object.keys(schemeBeneficery[0]['schemes_beneficary']).join(','));
      let schemeIds = Object.keys(
        schemeBeneficery[0]["schemes_beneficary"]
      ).join(",");
      getSchemeDetailsByVoter(schemeIds)
        .then((data) => {
          // console.log(data.response);
          setLoading(false);
          setSchemeDetails(data.response);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const listView = () => {
    setViewType('list')
    setListImage('images/list_active.svg');
    setGridImage('images/grid_black.svg')
    
  }
  const gridView = () => {
    setViewType('grid')
    setGridImage('images/grid_active.svg')
    setListImage('images/list_black.svg');
  }

  return (
    
    <div>
      <div className="">
        <VoterHeaderComponent />

        {/* show loader */}
        <LoaderComponent displayType={loading} />

        <div className="voter_container">
          <div className="filter-buttons">
              <ul>
                <li>
                  <img src={listImage} onClick={listView} style={{ position: 'relative' }} alt="" />
                </li>
                <li>
                  <img src={gridImage} onClick={gridView} style={{ position: 'relative' }} alt="" />
                </li>
              </ul>
          </div>
        </div>
        {/* List view code */}
        {viewType && viewType === 'list' && 
           datalist.map((el, i) => {
            return (
              <ListView key={i} el={el} markDoneObj = {markDoneObj} openSchemePopup={openSchemePopup}/> 
            );
          }) 
        }
        {/* Grid view code */}
        {viewType && viewType === 'grid' && 
          <React.Fragment>
            <div className="voter_container">
              <div className="listgrid_flex">
                {datalist.map((el, i) => {
                    return (
                      <GridView key={i} el={el} markDoneObj = {markDoneObj} openSchemePopup={openSchemePopup}/> 
                    );
                  })}
              </div>
            </div>
          </React.Fragment>
        }
        {/* No Record Found */}
        {/* {!loading && (datalist.length === 0 || datalist.length === -1) && 
          <React.Fragment>
            <div className="voter_container">
                <div className="voterlist_detail">
                  <div className="voterlist_profile">
                    <ul>
                      <li className="dot_position">
                        <h4>No record found</h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          </React.Fragment> 
        } */}

        {totalCount >= offset && (
          <React.Fragment>
            <button className="loadmore_btn" onClick={loadMore}>
              Load More
            </button>
          </React.Fragment>
        )}
      </div>

      {showPopup && (
        <div className="beneficiary_popup">
          <div className="beneficiary_content">
            <p>
              <span onClick={() => closeSchemePopup()}>&times;</span>
            </p>
            <h4>Beneficiary</h4>
            {schemeDetails.map((scheme, i) => (
              <ul key={i}>
                <li>
                  <img src={`images/img-4${i + 1}.svg`} alt="" />
                </li>
                <li>
                  <h5>{appLanguageData(scheme.json_language_data, "title")}</h5>
                  <p>{appLanguageData(scheme.json_language_data, "desc")}</p>
                </li>
              </ul>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// export default FindVotersList;
const mapStateToProps = (state) => {
  return {
    votersList: state.FindVotersList,
  };
};

export default connect(mapStateToProps)(FindVotersList);
