import React from "react";
import EducationalDetailsList from "../../components/voterDetail/educational/EducationalDetailsList";

function EducationList() {
  return (
    <div>
      <EducationalDetailsList />
    </div>
  );
}

export default EducationList;
