import { connect } from "react-redux";
import { PersonalDetails } from "./PersonalDetails";
import { categoryList, cleanCategoryList } from "../../../actions/personal";

const mapStateToProps = (state) => {
  return {
    personl: state.personl,
  };
};

// const mapDispatchToProps = () => ({
//   categoryList,
//   cleanCategoryList
// });

export const PersonalDetaillContainer = connect(mapStateToProps, {
  categoryList: categoryList,
  cleanCategoryList: cleanCategoryList,
})(PersonalDetails);
