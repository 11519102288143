import React from "react";
import Sangathan from "../../components/voter/Sangathan";

function SangathanPage() {
  return (
    <div>
      <Sangathan />
    </div>
  );
}

export default SangathanPage;
