import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import VoterHeaderComponent from "./voterheader";

const KaryakartaPopup = () => {
  return (
    <div>
      <div className="karya_popup">
        <div className="kar_assign_voter">
          <h5>Assigned Voters</h5>
          <ul>
            <li>
              <h6>To</h6>
              <input type="text" placeholder="20" />
            </li>
            <li>
              <h6>From</h6>
              <input type="text" placeholder="70" />
            </li>
          </ul>
          <div style={{ display: "flex" }}>
            <button type="button" className="save_btn">
              Save
            </button>
            <button type="button" className="cancel_btn">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <VoterHeaderComponent />
      <div className="voter_container">
        <div className="karya_heading">
          <h4>Karyakarta List</h4>
        </div>
      </div>

      <div className="karya_list">
        <ul>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6 className="karta_color">Name</h6>
                  </li>
                  <li>
                    <h6 className="karta_color karta_value">Assigned Voters</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Samsher Singh Rathore :</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Ranjeet</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Samsher Singh</h6>
                  </li>
                  <li>
                    <button type="button">Assigned Voter >></button>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Samsher Singh Rathore :</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Ranjeet</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Samsher Singh Rathore :</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Ranjeet</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Samsher Singh Rathore :</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Ranjeet</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default KaryakartaPopup;
