import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import VoterHeaderComponent from "../voter/voterheader";
import { boothListing, cleanBoothListing } from "../../actions/booth";
import LoaderComponent from "../loader/LoaderComponent";

const BoothListComponent = (props) => {
  const [boothCode, setBoothCode] = useState("");
  useEffect(() => {
    let sectorData;
    try {
      sectorData = localStorage.getItem("user_data");
    } catch (error) {}

    if (sectorData) {
      sectorData = JSON.parse(sectorData);
      props.boothListing(sectorData["sector_code"]);
    }
    //User Details
    let userInfo;
    try {
      userInfo = localStorage.getItem("user_data");
    } catch (error) {}

    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      setBoothCode(userInfo.booth_code);
    }

    return () => {
      props.cleanBoothListing();
    };
  }, []);

  return (
    <div>
      <VoterHeaderComponent hideBackIcon={true} />

      {/* show loader */}
      {props.boothData && props.boothData.loading ? (
        <LoaderComponent displayType={props.boothData.loading} />
      ) : null}

      <div className="voter_container">
        <div className="sevabooth_container">
          {/* <h4>Jansampark</h4> */}

          {/* show data */}
          {props.boothData &&
          props.boothData.data &&
          props.boothData.data.length > 0 ? (
            props.boothData.data.map((booth) => (
              <React.Fragment key={booth._id}>
                <Link to={{ pathname: "/boothlistdetail/" + booth.code }}>
                  <ul className="sevabooth_list">
                    <li>
                      <h5>Booth No</h5>
                      <h5>Polling Station</h5>
                      {/* <h5>Number of Booth:</h5> */}
                    </li>
                    <li className="booth_number">
                      <h5>{booth.booth_no}</h5>
                      <h5>{booth.name}</h5>
                      {/* <h5>
                        <span style={{ paddingRight: "10px" }}>5</span>
                        <Link to={`/karyakartalist/${boothCode}`}>
                          View detail <img src="images/chev_right.svg" alt="" />
                        </Link>
                      </h5> */}
                    </li>
                    <li>
                      <img src="/images/next2.svg" alt="" />
                    </li>
                  </ul>
                </Link>
              </React.Fragment>
            ))
          ) : (
            <div>
              <h4>No Data found</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    boothData: state.boothList,
  };
};

export default connect(mapStateToProps, {
  boothListing: (booth_code) => boothListing(booth_code),
  cleanBoothListing: () => cleanBoothListing(),
})(BoothListComponent);
