import React, { useState, memo } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
// import { connect } from "react-redux";
import { axiosGet, axiosPost } from "../../utils/commonAxios";
import VoterHeaderComponent from "../voter/voterheader";
import LoaderComponent from "../loader/LoaderComponent";
import { getSchemeDetailsByVoter } from "../../actions/voter";
import { appLanguageData } from "../../utils/language";
// import "./ElectoralList.css";
import { removeHiphensinEpicNo } from "../../utils/misc";
import debounce from "../voter/scheme/debounce";
import ListView from '../voter/ListView'
import GridView from '../voter/GridView'

const ElectoralList = ({ ElectoralList }) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(30);
  const [datalist, setDatalist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(limit);
  const [markDoneObj, setMarkDoneObj] = useState({});

  const [showPopup, setShowPopup] = useState(false);
  const [schemeDetails, setSchemeDetails] = useState([]);

  const [viewType, setViewType] = useState('list')
  const [listImage,setListImage] = useState('images/list_active.svg')
  const [gridImage,setGridImage] = useState('images/grid_black.svg')

  const getDataList = async (search = "", page, limit, isSearchData) => {
    setLoading(true);
    let userInfo;
    try {
      userInfo = localStorage.getItem("user_data");
    } catch (error) {}

    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
    // document.getElementById("full_loader").style.display = "block";
    let urldata = {
      skip: page,
      limit: limit,
      booth_code: userInfo ? userInfo["booth_code"] : "",
    };
    
    if(userInfo && userInfo["designition"] === "Karyakarta"){
      urldata.panna_pramukh_id = userInfo["_id"]
    }

    if (search) {
      urldata["search"] = search;
    }
    // electoral_list/detail
    // let res = await axiosGet("/voter/list", urldata);
    let res = await axiosGet("/electoral_list/list", urldata);
    if (res.data && res.data.resultFlag) {
      if (isSearchData) {
        setDatalist(res.data.response.listData);
      } else {
        setDatalist([...datalist, ...res.data.response.listData]);
      }

      let tempObjOfMarkDone = {};
      if (
        res.data.response &&
        res.data.response.markDoneObj &&
        res.data.response.markDoneObj.length > 0
      ) {
        // create object
        for (let i = 0; i < res.data.response.markDoneObj.length; i++) {
          tempObjOfMarkDone[
            res.data.response.markDoneObj[i]["voter_id"]
          ] = true;
        }
      }
      setMarkDoneObj({ ...markDoneObj, ...tempObjOfMarkDone });
      setLoading(false);
    } else {
      setLoading(false);
    }
    // document.getElementById("full_loader").style.display = "none";
  };

  useEffect(() => {
    getDataList("", page, limit);
    setPage(page + 1);
    // setOffset(offset + limit);
  }, []);

  const debouncedSearchTerm = debounce(search, 500);
  useEffect(() => {
    if (debouncedSearchTerm) {
      getDataList(debouncedSearchTerm, "", "", true);
    }
  }, [debouncedSearchTerm]);

  const handleSearch = (value) => {
    if (value.length === 0) {
      getDataList("", page, limit, false);
      setPage(page + 1);
    } else {
      setSearch(value);
    }
  };

  const loadMore = () => {
    setPage(page + 1);
    setOffset(offset + limit);
    getDataList("", page, limit);
  };

  const onClickVoterMaakDone = async function onClickVoterMaakDone(element) {
    // console.log(element._id);
    setLoading(true);

    let data = {
      epic_no: element.epic_no,
    };

    let reqobject = {
      action: "/electoral_list/markvote",
      ...data,
    };

    let res = await axiosPost(reqobject, {});
    if (res.data && res.data.resultFlag) {
      datalist.map((data) => {
        // console.log(data._id);
        if (data._id == element._id) {
          data.is_voted = true;
        }
      });

      setDatalist(datalist);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  // console.log(datalist);

  const openSchemePopup = (schemeBeneficery) => {
    if (
      schemeBeneficery &&
      schemeBeneficery[0] &&
      schemeBeneficery[0]["schemes_beneficary"] &&
      Object.keys(schemeBeneficery[0]["schemes_beneficary"]).length > 0
    ) {
      setLoading(true);
      setShowPopup(true);
      // console.log(Object.keys(schemeBeneficery[0]['schemes_beneficary']).join(','));
      let schemeIds = Object.keys(
        schemeBeneficery[0]["schemes_beneficary"]
      ).join(",");
      getSchemeDetailsByVoter(schemeIds)
        .then((data) => {
          // console.log(data.response);
          setLoading(false);
          setSchemeDetails(data.response);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const closeSchemePopup = () => {
    setShowPopup(false);
  };

  const listView = () => {
    setViewType('list')
    setListImage('images/list_active.svg');
    setGridImage('images/grid_black.svg')
    
  }
  const gridView = () => {
    setViewType('grid')
    setGridImage('images/grid_active.svg')
    setListImage('images/list_black.svg');
  }
  return (
    <div>
      <div className="">
        <VoterHeaderComponent />

        {/* show loader */}
        <LoaderComponent displayType={loading} />

        <div className="voter_container">
          <div className="filter-buttons">
              <ul>
                <li>
                  <img src={listImage} onClick={listView} style={{ position: 'relative' }} alt="" />
                </li>
                <li>
                  <img src={gridImage} onClick={gridView} style={{ position: 'relative' }} alt="" />
                </li>
              </ul>
          </div>
          <div className="voter_search">
            <input
              type="text"
              placeholder="Search by name"
              onKeyUp={(e) => handleSearch(e.target.value)}
            />
            <img src="/images/search_black.svg" alt="" />
          </div>
        </div>
        {/* List view code */}
        {viewType && viewType === 'list' && 
           datalist.map((el, i) => {
            return (
              <ListView key={i} el={el} markDoneObj = {markDoneObj} openSchemePopup={openSchemePopup}/> 
            );
          }) 
        }
        {/* Grid view code */}
        {viewType && viewType === 'grid' && 
          <React.Fragment>
            <div className="voter_container">
              <div className="listgrid_flex">
                {datalist.map((el, i) => {
                    return (
                      <GridView key={i} el={el} markDoneObj = {markDoneObj} openSchemePopup={openSchemePopup}/> 
                    );
                  })}
              </div>
            </div>
          </React.Fragment>
        }
        {/* No Record Found */}
        {!loading && (datalist.length === 0 || datalist.length === -1) && 
          <React.Fragment>
            <div className="voter_container">
                <div className="voterlist_detail">
                  <div className="voterlist_profile">
                    <ul>
                      <li className="dot_position">
                        <h4>No record found</h4>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          </React.Fragment> 
        }

        {datalist.length >= offset && (
          <React.Fragment>
            <button className="loadmore_btn" onClick={loadMore}>
              Load More
            </button>
          </React.Fragment>
        )}

        
      </div>

      {showPopup && (
        <div className="beneficiary_popup">
          <div className="beneficiary_content">
            <p>
              <span onClick={() => closeSchemePopup()}>&times;</span>
            </p>
            <h4>Beneficiary</h4>
            {schemeDetails.map((scheme, i) => (
              <ul key={i}>
                <li>
                  <img src={`images/img-4${i + 1}.svg`} alt="" />
                </li>
                <li>
                  <h5>{appLanguageData(scheme.json_language_data, "title")}</h5>
                  <p>{appLanguageData(scheme.json_language_data, "desc")}</p>
                </li>
              </ul>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ElectoralList;
