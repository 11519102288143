import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import VoterHeaderComponent from "../voter/voterheader";
import {
  karyakartaListCount,
  cleanKaryakartaListCount,
} from "../../actions/karyakarta";
import LoaderComponent from "../loader/LoaderComponent";
import "./KarykartaList.css";
import { __ucLetter } from "../../utils/language";

const KarykartaList = (props) => {
  let { code, abhiyaan_id } = useParams();

  useEffect(() => {
    if (typeof code !== "undefined" && abhiyaan_id !== "null") {
      props.karyakartaListCount(code, abhiyaan_id);
    }

    return () => {
      props.cleanKaryakartaListCount();
    };
  }, [code, abhiyaan_id]);

  // console.log(props.karyakarta);

  return (
    <div>
      <VoterHeaderComponent />

      {/* show loader */}
      {props.karyakarta && props.karyakarta.loading ? (
        <LoaderComponent displayType={props.karyakarta.loading} />
      ) : null}

      {/* <div className="voter_container">
         <div className="karkarta_container">
          <h4>Karykarta List</h4>
        </div> 
      </div> */}
      <div className="karykarta_list">
        <ul className="karykarta_count">
          {props.karyakarta.data &&
            props.karyakarta.data.map((value, i) => {
              return (
                <React.Fragment key={i}>
                  <li>
                    <div className="voter_container">
                      <ul className="karykarta_detail">
                        <li>
                          <h6>{__ucLetter(value.name)}</h6>
                        </li>
                        <li>
                          <h6 className="karykarta_green">
                            {Number(value.totalCount) === Number(0)
                              ? 0
                              : Math.floor(
                                (value.count / value.totalCount) * 100
                              )}
                            %
                          </h6>
                          {/* <h6 className="karykarta_green">{Math.floor((Math.random() * Math.floor(props.karyakarta.data && props.karyakarta.data.userList.length) / (props.karyakarta.data && props.karyakarta.data.userList.length)) * 100)}%</h6> */}
                        </li>
                      </ul>
                    </div>
                  </li>
                </React.Fragment>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    karyakarta: state.karyakarta,
  };
};

export default connect(mapStateToProps, {
  karyakartaListCount: (code, abhiyaan_id) =>
    karyakartaListCount(code, abhiyaan_id),
  cleanKaryakartaListCount: cleanKaryakartaListCount,
})(KarykartaList);
