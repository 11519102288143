import { axiosPost, axiosGet } from "../utils/commonAxios";

import {
    KARYAKARTA_LIST_COUNT_PENDING,
    KARYAKARTA_LIST_COUNT_SUCCESS,
    KARYAKARTA_LIST_COUNT_ERROR,
    KARYAKARTA_LIST_COUNT_EMPTY
} from './types'


export const karyakartaListCount = (booth_code, abhiyaan_id) => {
    return async (dispatch)=>{
        dispatch({
            type:KARYAKARTA_LIST_COUNT_PENDING,
        })
        
        let params = {
            booth_code,
            abhiyaan_id
        }
        try {
            let resp = await axiosGet('/abhiyan/details',params)
            dispatch({
                type:KARYAKARTA_LIST_COUNT_SUCCESS,
                payload:resp.data.response
            })
        } catch (error) {
            dispatch({
                type:KARYAKARTA_LIST_COUNT_ERROR,
            })
        }
    }
}

export const cleanKaryakartaListCount = () =>{
    return (dispatch)=>{
        dispatch({
            type:KARYAKARTA_LIST_COUNT_EMPTY
        })
    }
}