import React, { useState, useEffect } from 'react'
import { getScgeme } from "../../../actions/index";
import { appLanguageData } from "../../../utils/language";
import debounce from "./debounce";

const Scheme = (props) => {
  const [suggestions, setSuggestions] = useState([])
  const [tempArr, setTempArr] = useState([])
  const [schemeInput, setSchemeInput] = useState("")
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (props.tagData.length > 0) {
      // console.log(props.tagData)
      setTempArr([...props.tagData])
    }

    // return () => {
    //   cleanup
    // }
  }, [props.tagData])

  const debouncedSearchTerm = debounce(schemeInput, 500);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        // Fire off our API call
        getScgeme(debouncedSearchTerm).then((resdata) => {
          let newSugData = resdata.response && resdata.response.map((res, i) => {
            return { id: res._id, name: appLanguageData(res.json_language_data, "title") }
          })
          setIsSearching(false);
          if (newSugData && newSugData.length > 0) {
            setSuggestions(newSugData)
          } else {
            setSuggestions([{ id: 1, name: "no match found" }])
          }
        })
      } else {
        setSuggestions([]);
      }
    },
    [debouncedSearchTerm]
  );


  const onDelete = (tag) => {
    let tagId = tag.id
    let filterData = tempArr.filter((tag) => tag.id !== tagId)
    setTempArr(filterData)
    props.tagsArray(filterData)
  }

  const addUniqeOptions = (data) => {
    let tempArrNew = tempArr
    let index = -1;
    for (let i = 0; i < tempArrNew.length; i++) {
      if (tempArrNew[i].id == data.id) {
        index = i;
      }
    }

    if (index > -1) {
      tempArrNew[index] = data;
    } else {
      tempArrNew.push(data)
      setTempArr(tempArrNew)
    }
  }

  const onAddition = (tag) => {
    addUniqeOptions(tag)
    props.tagsArray(tempArr)
  }

  const getSchemeData = (event) => {
    // console.log(event.target.value)
    setSchemeInput(event.target.value)

    // if (!event.target.value) {
    //   setSuggestions([])
    // } else {
    //   if (event.target.value.length > 0) {
    // getScgeme(debouncedSearchTerm).then((resdata) => {
    //   let newSugData = resdata.response && resdata.response.map((res, i) => {
    //     return { id: res._id, name: appLanguageData(res.json_language_data, "title") }
    //   })
    //   setSuggestions(newSugData)
    // })
    //   }
    // }
  }

  // console.log('Scheme ', tempArr)

  return (
    <div className="voter_beneficary">
      <div className="">
        <h5>Scheme Beneficiary</h5>
        <div className="input_loader">
          <input type="text" placeholder="Mudra Yojna" value={schemeInput} onChange={(e) => getSchemeData(e)} />
          {isSearching ? (
            <img src="/images/loader2.svg" alt="loader" />
          ) : null}
        </div>
        <div className="select_suggestion">
          <ul>
            {
              suggestions.length > 0 && suggestions.map((suggestion, i) => {
                return (
                  <React.Fragment key={i}>
                    <li onClick={() => onAddition(suggestion)}>{suggestion.name}</li>
                  </React.Fragment>
                )
              })
            }
          </ul>
        </div>

        <ul>
          {
            tempArr.length > 0 && tempArr.map((tag, i) => {
              return (
                <React.Fragment key={i}>
                  <li><p>{tag.name} <span onClick={() => onDelete(tag)}>x</span></p></li>
                </React.Fragment>
              )
            })
          }
        </ul>

      </div>
    </div>

  )

}

export default Scheme