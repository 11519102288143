import { connect } from "react-redux";
import { EducationalDetails } from "./EducationalDetails";

const mapStateToProps = (state) => {
  // console.log(state);
  return {};
};

const mapDispatchToProps = () => ({});

export const EducationalDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EducationalDetails);
