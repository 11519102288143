import React from 'react'
import { PersonalDetaillContainer } from "../../components/voterDetail";

function Personal() {
  return (
    <div>
      <PersonalDetaillContainer />
    </div>
  )
}

export default Personal
