import React from "react";
import KarykartaCreatePage from "../../components/karyakarta/KarykartaCreatePage";

function KaryakartaCreate() {
  return (
    <div>
      <KarykartaCreatePage />
    </div>
  );
}

export default KaryakartaCreate;
