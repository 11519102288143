import { combineReducers } from "redux";
import authReducer from "./authReducer";
import todoReducers from "./todoReducers";
import voterReducers from "./voterReducers";
import questionReducers from "./questionReducers";
import questionOptionsReducers from "./questionOptionsReducers";
import boothReducers from "./boothReducers";
import boothDetailsReducer, { jansamparkCount } from "./boothDetailsReducer";
import karyakartaReducer from "./karyakartaReducer";
import sectorReducers from "./sectorReducers";
import personalReducer from "./personalReducer";
import voterKarykartaListReducer from "./voterKarykartaListReducer";
import FindVotersList from "./FindVotersList";

export default combineReducers({
  auth: authReducer,
  todo: todoReducers,
  voter: voterReducers,
  questioins: questionReducers,
  questionOptions: questionOptionsReducers,
  boothList: boothReducers,
  boothDetails: boothDetailsReducer,
  jansamparkCount: jansamparkCount,
  karyakarta: karyakartaReducer,
  sector: sectorReducers,
  personl: personalReducer,
  voterKarykarta: voterKarykartaListReducer,
  FindVotersList: FindVotersList,
});
