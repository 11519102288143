import React, { useState, useEffect, memo } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Questions from "./questions/Questions";
import VoterHeaderComponent from "./voterheader";
import { searchVoter, cleanVoterState, getSchemeByUser } from "../../actions/voter";
import { useParams } from "react-router-dom";
import { axiosPost } from "../../utils/commonAxios";
import LoaderComponent from "../loader/LoaderComponent";
import { __ucLetter } from "../../utils/language";
import { removeHiphensinEpicNo } from "../../utils/misc";

const notFound = {
  marginTop: "50px",
  marginLeft: "150px",
};

const SurveyList = () => {
  const { vid } = useParams();

  const [serveyDetails, setServeyDetails] = useState({});
  const [startLoading, setStartLoading] = useState(false);

  useEffect(() => {
    if (vid) {
      getSchemebenificearyByUser(vid);
    }
  }, [vid]);

  const getSchemebenificearyByUser = async (epic_no) => {
    let epicNo = removeHiphensinEpicNo(epic_no)
    setStartLoading(true)
    getSchemeByUser(epicNo).then((resp) => {
      if (resp.resultFlag) {
        // console.log(resp.response);
        // Object.values(serveyDetails.schemes_beneficary)
        setStartLoading(false)
        setServeyDetails(resp.response)
      } else {
        setStartLoading(false)
        setServeyDetails({})
      }
    });
  };

  return (
    <div>
      <VoterHeaderComponent />

      <LoaderComponent displayType={startLoading} />

      {Object.keys(serveyDetails) && Object.keys(serveyDetails).length > 0 && (
        <React.Fragment>
          <div className="voter_bg">
            <div className="voter_container">
              <div className="voter_detail">
                <div className="voterlink_btn">
                  <Link to={`/voter/${vid}`}>
                    <img src="/images/edit.svg" alt="" /> Edit
                  </Link>
                </div>
                <ul>
                  <li>Name:</li>
                  <li>
                    {serveyDetails.voter_data && serveyDetails.voter_data.personal.name_en ? __ucLetter(serveyDetails.voter_data.personal.name_en) : ""}
                  </li>
                  <li>Age:</li>
                  <li>
                    {serveyDetails.voter_data && serveyDetails.voter_data.personal.age ? serveyDetails.voter_data.personal.age : ""}
                  </li>
                  <li>Sex:</li>
                  <li>
                    {serveyDetails.voter_data && serveyDetails.voter_data.personal.gender ? serveyDetails.voter_data.personal.gender : ""}
                  </li>
                  <li>Address:</li>
                  <li>
                    {serveyDetails.voter_data && serveyDetails.voter_data.personal.address ? serveyDetails.voter_data.personal.address : ""}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="voter_bg">
            <div className="voter_container">
              <div className="voter_remark voter_remark_border">
                <h5>Remarks</h5>
                <p>
                  {/* jansampark_data jansampark_data.remarks.join(",") */}
                  {serveyDetails.jansampark_data && serveyDetails.jansampark_data.remarks.join(", ")}
                </p>
              </div>
            </div>
          </div>

          <div className="voter_bg">
            <div className="voter_container">
              <div className="voter_remark voter_remark_border">
                <h5>Questionnaire</h5>
                {
                  serveyDetails.question_obj && Object.values(serveyDetails.question_obj).map((questionAns, i) => (
                    <React.Fragment key={i}>
                      <h6>{questionAns.ques}</h6>
                      <p>{questionAns.ans}</p>
                    </React.Fragment>
                  ))
                }
              </div>
            </div>
          </div>

          <div className="voter_bg">
            <div className="voter_container">
              <div className="voter_remark voter_remark_border">
                <h5>Scheme Beneficiary</h5>
                <ul>
                  {serveyDetails.voter_data && serveyDetails.voter_data.schemes_beneficary && serveyDetails.voter_data.schemes_beneficary.length > 0 && serveyDetails.voter_data.schemes_beneficary.map((scheme, i) => (
                    <React.Fragment key={i}>
                      <li>
                        <h6>{scheme.name}</h6>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
        // (
        //     <React.Fragment>
        //       <div style={notFound}>
        //         <h4>No Data Found!</h4>
        //       </div>
        //     </React.Fragment>
        //   )
      }
    </div>
  );
};

export default SurveyList;