import React from "react";
import PoliticalDetailsList from "../../components/voterDetail/polical/PoliticalDetailsList";

function PoliticalList() {
  return (
    <div>
      <PoliticalDetailsList />
    </div>
  );
}

export default PoliticalList;
