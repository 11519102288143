import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { VoterHeaderMenu } from "../VoterHeaderMenu";
import { axiosPost } from "../../../utils/commonAxios";
import LoaderComponent from "../../loader/LoaderComponent";
import "./EducationalDetailsList.css";

function EducationalDetailsList() {
  const { epic_id } = useParams();
  const [Professional, setProfessional] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    try {
      fetchVoterDetailsData();
    } catch (error) {}
  }, []);
  const fetchVoterDetailsData = async () => {
    setLoading(true);
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) {}

    let reqobject = {
      action: "/voter/search",
      epic_no: epic_id,
    };

    try {
      const resp = await axiosPost(reqobject, checkuser);
      // if (resp.data.response === null) {
      //   history.push(`/voterdetail/education/${epic_id}`);
      // }
      const { professional } = resp.data.response;
      // console.log(professional);
      let setData = {};
      setData["edu_level"] = professional.edu_level;
      setData["main_profession"] = professional.main_profession;
      setData["description"] = professional.description;
      setData["profession"] = professional.profession;
      setData["skill_set"] = professional.skill_set.join();
      setData["subject"] = professional.subject;

      if (professional.occupation) {
        setData["position"] = professional.occupation.position;
        setData["organisation"] = professional.occupation.organisation;
        setData["start_yr"] = professional.occupation.start_yr;
        setData["end_yr"] = professional.occupation.end_yr;
      }

      if (professional.degree) {
        setData["d_name"] = professional.degree.name;
        setData["d_institute"] = professional.degree.institute;
        setData["d_subject"] = professional.degree.subject;
        setData["d_start_yr"] = professional.degree.start_yr;
        setData["d_end_yr"] = professional.degree.end_yr;
      }
      setProfessional((prevState) => ({
        ...prevState,
        ...setData,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  // console.log(Professional);
  return (
    <div>
      <LoaderComponent displayType={loading} />
      <VoterHeaderMenu>
        <div className="voter_container">
          <div className="personaldetail_list">
            <ul>
              <li>
                <h5>Education Level :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.edu_level}</h5>
              </li>

              <li>
                <h5>Subject :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.subject}</h5>
              </li>

              <li>
                <h5>Degree :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.d_name} </h5>
              </li>

              <li>
                <h5>Subject :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.d_subject}</h5>
              </li>

              {/* <li>
                <h5>Pincode :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.pincode}</h5>
              </li> */}

              <li>
                <h5>Institution :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.d_institute}</h5>
              </li>

              <li>
                <h5>Year :</h5>
              </li>
              <li>
                <h5 className="detail_color">
                  {Professional.start_yr} to {Professional.end_yr}
                </h5>
              </li>

              <li>
                <h5>Professional Profile :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.profession}</h5>
              </li>

              <li>
                <h5>Main Profession :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.main_profession}</h5>
              </li>

              <li>
                <h5>Organiation :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.organisation}</h5>
              </li>

              <li>
                <h5>Position :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.position}</h5>
              </li>

              <li>
                <h5>Year :</h5>
              </li>
              <li>
                <h5 className="detail_color">
                  {Professional.d_start_yr} to {Professional.d_end_yr}
                </h5>
              </li>

              <li>
                <h5>Skill Sets :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.skill_set}</h5>
              </li>

              <li>
                <h5>Description :</h5>
              </li>
              <li>
                <h5 className="detail_color">{Professional.description}</h5>
              </li>
            </ul>
          </div>
        </div>
      </VoterHeaderMenu>
    </div>
  );
}

export default EducationalDetailsList;
