import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import VoterHeaderComponent from "../voter/voterheader";
import "./VoterHeaderMenu.css";

function VoterHeaderMenu(props) {
  const { epic_id } = useParams();

  const [epicId, setEpicId] = useState("");
  const [windowLocation, setWindowLocation] = useState(
    window.location.pathname
  );

  useEffect(() => {
    if (epic_id) {
      setEpicId(epic_id);
    }
  }, []);

  // console.log(window.location.pathname);

  return (
    <div>
      <VoterHeaderComponent />
      {/* <div className="updatelist_bg">
          <div className="voter_container">
            <div className="personal_updatelist">
              <ul>
                <li>
                  <h6>Last Update</h6>
                  <select>
                    <option>12 Jan 21</option>
                    <option>13 Jan 21</option>
                    <option>14 Jan 21</option>
                  </select>
                </li>
                <li>
                  <h6 className="text_right">Updated by</h6>
                  <h5 className="text_right">Ram Singh</h5>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      <div>
        <div className="votermenu_list">
          <ul>
            <li
              className={
                windowLocation === `/voterdetail/personal/list/${epicId}` ||
                windowLocation === `/voterdetail/personal/${epicId}`
                  ? "active_votermenu"
                  : ""
              }
            >
              <Link
                to={
                  windowLocation === `/voterdetail/personal/${epicId}`
                    ? `/voterdetail/personal/${epicId}`
                    : `/voterdetail/personal/list/${epicId}`
                }
              >
                personal
              </Link>
            </li>
            <li
              className={
                windowLocation ===
                  `/voterdetail/communication/list/${epicId}` ||
                windowLocation === `/voterdetail/communication/${epicId}`
                  ? "active_votermenu"
                  : ""
              }
            >
              <Link
                to={
                  windowLocation === `/voterdetail/communication/${epicId}`
                    ? `/voterdetail/communication/${epicId}`
                    : `/voterdetail/communication/list/${epicId}`
                }
              >
                communication
              </Link>
            </li>
            <li
              className={
                windowLocation === `/voterdetail/education/list/${epicId}` ||
                windowLocation === `/voterdetail/education/${epicId}`
                  ? "active_votermenu"
                  : ""
              }
            >
              <Link
                to={
                  windowLocation === `/voterdetail/education/${epicId}`
                    ? `/voterdetail/education/${epicId}`
                    : `/voterdetail/education/list/${epicId}`
                }
              >
                Education & Professional Detail
              </Link>
            </li>
            <li
              className={
                windowLocation === `/voterdetail/political/list/${epicId}` ||
                windowLocation === `/voterdetail/political/${epicId}`
                  ? "active_votermenu"
                  : ""
              }
            >
              <Link
                to={
                  windowLocation === `/voterdetail/political/${epicId}`
                    ? `/voterdetail/political/${epicId}`
                    : `/voterdetail/political/list/${epicId}`
                }
              >
                political detail
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <br />
      {props.children}
    </div>
  );
}

export { VoterHeaderMenu };
