import { axiosPost, axiosGet } from "../utils/commonAxios";

import {
  SEARCH_VOTER_PENDING,
  SEARCH_VOTER_SUCCESS,
  SEARCH_VOTER_ERROR,
  SEARCH_VOTER_EMPTY,
  SEARCH_VOTER_NOT_FOUND,
} from "./types";

export const searchVoter = (searchData, searchBy) => {
  // console.log(searchData, searchBy);
  return async (dispatch, getState) => {
    // dispatch another pending
    dispatch({
      type: SEARCH_VOTER_PENDING,
    });

    let userInfo;
    try {
      userInfo = localStorage.getItem("user_data");
    } catch (error) {}

    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }

    let userId = userInfo ? userInfo["_id"] : "";

    let bodyObj = {
      userId,
    };

    if (searchBy === "id") {
      bodyObj["epicno"] = searchData;
    } else if (searchBy === "name") {
      bodyObj["name"] = searchData;
    }

    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) {}

    let reqobject = {
      action: "/voter/search",
      ...bodyObj,
    };

    try {
      const response = await axiosPost(reqobject, checkuser);
      // dispatch another success

      if (
        response.data.response &&
        Object.keys(response.data.response).length > 0
      ) {
        // console.log(response.data.response);
        dispatch({
          type: SEARCH_VOTER_SUCCESS,
          payload: response.data.response,
        });
      } else {
        dispatch({
          type: SEARCH_VOTER_NOT_FOUND,
        });
      }
    } catch (error) {
      // dispatch another error
      dispatch({
        type: SEARCH_VOTER_ERROR,
        payload: "Some thing wrong",
      });
    }
  };
};

export const submitVoter = async (data) => {
  let checkuser = {};
  try {
    checkuser["token"] = localStorage.getItem("auth_key");
  } catch (error) {}

  let reqobject = {
    action: "/voter/jansampark_create",
    ...data,
  };

  try {
    const response = await axiosPost(reqobject, checkuser);
    return response.data;
  } catch (error) {
    // console.log(error)
    return "Something wrong";
  }
};

export const cleanVoterState = () => {
  return async (dispatch) => {
    dispatch({
      type: SEARCH_VOTER_EMPTY,
    });
  };
};

export const getQuestionByUser = async (voter_id) => {
  let params = {
    voter_id: voter_id,
  };

  try {
    let res = await axiosGet("/voter/get_jansampark_que", params);
    return res.data;
  } catch (error) {
    // console.log(error);
    return "Something wrong";
  }
};

export const getSchemeByUser = async (epic_no) => {
  let params = {
    epic_no: epic_no,
  };

  try {
    // let res = await axiosGet("/voter/get_scheme_beneficary", params);
    let res = await axiosGet("/voter/voter_jansampark_data", params);
    return res.data;
  } catch (error) {
    // console.log(error);
    return "Something wrong";
  }
};

export const getSchemeDetailsByVoter = async (schemes_id) => {
  let params = {
    schemes_id: schemes_id,
  };

  try {
    // let res = await axiosGet("/voter/get_scheme_beneficary", params);
    let res = await axiosGet("/voter/get_voter_schemes", params);
    return res.data;
  } catch (error) {
    // console.log(error);
    return "Something wrong";
  }
};  

export const search = async (searchData, searchBy) => {
  let bodyObj = {
  };

  if (searchBy === "id") {
    bodyObj["epicno"] = searchData;
  } else if (searchBy === "name") {
    bodyObj["name"] = searchData;
  }

  let checkuser = {};
  try {
    checkuser["token"] = localStorage.getItem("auth_key");
  } catch (error) {}

  let reqobject = {
    action: "/voter/search",
    ...bodyObj,
  };

  try {
    const response = await axiosPost(reqobject, checkuser);
    // dispatch another success

    if (
      response.data.response &&
      Object.keys(response.data.response).length > 0
    ) {
      // console.log(response.data.response);
     return response.data.response;
    } else {
    }
  } catch (error) {
    // dispatch another error
    console.log('error')
  }

};

export const getUserDetails = async (vid) => {
  let checkuser = {};
  try {
    checkuser["token"] = localStorage.getItem("auth_key");
    let req = {
      action: "/voter/search",
      vid: vid,
    };
    return req;
    // let resp = await axiosPost(req, checkuser);
    // console.log(resp);
  } catch (error) {}
};
