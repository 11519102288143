import React, { useState, useEffect, useRef } from "react";
import VoterHeaderComponent from "../../voter/voterheader";
import './AssignVoters.css';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import VotersList from "./VotersList";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { axiosPost, axiosGet } from "../../../utils/commonAxios";
import LoaderComponent from "../../loader/LoaderComponent";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../common/AlertBox.css';

import { AlertBox } from "../../../components/common/AlertBox"

import AssignVoterPopUp from "./AssignVoterPopUp";

export default function AssignVoters(props) {
    const history = useHistory();
    const { boothid, aid } = useParams();
    const { state } = useLocation();
    const count = state.unAssignedCount;
    const [data, setData] = useState([]);
    // const initialState = {
    //     value: [1, state.unAssignedCount]
    // }
    const initialState = [1, state.unAssignedCount];
    const [range, setRange] = useState(initialState);
    const [from, setFrom] = useState(1);
    const [to, setTo] = useState(state.unAssignedCount);

    const [count_1, setCount_1] = useState(0);
    const [to_1, setTo_1] = useState(0)
    const [random, setRandom] = useState(0)

    const [newArr, setNewArr] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [showAssignPopUp, setShowAssignPopUp] = useState(false);


    useEffect(() => {
        // setCount_1(200)
        async function getUnAssignedVoters() {
            setIsLoading(true)
            let actionURL = `/electoral_list/unassigned_voter_list?booth_code=${boothid}`;
            try {
                let resp = await axiosGet(actionURL, {});
                if (resp.data.resultFlag === 1) {
                    let arrData = resp.data.response.list;
                    arrData.map((data) => {
                        data['activeClass'] = false
                    })
                    setData(arrData);
                    setTo_1(resp.data.response.list && resp.data.response.list.length);
                    setRangeData(arrData, [1, (resp.data.response.list && resp.data.response.list.length)])
                    setIsLoading(false)
                } else {
                    console.log("API Error - Unable to Fetch Data!");
                    setIsLoading(false)
                }
            } catch (error) {
                console.log(error);
                setIsLoading(false)
            }
        }
        getUnAssignedVoters();
    }, [])

    const nameForm = useRef(null);

    const confirmationBox = () => {
        confirmAlert({
            title: 'Confirm to proceed further',
            message: "Are you sure you want to assign?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        assignVoters()
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    const assignVoters = async () => {
        // if (window.confirm("Are you sure you want to assign?")) {
        // console.log("assignVoters");
        let fromVal, toVal;
        const form = nameForm.current;
        // console.log(`Values - ${form['from_val'].value} ${form['to_val'].value}`);
        // console.log("range", range);
        if (range && range.length > 0) {
            [fromVal, toVal] = range;
        } else {
            [fromVal, toVal] = [form['from_val'].value, form['to_val'].value];
        }

        // console.log("Final Range", fromVal, toVal)

        if (boothid && aid) {
            setIsLoading(true)
            let serialArray = [];
            // for (let i = fromVal - 1; i < data.length; i++) {
            //     if (i === toVal) { break; }
            //     serialArray.push(data[i].serial_no);
            // }

            // console.log(serialArray);
            let serialCSV = newArr.join(", ");

            // if (newTempArr.length > 0) {
            //     let newArr = new Set(newTempArr);
            //     serialCSV += ", "
            //     serialCSV += [...newArr].join(", ");
            // }

            // console.log(serialCSV);
            // return

            let reqObject = {
                action: "/electoral_list/assign_voter",
                booth_code: boothid,
                panna_parmukh_id: aid,
                serial_no: serialCSV
            };


            try {
                let resp = await axiosPost(reqObject);
                if (resp.data.resultFlag === 1) {
                    setIsLoading(false)
                    setShowAssignPopUp(false)
                    AlertBox("Success message", "Congratulation!, Assignment Done!");
                    history.push(`/karyakartalist/${boothid}`);
                } else {
                    setIsLoading(false)
                    AlertBox("Error message", "API Error - Assignment Failed!");
                }
            } catch (error) {
                setIsLoading(false)
                AlertBox("Error message", "API Error while assigning voters!");
            }

        } else {
            AlertBox("Error message", "Invalid Booth Code OR Agent ID!");
        }
        // }


    };

    // const onRangeChange = (range) => {
    //     // console.log("range", range);
    //     setRange(range);
    //     setFrom(range[0]);
    //     // setTo(range[1]);
    //     setTo_1(range[1])
    //     setRangeData(data, [range[0], range[1]])

    // }

    const onChageToHandlerStart = (e) => {
        let startRange = Number(e.target.value)
        setFrom(e.target.value)
        setTo_1(to_1)
        setRangeData(data, [startRange, to_1])
    }

    const onChageToHandlerEnd = (e) => {
        // console.log(e.target.value);
        let endRange = Number(e.target.value)
        // setRange([1, endRange])
        setFrom(from)
        setTo_1(e.target.value)
        setRangeData(data, [from, endRange])
        // setNewTempArr([])
    }


    const setRangeData = (data, range) => {
        let newAr = []
        data.map((newData, i) => {
            let newVoterItem = newData;
            newVoterItem['activeClass'] = false;
            if (i >= range[0] - 1 && i < range[1]) {
                newAr.push(newData.serial_no)
            }
        })
        setNewArr(newAr)
    }

    const setFilterData = (voterData) => {
        let newData = data;

        newData.map((data) => {
            if (data._id == voterData._id) {
                data['activeClass'] = !voterData.activeClass
                let newAr = newArr
                if (newArr.indexOf(voterData.serial_no) != -1) {
                    let index = newAr.indexOf(voterData.serial_no);
                    newAr.splice(index, 1)
                } else {
                    newAr.push(voterData.serial_no)
                }
                setNewArr(newAr)
            }
        })

        // console.log('newData ', newData);
        setData(newData)
        setRandom(Math.random())
    }


    useEffect(() => { }, [random])

    // open popup
    const openAssignPopUp = () => {
        setShowAssignPopUp(true)
    }

    const closeAssignPopUp = () => {
        setShowAssignPopUp(false)
    }

    return (
        <div >
            <VoterHeaderComponent />
            <LoaderComponent displayType={isLoading} />
            {
                showAssignPopUp ? (
                    <AssignVoterPopUp data={data} closeAssignPopUp={closeAssignPopUp} setFilterData={setFilterData} confirmationBox={confirmationBox} />
                ) : null
            }
            <div className="voter_container">

                <form ref={nameForm}>
                    <div className="input_range_list">
                        <ul>
                            <li className="range_padding">
                                <input type="number" placeholder="from" name={'from_val'} value={from} onChange={onChageToHandlerStart} />
                            </li>
                            <li>
                                <p>to</p>
                            </li>
                            <li>
                                <input type="number" placeholder="to" name={'to_val'} value={to_1} onChange={onChageToHandlerEnd} />
                            </li>
                        </ul>
                        {/* <Range
                            min={1}
                            max={count}
                            defaultValue={[1, to_1]}
                            trackStyle={[{ backgroundColor: 'rgba(109, 107, 106, 0.34)' }, { backgroundColor: 'rgba(109, 107, 106, 0.34)' }]}
                            handleStyle={[{ backgroundColor: '#6D6B6A' }, { backgroundColor: '#6D6B6A' }]}
                            railStyle={{ backgroundColor: 'rgba(109, 107, 106, 0.34)' }}
                            onChange={onRangeChange}
                            value={[1, to_1]}
                        /> */}
                    </div>
                    <div className="assignvoter_btn">
                        <ul>
                            <li>
                                <button className="assign_btn2" type="button" onClick={() => openAssignPopUp()}>Next</button>
                            </li>
                            {/* <li>
                            <button className="unassign_btn2" type="button">Unassign</button>
                        </li> */}
                        </ul>
                    </div>
                </form>

                <div className="loksabha_list" key={Math.random()}>
                    <div className="loksabha_list">
                        <ul>
                            {
                                data.map((newData, i) => {
                                    let newVoterItem = newData;
                                    newVoterItem['activeClass'] = false;
                                    if (newArr.indexOf(newVoterItem.serial_no) !== -1) {
                                        newVoterItem['activeClass'] = true
                                    }

                                    // console.log('newData ', newData.activeClass);

                                    return (

                                        <li onClick={() => setFilterData(newVoterItem)}>
                                            {
                                                newData.activeClass ?
                                                    (
                                                        <h5 className="active_voter">{newVoterItem.serial_no}</h5>
                                                    ) : (
                                                        <h5>{newVoterItem.serial_no}</h5>
                                                    )
                                            }
                                        </li>
                                        // <VotersList count={count} range={range} newVoterItem={newVoterItem} setFilterData={setFilterData} key={Math.random()} />
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    );
}

