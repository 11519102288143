import {
  QUESTION_PENDING,
  QUESTION_SUCCESS,
  QUESTION_ERROR,
} from "../actions/types";

const INTIAL_STATE = {
  // data: [],
  loading: false,
  success: false,
  errorMessage: null,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case QUESTION_PENDING:
      return {
        ...state,
        success: false,
        loading: true,
        errorMessage: null,
        // data: [],
      };
    case QUESTION_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        errorMessage: null,
        data: action.payload,
      };
    case QUESTION_ERROR:
      return {
        ...state,
        success: true,
        loading: false,
        errorMessage: action.payload,
        // data: [],
      };
    default:
      return state;
  }
};
