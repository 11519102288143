export const getYearList = async (startYear = 1950) => {
  try {
    let currentYear = new Date().getFullYear();
    let listOfyears = [];
    // let startYear = 1950;
    while (startYear <= currentYear) {
      listOfyears.push({ value: startYear, label: startYear });
      startYear++;
    }
    return listOfyears;
  } catch (error) { }
};

// export const getYearList = async (startYear) => {
//   try {
//     let currentYear = startYear;
//     let listOfyears = [];
//     let startYear = 1950;
//     while (startYear <= currentYear) {
//       listOfyears.push({ value: startYear, label: startYear });
//       startYear++;
//     }
//     return listOfyears;
//   } catch (error) {}
// };

export const getNumbers = async () => {
  try {
    let maxNumber = 20;
    let numbersList = [];
    let startNumber = 1;
    while (startNumber <= maxNumber) {
      if (startNumber <= 9) {
        numbersList.push({
          value: "0" + startNumber,
          label: "0" + startNumber,
        });
      } else {
        numbersList.push({ value: startNumber, label: startNumber });
      }
      startNumber++;
    }
    return numbersList;
  } catch (error) { }
};
//Relation Type

export const relationType = async () => {
  const relation = [
    { value: "mother", label: "Mother" },
    { value: "father", label: "Father" },
    { value: "spouse", label: "Spouse" },
  ];
  return relation;
};
export const maritalStatus = async () => {
  const relation = [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
    { value: "widowed", label: "Widowed" },
    { value: "separated", label: "Separated" },
    { value: "divorced", label: "Divorced" },
  ];
  return relation;
};

export const politicalLeaning = async () => {
  return [
    { value: "supporter", label: "Supporter" },
    { value: "opposite", label: "Opposite" },
    { value: "natural", label: "Natural" },
    { value: "party member", label: "Party Member" },
    { value: "ex party member", label: "Ex Party Member" },
  ];
};

export const ageNumber = async () => {
  try {
    let maxNumber = 99;
    let numbersList = [];
    let startNumber = 18;
    while (startNumber <= maxNumber) {
      numbersList.push({ value: startNumber, label: startNumber });
      startNumber++;
    }
    return numbersList;
  } catch (error) { }
};
