import React, { useEffect, useState } from "react";
import { axiosPost } from "../../utils/commonAxios";
import { useParams, Link } from "react-router-dom";
import VoterHeaderComponent from "../voter/voterheader";
import LoaderComponent from "../loader/LoaderComponent";

function SectorDetails() {
  let { id } = useParams();

  const [sectorDetails, setSectorDetails] = useState({});
  const [numberOfBooth, setNumberOfBooth] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getSectorDetails(id);
    }
  }, [id]);

  const getSectorDetails = async function getSectorDetails(id) {
    setLoading(true);

    let data = {
      id: id,
    };

    let reqobject = {
      action: "/sector/detail",
      ...data,
    };

    let res = await axiosPost(reqobject, {});
    if (res.data && res.data.resultFlag) {
      setNumberOfBooth(res.data.response.number_of_booth);
      setSectorDetails(res.data.response.sector[0]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      <VoterHeaderComponent />

      {/* show loader */}
      <LoaderComponent displayType={loading} />

      <div className="voter_container">
        <div className="boothdetail_container">
          <h4>Sector Details</h4>
          <ul className="boothlist_detail">
            <React.Fragment>
              <li>
                <h5>Sector Number</h5>
              </li>
              <li className="boothdetail_no">
                <h5>{sectorDetails.code ? sectorDetails.code : ""}</h5>
              </li>
              <li>
                <h5>Sector Name:</h5>
              </li>
              <li className="boothdetail_no">
                <h5>{sectorDetails.name ? sectorDetails.name : ""}</h5>
              </li>
              <li>
                <h5>Number of Booth:</h5>
              </li>
              <li className="boothdetail_no">
                <h5>
                  {numberOfBooth ? numberOfBooth : ""}{" "}
                  <Link to={`/boothlists/${sectorDetails.code}`}>
                    View detail <img src="/images/next3.svg" alt="" />
                  </Link>
                </h5>
              </li>
            </React.Fragment>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SectorDetails;
