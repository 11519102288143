import React from "react";
import KarykartaList from "../../components/karyakarta/KarykartaList";

function KaryakartaPage() {
  return (
    <div>
      <KarykartaList />
    </div>
  );
}

export default KaryakartaPage;
