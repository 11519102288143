import { axiosPost, axiosGet } from "../utils/commonAxios";
import {
  FETCH_BOOTH_PENDING,
  FETCH_BOOTH_SUCCESS,
  FETCH_BOOTH_ERROR,
  FETCH_BOOTH_EMPTY,
  BOOTH_DETAILS_PENDING,
  BOOTH_DETAILS_SUCCESS,
  BOOTH_DETAILS_ERROR,
  BOOTH_DETAILS_EMPTY,
  JANSAMPARK_COUNT_SUCCESS,
  JANSAMPARK_COUNT_ERROR,
  JANSAMPARK_COUNT_EMPTY,
  JANSAMPARK_COUNT_PENDING,
} from "./types";

export const boothListing = (sector_code) => {
  console.log(sector_code);

  return async (dispatch) => {
    dispatch({
      type: FETCH_BOOTH_PENDING,
    });

    try {
      const resp = await axiosGet("/booth/list", { sector_code });

      dispatch({
        type: FETCH_BOOTH_SUCCESS,
        payload: resp.data.response,
      });
    } catch (error) {
      dispatch({
        type: FETCH_BOOTH_ERROR,
        payload: "Something wrong!",
      });
    }
  };
};

export const cleanBoothListing = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_BOOTH_EMPTY,
    });
  };
};

export const boothDetails = (code) => {
  return async (dispatch) => {
    dispatch({
      type: BOOTH_DETAILS_PENDING,
    });

    try {
      const resp = await axiosGet("/booth/details?code=" + code, {});
      // const resp = await axiosGet("/booth/details?code=S24-72-01-01-01", {});
      dispatch({
        type: BOOTH_DETAILS_SUCCESS,
        payload: resp.data.response,
      });
    } catch (error) {
      dispatch({
        type: BOOTH_DETAILS_ERROR,
      });
    }
  };
};

export const cleanBoothDetails = () => {
  return (dispatch) => {
    dispatch({
      type: BOOTH_DETAILS_EMPTY,
    });
  };
};

//Jansampark count for booth details graph page
export const jansamparkCount = (code, abhiyaan_id) => {
  return async (dispatch) => {
    dispatch({
      type: JANSAMPARK_COUNT_PENDING,
    });
    // let params = {
    //   code: code,
    //   abhiyaan_id: abhiyaan_id,
    // };

    let bodyObj = {};

    bodyObj["booth_code"] = code;
    bodyObj["abhiyaan_id"] = abhiyaan_id

    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) {}

    let reqobject = {
      action: "/electoral_list/getJansamparkPercentage",
      ...bodyObj,
    };

    try {
      // let resp = await axiosGet("/voter/get_jansampark_count", params);
      const resp = await axiosPost(reqobject, checkuser);
      dispatch({
        type: JANSAMPARK_COUNT_SUCCESS,
        payload: resp.data.response,
      });
    } catch (error) {
      dispatch({
        type: JANSAMPARK_COUNT_ERROR,
      });
    }
  };
};

export const cleanJansamparkCount = () => {
  return (dispatch) => {
    dispatch({
      type: JANSAMPARK_COUNT_EMPTY,
    });
  };
};
