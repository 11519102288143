const axios = require("axios").default;
let url;
if (window.location.hostname === "localhost") {
  url = "http://localhost:8080/v1";
} else {
  url = "https://api.abhiyaaan.in/v1";
}
// url = "https://votermodule.opalina.in/api/v1";

if (window.location.href.indexOf("votermodule") !== -1) {
  url = "https://votermodule.opalina.in/api/v1";
}

const apiUrl = {
  apiv1URL: url,
};

export const axiosGet = async (routeurl, dataobj) => {
  let userInfo;
  try {
    userInfo = localStorage.getItem("user_data");
  } catch (error) {}

  if (userInfo) {
    userInfo = JSON.parse(userInfo);
  }

  let config = {
    headers: {
      booth_code: userInfo && userInfo.booth_code,
    },
  };

  return await axios.get(apiUrl.apiv1URL + routeurl, {
    params: dataobj,
    headers: { ...config.headers },
  });
};

export const axiosPost = async (dataobj, headobj = undefined) => {
  let urlobj = {};

  Object.keys(dataobj).forEach((key) => {
    urlobj[key] = dataobj[key];
  });

  let userInfo;
  try {
    userInfo = localStorage.getItem("user_data");
  } catch (error) {}

  if (userInfo) {
    userInfo = JSON.parse(userInfo);
  }

  let config = {
    headers: {
      booth_code: userInfo && userInfo.booth_code,
    },
  };
  if (headobj) {
    config["headers"] = { ...config.headers, ...headobj };
  }
  let ax = await axios.post(
    `${apiUrl.apiv1URL + dataobj.action}`,
    urlobj,
    config
  );
  return ax;
};
