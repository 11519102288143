import React, { useState, useEffect } from "react";
import VoterHeaderComponent from "../voter/voterheader";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { getPoliticalProfileList } from "../voterDetail/polical/handleOnChange";
import { axiosPost } from "../../utils/commonAxios";
import validator from 'validator'
import "./KarykartaCreatePage.css";
import KarykartaPopup from './KarykartaPopup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../common/AlertBox.css';

import { AlertBox } from "../../components/common/AlertBox"

const KarykartaCreatePage = (props) => {
  const history = useHistory();
  const [karyakartaCreate, setKaryakartaCreate] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const [karyakartaCreateArr, setKaryakartaCreateArr] = useState({
    designation: [],
  });
  const [data, setData] = useState([]);
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [mobiledigitError, setMobileDigitError] = useState('');
  const [showKarykartaPopUp, setshowKarykartaPopUp] = useState(false);
  const [newArr, setNewArr] = useState([])

  const updateForm = (e) => {
    const { name, value } = e.target;
    setKaryakartaCreate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const openKarykartaPopUp = (e) => {
    e.preventDefault();
    let userInfo;
    let karyakarta = {};

    
    try {
      userInfo = localStorage.getItem("user_data");
    } catch (error) { }

    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
 
    karyakarta["id"] = userInfo ? userInfo["_id"] : "";

    if (!karyakartaCreate.name ) {
      setNameError('Please Enter Name')
    } else {
      karyakarta["name"] = karyakartaCreate.name;
      setNameError('')
    }

    let isvalidEmail = true;
    if (karyakartaCreate.email === '') {
      isvalidEmail = true;
      setEmailError('')
    } else if (validator.isEmail(karyakartaCreate.email)) {
      karyakarta["email"] = karyakartaCreate.email;
      isvalidEmail = true;
      setEmailError('')
    } else {
      isvalidEmail = false
      setEmailError('Please Enter Valid Email')
    }

    if (karyakartaCreate.mobile === null || (karyakartaCreate.mobile.length < 10 || karyakartaCreate.mobile.length > 10)) {
      setMobileError('Please Enter Valid 10 Digit Mobile Number')
    } else {
      karyakarta["mobile"] = karyakartaCreate.mobile;
      setMobileError('')
    }

    

    if (!!karyakarta["name"] && !!karyakarta["mobile"] && isvalidEmail) {
      setNewArr(karyakarta);
      setshowKarykartaPopUp(true)
    }
  }
    const closeKarykartaPopUp = () => {
        setshowKarykartaPopUp(false)
    }

    //Submit form
  const submitForm = async () => {
    setshowKarykartaPopUp(false)
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) { }

    let reqobject = {
      action: "/user/create",
      ...newArr,
    };
      try {
        const resp = await axiosPost(reqobject, checkuser);
        console.log(resp);
        if (resp.data.resultFlag) {
          // setshowKarykartaPopUp(false)
          AlertBox("Successfully Submitted!");
          history.push("/menulist");
        } else {
          AlertBox("Error message", "User exist, try with different mobile number!");
        }
      } catch (error) {
        console.log(error);
      }
  
  };

    const confirmationBox = () => {
        confirmAlert({
            // title: 'Confirm to proceed further',
            message: "Are you sure ?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        submitForm()
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };


  return (
    <div>
      <VoterHeaderComponent />
       {
                showKarykartaPopUp ? (
                    <KarykartaPopup data={newArr} closeKarykartaPopUp={closeKarykartaPopUp} confirmationBox={confirmationBox} />
                ) : null
            }
      <form id="personal_details" encType="multipart/form-data">
        <div className="voter_container">
          <div className="voter_question_margin">
            <div className="voter_question2">
              <h6>Name</h6>
              <input
                type="text"
                name="name"
                placeholder="Name"
                onChange={updateForm}
              /><br />
              <span style={{
                fontWeight: 'bold',
                color: 'red',
              }}>{nameError}</span>
            </div>

            <div className="voter_question2">
              <h6>Email</h6>
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={updateForm}
              /><br />
              <span style={{
                fontWeight: 'bold',
                color: 'red',
              }}>{emailError}</span>
            </div>

            <div className="voter_question2">
              <h6>Mobile No</h6>
              <div>
                <input
                name="mobile"
                  type="number"
                  placeholder="Mobile Number"
                  onChange={updateForm}
                /><br />
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{mobileError}</span>
                <span style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>{mobiledigitError}</span>
              </div>
            </div>

            <div className="child_btn">
              <button onClick={openKarykartaPopUp}>Next</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

};

export default KarykartaCreatePage;