import { axiosGet } from "../utils/commonAxios";

export const getMandalList = async (params) => {
  try {
    let response = await axiosGet("/mandal/list", params);
    if (response.data.resultFlag) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
