import apis from "../utils/apis";
import { axiosGet } from "../utils/commonAxios";

import {
  SIGN_IN,
  SIGN_OUT,
  FETCH_POST_PENDING,
  FETCH_POST_SUCCESS,
  FETCH_POST_ERROR,
} from "./types";

export const signIn = () => {
  return async (dispatch, getState) => {
    const response = await apis.axiosInstance.post("/user/login");

    dispatch({
      type: SIGN_IN,
      payload: response,
    });
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};

export const fetchPost = () => {
  return async (dispatch, getState) => {
    // dispatch another pending
    dispatch({
      type: FETCH_POST_PENDING,
    });

    try {
      const response = await apis.axiosInstance.get("/users");
      // dispatch another success
      dispatch({
        type: FETCH_POST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      // dispatch another error
      dispatch({
        type: FETCH_POST_ERROR,
        payload: "Some thing wrong",
      });
    }
  };
};

export const getScgeme = async (searchParama) => {
  let params = {
    searchkey: searchParama,
  };

  try {
    let res = await axiosGet("/voter/scheme", params);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
