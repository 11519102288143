import { axiosGet, axiosPost } from "../utils/commonAxios";
import {
  CATEGORY_LIST_PENDING,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_EMPTY,
  CATEGORY_LIST_ERROR,
} from "./types";

export const categoryList = () => {
  return async (dispatch) => {
    dispatch({ type: CATEGORY_LIST_PENDING });

    try {
      let resp = await axiosGet("/category/list");
      dispatch({
        type: CATEGORY_LIST_SUCCESS,
        payload: resp.data.response,
      });
    } catch (error) {
      dispatch({ type: CATEGORY_LIST_ERROR });
    }
  };
};
export const cleanCategoryList = () => {
  return async (dispatch) => {
    dispatch({ type: CATEGORY_LIST_EMPTY });
  };
};

export const regilionList = () => {
  return async (dispatch) => {};
};
