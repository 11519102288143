import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import VoterHeaderComponent from "../voter/voterheader";
import { connect } from "react-redux";
import {
  boothDetails,
  cleanBoothDetails,
  cleanJansamparkCount,
  jansamparkCount,
} from "../../actions/booth";
import LoaderComponent from "../loader/LoaderComponent";
import PieChartComponent from "./PieChartComponent";
import { getAbhiyaanList } from "../../actions/abhiyaan";
import BoothSelctcComponent from "./BoothSelctcComponent";
import "./BoothDetailsComponent.css";

import { __ucLetter } from "../../utils/language";

const BoothDetailsComponent = (props) => {
  let { code } = useParams();

  const [abhiyaanList, setAbhiyaanList] = useState([]);
  const [abhiyaanlistError, setAbhiyaanlistError] = useState(false);
  const [abhiyaanId, setAbhiyaanId] = useState(null);

  useEffect(() => {
    if (code) {
      props.boothDetails(code);
      getAbhiyaanListDate(code);
    }

    return () => {
      props.cleanBoothDetails();
      props.cleanJansamparkCount();
    };
  }, [code]);

  const getAbhiyaanListDate = function getAbhiyaanListDate(boothId) {
    getAbhiyaanList(boothId)
      .then((resp) => {
        setAbhiyaanList(resp.response);
        if (resp.response.length > 0) {
          getJansamparkCount(resp.response[0]["_id"]);

          setAbhiyaanId(resp.response[0]["_id"]);
        }
      })
      .catch((err) => {
        setAbhiyaanlistError(true);
      });
  };

  const getJansamparkCount = (abhiyaan_id) => {
    if (code && abhiyaan_id) {
      props.jansamparkCount(code, abhiyaan_id);
    }
  };

  const handleSelectChange = function handleSelectChange(e) {
    if (e.target.value) {
      getJansamparkCount(e.target.value);
      setAbhiyaanId(e.target.value);
    }
  };

  var boothDetails =
    props.boothDetailsData && props.boothDetailsData.data
      ? props.boothDetailsData.data
      : "";

  return (
    <div>
      <VoterHeaderComponent />
      {/* show loader */}
      {props.boothDetailsData && props.boothDetailsData.loading ? (
        <LoaderComponent displayType={props.boothDetailsData.loading} />
      ) : null}

      <div className="voter_container">
        <div className="boothdetail_container">
          {/* <h4>Booth Details</h4> */}

          <ul className="boothlist_detail">
            {boothDetails && boothDetails.booth && (
              <React.Fragment>
                <li>
                  <h5>Booth Number</h5>
                </li>
                <li className="boothdetail_no">
                  <h5>
                    {boothDetails.booth.booth_no && boothDetails.booth.booth_no}
                  </h5>
                </li>
                <li>
                  <h5>Booth President Name:</h5>
                </li>
                <li className="boothdetail_no">
                  <h5>
                    {boothDetails.president &&
                      boothDetails.president.name &&
                      __ucLetter(boothDetails.president.name)}
                  </h5>
                </li>
                <li>
                  <h5>Polling Station Name</h5>
                </li>
                <li className="boothdetail_no">
                  <h5>{boothDetails.booth.name && boothDetails.booth.name}</h5>
                </li>
                <li>
                  <h5>Number of Karykarta:</h5>
                </li>
                {boothDetails.number_of_karykarta ? (
                  <li className="boothdetail_no">
                    <h5>
                      {boothDetails.number_of_karykarta &&
                        boothDetails.number_of_karykarta}{" "}
                      <Link to={{
                        pathname: `/karyakartalist/${code}`,
                        state: { abhiyaanId }
                      }} >
                        View detail <img src="/images/next3.svg" alt="" />
                      </Link>
                      {/* {abhiyaanId ? (
                        <Link to={`/karyakarta/${code}/${abhiyaanId}`}>
                          View detail <img src="/images/next3.svg" alt="" />
                        </Link>
                      ) : (
                        <Link to={`/karyakarta/${code}`}>
                          View detail <img src="/images/next3.svg" alt="" />
                        </Link>
                      )} */}
                    </h5>
                  </li>
                ) : null}
              </React.Fragment>
            )}
          </ul>
          {/* Abhiyaan listing */}
          {abhiyaanList && abhiyaanList.length > 0 ? (
            <BoothSelctcComponent
              abhiyaanList={abhiyaanList}
              handleSelectChange={handleSelectChange}
            />
          ) : null}

          {/* PieChart */}
          {abhiyaanList && abhiyaanList.length > 0 && (
            <div className="boothdetail_img">
              <div className="chart_section">
                <PieChartComponent />
              </div>

              {/* <Link
                // to={`/karyakarta/${code}/${abhiyaanId}`}
                to={`/karyakartalist/${code}/${abhiyaanId}`}
                className="booth_view"
              > */}
              <Link to={{
                pathname: `/karyakartainfo/${code}`,
                state: { abhiyaanId }
              }} className="booth_view" >
                view detail
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    boothDetailsData: state.boothDetails,
  };
};

export default connect(mapStateToProps, {
  boothDetails: (code) => boothDetails(code),
  jansamparkCount: (code, abhiyaan_id) => jansamparkCount(code, abhiyaan_id),
  // karyakarta: (code, abhiyaan_id) => karyakarta(code, abhiyaan_id),
  cleanBoothDetails: cleanBoothDetails,
  cleanJansamparkCount: cleanJansamparkCount,
  // cleanKaryakartaListCount:cleanKaryakartaListCount
})(BoothDetailsComponent);
