import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Questions from "./questions/Questions";
import Scheme from "./scheme/Scheme";
import { appLanguageData } from "../../utils/language";
import {
  submitVoter,
  cleanVoterState,
  getQuestionByUser,
  getSchemeByUser,
} from "../../actions/voter";
import "./VoterDetail.css";
import { axiosGet } from "../../utils/commonAxios";

const Voterdetail = ({ voterData, cleanVoterState, loadingIcon }) => {
  const history = useHistory();

  const [questionnaire_id, setQuestionnaire_id] = useState([]);
  const [tempQArr, setTempQArr] = useState([]);
  const [questionArr, setQuestionArr] = useState([]);
  const [tempArr, setTempArr] = useState([]);
  const [tagData, setTagData] = useState([]);

  const [partySupporter, setPartySupporter] = useState("");
  const [remark, setRemark] = useState("");
  const [remarkArr, setRemarkArr] = useState([remark]);
  const [formUpdate, setFormUpdate] = useState(false);

  const [optionDataNew, setOptionDataNew] = useState({});

  useEffect(() => {
    if (voterData.data["mark_done"]) {
      // call an api for get question and scheme benificeary
      getJansampQuestionByUser(voterData.data["personal"]["epic_no"]);
      getSchemebenificearyByUser(voterData.data["personal"]["epic_no"]);
      setPartySupporter(voterData.data["partySupporterType"]);
      // console.log(voterData.data);
      // setRemarkArr(voterData.data["remark"]);
      setFormUpdate(true);

      //Only for Remark
      getJansamparkDetials(voterData.data["personal"]["epic_no"]);
    }
  }, [voterData.data["mark_done"]]);

  function toObject(arr) {
    var rv = {};
    for (var i = 0; i < arr.length; ++i) {
      let tl = arr[i];
      rv[tl.question_id] = tl["text_answer"];
    }

    return rv;
  }

  const getJansamparkDetials = async (epic_no) => {
    try {
      let resp = await axiosGet("/voter/get_jansampark?voter_id=" + epic_no);
      if (resp.data.response) {
        setRemarkArr(resp.data.response.remarks);
      }
    } catch (error) { }
  };

  const getJansampQuestionByUser = async (epic_no) => {
    getQuestionByUser(epic_no).then((resp) => {
      let tempArr = [];
      let newArr =
        resp.response && resp.response.jansampark_que &&
        resp.response.jansampark_que.forEach((data) => {
          let tempOb = {
            question_id: data.question_id,
            questionnaire_id: Number(data.questionnaire_id),
            text_answer: data.text_answer,
          };
          tempArr.push(tempOb);
          addUniqeQ(tempOb);
        });

      let newData = toObject(tempArr);
      // console.log(newData)
      setOptionDataNew(newData);
      // console.log(tempArr)
      setQuestionArr(tempArr);
    });
  };

  const getSchemebenificearyByUser = async (epic_no) => {
    getSchemeByUser(epic_no).then((resp) => {
      // scheme_id
      let tempArr = [];

      console.log(resp.response.voter_data['schemes_beneficary']);

      resp.response.voter_data['schemes_beneficary'] && resp.response.voter_data['schemes_beneficary'].length > 0 && resp.response.voter_data['schemes_beneficary'].map((schemeData) => {
        tempArr.push({
          id: schemeData.id,
          name: schemeData.name
        })
      })

      // resp.response && resp.response.length > 0 && resp.response.map((res) => {
      //   let newArr = res.scheme_id.forEach((data) => {
      //     console.log(data.json_language_data);
      //     tempArr.push({
      //       id: data.id,
      //       // name: appLanguageData(data.json_language_data, "title"),
      //       name: data.name,
      //     });
      //   });
      // });

      // console.log(tempArr, ' tempArr')
      setTagData(tempArr);
    });
  };

  function addUniqeQ(data) {
    let tempArrNew = tempQArr;
    let index = -1;
    for (let i = 0; i < tempArrNew.length; i++) {
      if (tempArrNew[i] == Number(data.questionnaire_id)) {
        index = i;
      }
    }

    if (index > -1) {
      tempArrNew[index] = Number(data.questionnaire_id);
    } else {
      tempArrNew.push(Number(data.questionnaire_id));
      setTempQArr(tempArrNew);
      setQuestionnaire_id(tempArrNew);
    }
  }

  function addUniqeOptions(data) {
    let tempArrNew = tempArr;
    let index = -1;
    for (let i = 0; i < tempArrNew.length; i++) {
      if (tempArrNew[i].question_id === data.question_id) {
        index = i;
      }
    }

    if (index > -1) {
      tempArrNew[index] = data;
    } else {
      tempArrNew.push(data);
      setTempArr(tempArrNew);
      setQuestionArr(tempArrNew);
    }
  }

  const questopnOption = (data) => {
    if (
      data[0]["question_id"] &&
      data[0]["questionnaire_id"] &&
      data[0]["json_language_data"]
    ) {
      let tempObj = {};
      tempObj["question_id"] = data[0]["question_id"];
      tempObj["questionnaire_id"] = Number(data[0]["questionnaire_id"]);
      tempObj["text_answer"] = appLanguageData(
        data[0]["json_language_data"],
        "option"
      );

      addUniqeOptions(tempObj);
      addUniqeQ(tempObj);
    }
  };

  const tagsArray = (data) => {
    // console.log(data)
    setTagData(data);
  };

  const checkHandler = (e) => {
    // console.log(e.target.name)
    // console.log(e.target.value)
    setPartySupporter(e.target.value);
  };

  const onSubmithandler = (e) => {
    e.preventDefault();
    loadingIcon(true);

    let userInfo;
    try {
      userInfo = localStorage.getItem("user_data");
    } catch (error) { }

    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
    if (remark != "") {
      let newRemarkArr = remarkArr;
      newRemarkArr.push(remark);
      setRemarkArr(newRemarkArr);
      // setRemarkArr((prevState) => ({
      //   ...prevState,
      //   newRemarkArr,
      // }));
    }

    let data = {
      language_code: "en",
      questionnaire_id: questionnaire_id,
      questionArr: questionArr,
      election_id: "",
      user_id: userInfo ? userInfo["_id"] : "",
      voter_id: voterData.data["personal"]["epic_no"]
        ? voterData.data["personal"]["epic_no"]
        : "",
      booth_code_id: userInfo ? userInfo["booth_code"] : "",
      remarks_id: "",
      remarks: remarkArr,
      schemeBenificiary: tagData,
      partySupporterType: partySupporter,
    };

    // console.log(data)
    // return

    // form submit
    document.getElementById("full_loader").style.display = "block";
    submitVoter(data)
      .then((resp) => {
        if (resp.resultFlag) {
          document.getElementById("full_loader").style.display = "none";
          loadingIcon(false);
          alert(resp.message);
          cleanVoterState();
          history.goBack();
        }
      })
      .catch((error) => {
        document.getElementById("full_loader").style.display = "none";
        loadingIcon(false);
        alert(error);
      });
  };


  return voterData.data &&
    Object.keys(voterData.data["personal"]).length > 0 ? (
      <div>
        <div className="">
          <div className="voter_container">
            <div className="voter_detail">
              {/* <div className="voterlink_btn">
              <Link
                // to={`/voterdetail/personal/${voterData.data["personal"]["epic_no"]}`}
                to={`/voterdetail/personal/list/${voterData.data["personal"]["epic_no"]}`}
              >
                <img src="/images/idcard2.svg" alt="" /> Voter Details
              </Link>
            </div> */}
              <ul>
                <li>Name:</li>
                <li>
                  {voterData.data["personal"]
                    ? voterData.data["personal"]["name_en"]
                    : ""}
                </li>
                <li>Age:</li>
                <li>
                  {voterData.data["personal"]
                    ? voterData.data["personal"]["age"]
                    : ""}
                </li>
                <li>Sex:</li>
                <li>
                  {voterData.data["personal"]
                    ? voterData.data["personal"]["gender"]
                    : ""}
                </li>
                <li>Address:</li>
                {voterData.data["personal"] && (
                  <li>
                    {voterData.data["personal"]["house_no"]
                      ? voterData.data["personal"]["house_no"]
                      : ""}

                    {voterData.data["personal"]["add_curr"]
                      ? " , " + voterData.data["personal"]["add_curr"]["full_add"]
                      : ""}
                    {voterData.data["personal"]["add_curr"]
                      ? " - " + voterData.data["personal"]["add_curr"]["pincode"]
                      : ""}
                  </li>
                )}
              </ul>
            </div>

            <div className="voter_remark">
              <h5>Add Remark</h5>
              <div style={{ marginLeft: "15px" }}>
                <ul>
                  {remarkArr &&
                    remarkArr.map((rvalue, i) => {
                      return (
                        <React.Fragment key={i}>
                          {rvalue ? <li>{rvalue}</li> : ""}
                        </React.Fragment>
                      );
                    })}
                </ul>
              </div>
              <textarea
                type="text"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                placeholder="Add more remark"
              />
              {/* <button type="button">
              <img src="/images/plus.svg" alt="" />
              Add More Remark
            </button> */}
            </div>

            <Questions
              questopnOption={questopnOption}
              formUpdate={formUpdate}
              optionDataNew={optionDataNew}
            />
            <Scheme
              tagsArray={tagsArray}
              formUpdate={formUpdate}
              tagData={tagData}
            />

            <div className="voter_checkbox">
              <ul>
                <li>
                  <label className="container_check">
                    <input
                      type="radio"
                      checked={partySupporter == "nutral" ? true : false}
                      name="nutral"
                      value="nutral"
                      onChange={checkHandler}
                    />
                  Neutral <span className="checkmark"></span>
                  </label>
                </li>

                <li>
                  <label className="container_check">
                    <input
                      type="radio"
                      checked={partySupporter == "party member" ? true : false}
                      name="party member"
                      value="party member"
                      onChange={checkHandler}
                    />
                  Party member <span className="checkmark"></span>
                  </label>
                </li>

                <li>
                  <label className="container_check">
                    <input
                      type="radio"
                      name="supporter"
                      checked={partySupporter == "supporter" ? true : false}
                      value="supporter"
                      onChange={checkHandler}
                    />
                  Supporter <span className="checkmark"></span>
                  </label>
                </li>

                <li>
                  <label className="container_check">
                    <input
                      type="radio"
                      name="opposite"
                      checked={partySupporter == "opposite" ? true : false}
                      value="opposite"
                      onChange={checkHandler}
                    />
                  Opposite <span className="checkmark"></span>
                  </label>
                </li>

                <li>
                  <label className="container_check">
                    <input
                      type="radio"
                      name="ex party member"
                      checked={partySupporter == "ex party member" ? true : false}
                      value="ex party member"
                      onChange={checkHandler}
                    />
                  Ex party member <span className="checkmark"></span>
                  </label>
                </li>
              </ul>
            </div>

            <div className="voter_submit">
              <button type="button" onClick={onSubmithandler}>
                {formUpdate ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : null;
};

const mapStateToProps = (state) => {
  return {
    voterData: state.voter,
  };
};

export default connect(mapStateToProps, {
  cleanVoterState: () => cleanVoterState(),
})(Voterdetail);
