import React from "react";
import PersonalDetailsList from "../../components/voterDetail/personal/PersonalDetailsList";

function PersonalList() {
  return (
    <div>
      <PersonalDetailsList />
    </div>
  );
}

export default PersonalList;
