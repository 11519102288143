const defaultLanguage = "en";

export const appLanguageData = (json, key, lang = defaultLanguage) => {
  lang = lang ? lang : localStorage.getItem("lang");
  return json[lang][key];
};

export const __ucLetter = (str) => {
  // console.log(str);

  let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";

  if (lang === "en" && typeof str !== "undefined") {
    var pieces = str.split(" ");
    for (var i = 0; i < pieces.length; i++) {
      var j = pieces[i].charAt(0).toUpperCase();
      pieces[i] = j + pieces[i].substr(1).toLowerCase();
    }
    return pieces.join(" ");
  } else {
    return str;
  }
};
