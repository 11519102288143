import React from "react";
import BoothListComponent from "../../components/booth/BoothListComponent";

function BoothListPage() {
  return (
    <div>
      <BoothListComponent />
    </div>
  );
}

export default BoothListPage;
