import React from "react";
import FormField from "../../../utils/formField";
import {
  update,
  generateData,
  isFormValid,
  validation,
} from "../../../utils/formAction";

function ChildInfo({
  rowPosition,
  formData,
  updateChildForm,
  updateChildFormRadio,
}) {
  const update_form = (element, rowPosition) => {
    updateChildForm(element, rowPosition);
  };

  const update_form_radio = (element, rowPosition) => {
    updateChildFormRadio(element, rowPosition);
  };
  return (
    <div style={{ marginTop: "10px" }}>
      <div className="voter_question2 plus_position">
        <h6>Children Name*</h6>
        <input
          type="text"
          name="name"
          placeholder="Children Name"
          value={formData.name}
          onChange={(element) => update_form(element, rowPosition)}
        />
      </div>

      <div className="voter_question2">
        <h6>Date of Birth</h6>
        <input
          type="date"
          name="age"
          placeholder="Name"
          value={formData.age}
          onChange={(element) => update_form(element, rowPosition)}
        />
      </div>
      <div className="voter_question2">
        <h6>Gender</h6>
        <fieldset id={`gender_${rowPosition}`} style={{ border: "none" }}>
          <ul className="gender_listing">
            <li>
              <label className="personal_container">
                Male
                <input
                  type="radio"
                  value={"M"}
                  name={`gender_${rowPosition}`}
                  checked={formData.gender == "M" && true}
                  onChange={(element) =>
                    update_form_radio(element, rowPosition)
                  }
                />
                <span className="personal_checkmark"></span>
              </label>
            </li>

            <li>
              <label className="personal_container">
                Female
                <input
                  type="radio"
                  value="F"
                  name={`gender_${rowPosition}`}
                  checked={formData.gender == "F" && true}
                  onChange={(element) =>
                    update_form_radio(element, rowPosition)
                  }
                />
                <span className="personal_checkmark"></span>
              </label>
            </li>

            <li>
              <label className="personal_container">
                Others
                <input
                  type="radio"
                  value="O"
                  name={`gender_${rowPosition}`}
                  checked={formData.gender == "O" && true}
                  onChange={(element) =>
                    update_form_radio(element, rowPosition)
                  }
                />
                <span className="personal_checkmark"></span>
              </label>
            </li>
          </ul>
        </fieldset>
      </div>

      <div className="voter_question2">
        <h6>Occupation</h6>
        <input
          type="text"
          name="childrenOccupation"
          placeholder="Occupation"
          value={formData.childrenOccupation}
          onChange={(element) => update_form(element, rowPosition)}
        />
      </div>

      <div className="voter_question2">
        <h6>Aadhaar</h6>
        <input
          type="number"
          name="child_aadhar"
          placeholder="Aadhaar"
          value={formData.child_aadhar}
          onChange={(element) => update_form(element, rowPosition)}
        />
      </div>

      <div className="voter_question2">
        <h6>Voter Id</h6>
        <input
          type="text"
          name="child_voter"
          placeholder="Voter Id"
          value={formData.child_voter}
          onChange={(element) => update_form(element, rowPosition)}
        />
      </div>
      {/* <hr /> */}
    </div>
  );
}

export default ChildInfo;
