import React, { useState, useEffect } from "react";
import { getQuestionOptions } from "../../../actions/question";
import LoaderComponent from "../../loader/LoaderComponent";
import { appLanguageData } from "../../../utils/language";

function OptionsList(props) {
  const [selectQuestion, setSelectQuestion] = useState("");
  const [optionData, setOptionData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getQuestionOptions(props.questionId)
      .then((resp) => {
        // console.log(resp)
        setOptionData(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [props.questionId]);

  const handleChange = (e) => {
    // console.log(e.target.value)
    setSelectQuestion(e.target.value);
    let newData = optionData.filter((optData) => {
      return (
        appLanguageData(optData.json_language_data, "option") === e.target.value
      );
    });
    // console.log(newData)
    props.questopnOption(newData);
    // console.log(e.target.value)
  };

  // console.log(props.optionDataNew)

  return (
    <div>
      {loading ? <LoaderComponent /> : null}

      <select onChange={handleChange}>
        <option value="">Select option</option>
        {optionData &&
          optionData.length > 0 &&
          optionData.map((questionOtions, i) => {
            let defaultSelect = false;
            let appLang = appLanguageData(
              questionOtions.json_language_data,
              "option"
            );
            // console.log(props.optionDataNew[questionOtions.question_id])
            if (props.optionDataNew[questionOtions.question_id] == appLang) {
              defaultSelect = true;
              // console.log(questionOtions.question_id)
            }
            return (
              <option key={i} selected={defaultSelect} value={appLang}>
                {appLang}
              </option>
            );
          })}
      </select>
    </div>
  );
}

export default OptionsList;
