import { axiosGet } from "../utils/commonAxios";

export const getAbhiyaanList = async (boothId) => {
  let params = {
    booth_code: boothId,
  };

  try {
    let res = await axiosGet("/abhiyan/list", params);
    return res.data;
  } catch (error) {
    // console.log(error);
    return "Something wrong";
  }
};
