import React from "react";
import MyBoothDetailsComponent from "../../components/booth/MyBoothDetailsComponent";

function MyBoothPage() {
  return (
    <div>
      <MyBoothDetailsComponent />
    </div>
  );
}

export default MyBoothPage;
