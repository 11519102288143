import React, { useState, useEffect } from "react";
import { VoterHeaderMenu } from "../VoterHeaderMenu";
import { useHistory, useParams } from "react-router-dom";
import LoaderComponent from "../../loader/LoaderComponent";
import { axiosPost } from "../../../utils/commonAxios";
import "./PoliticalDetailsList.css";

function PoliticalDetailsList() {
  const history = useHistory();
  const { epic_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [politicals, setPoliticals] = useState([]);
  useEffect(() => {
    getPoliticalDetailsList();
  }, []);

  const getPoliticalDetailsList = async () => {
    setLoading(true);
    let checkuser = {};
    try {
      checkuser["token"] = localStorage.getItem("auth_key");
    } catch (error) {}

    let reqobject = {
      action: "/voter/search",
      epic_no: epic_id,
    };
    try {
      const resp = await axiosPost(reqobject, checkuser);
      //   setTimeout(() => {
      //     console.log("resp", resp);
      //   }, 1000);
      //   if (resp.data.response === null) {
      //     history.push(`/voterdetail/political/${epic_id}`);
      //   }
      const { political } = resp.data.response;
      let setData = {};
      setData["political_leaning"] = political.political_leaning;
      setData["party_supported"] = political.party_supported;
      setData["is_prospect"] = political.is_prospect;
      setData["is_prospect_added"] = political.is_prospect_added;
      setData["is_namo_user"] = political.is_namo_user;
      setData["is_bjg_profile"] = political.is_bjg_profile;
      if (political.is_bjg_profile === "yes") {
        setData["memb_id"] = political.bjp_profile.memb_id;
        setData["unit"] = political.bjp_profile.unit;
        setData["join_yr"] = political.bjp_profile.join_yr;
        setData["leave_yr"] = political.bjp_profile.leave_yr;
        setData["designation"] = political.bjp_profile.designation;
        setData["start_yr"] = political.bjp_profile.start_yr;
        setData["end_yr"] = political.bjp_profile.end_yr;
        setData["desc"] = political.bjp_profile.desc;
      }
      setData["is_rss_profile"] = political.is_rss_profile;
      if (political.is_rss_profile === "yes") {
        setData["rss_memb_id"] = political.rss_profile.memb_id;
        setData["rss_unit"] = political.rss_profile.unit;
        setData["rss_join_yr"] = political.rss_profile.join_yr;
        setData["rss_leave_yr"] = political.rss_profile.leave_yr;
        setData["rss_designation"] = political.rss_profile.designation;
        setData["rss_start_yr"] = political.rss_profile.start_yr;
        setData["rss_end_yr"] = political.rss_profile.end_yr;
        setData["rss_desc"] = political.rss_profile.desc;
      }
      setData["is_contested_elections"] = political.is_contested_elections;
      if (political.is_contested_elections === "yes") {
        setData["seat"] = political.contested_elections.seat;
        setData["party"] = political.contested_elections.party;
        setData["is_won"] = political.contested_elections.is_won;
      }

      setData["is_associated_with_other"] = political.is_associated_with_other;
      if (political.is_associated_with_other === "yes") {
        setData["name"] = political.oth_party_asso.name;
        setData["no_of_years_asso_oth"] =
          political.oth_party_asso.no_of_years_asso_oth;
        setData["join_yr"] = political.oth_party_asso.join_yr;
        setData["leave_yr"] = political.oth_party_asso.leave_yr;
        setData["desc"] = political.oth_party_asso.desc;
      }
      setData["is_family_member_in_politics"] =
        political.is_family_member_in_politics;
      if (political.is_family_member_in_politics === "yes") {
        setData["name"] = political.family_asso.name;
        setData["relation"] = political.family_asso.relation;
        setData["party"] = political.family_asso.party;
        setData["epic_no"] = political.family_asso.epic_no;
        setData["desc"] = political.family_asso.desc;
      }

      setPoliticals((prevState) => ({
        ...prevState,
        ...setData,
      }));

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div>
      <LoaderComponent displayType={loading} />
      <VoterHeaderMenu>
        <div className="voter_container">
          <div className="personaldetail_list">
            <ul>
              <li>
                <h5>Political Leaning :</h5>
              </li>
              <li>
                <h5 className="detail_color">{politicals.political_leaning}</h5>
              </li>
              {politicals.is_contested_elections && (
                <React.Fragment>
                  <li>
                    <h5>Contested Elections ? :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">
                      {politicals.is_contested_elections}
                    </h5>
                  </li>
                  <li>
                    <h5>Seat :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.seat}</h5>
                  </li>
                  <li>
                    <h5>Party :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.party}</h5>
                  </li>
                  <li>
                    <h5>Is Own? :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.is_won}</h5>
                  </li>
                </React.Fragment>
              )}
              <li>
                <h5>Is Prospect ? :</h5>
              </li>
              <li>
                <h5 className="detail_color">{politicals.is_prospect}</h5>
              </li>
              {politicals.is_prospect && (
                <React.Fragment>
                  <li>
                    <h5>Is Prospect Added ?:</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">
                      {politicals.is_prospect_added}
                    </h5>
                  </li>
                </React.Fragment>
              )}
              <li>
                <h5>Is Namo User ? :</h5>
              </li>
              <li>
                <h5 className="detail_color">{politicals.is_namo_user}</h5>
              </li>
              <li>
                <h5>BJP Profile ? :</h5>
              </li>
              <li>
                <h5 className="detail_color">{politicals.is_bjg_profile}</h5>
              </li>
              {politicals.is_bjg_profile && (
                <React.Fragment>
                  <li>
                    <h5>BJP Membership ID :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.memb_id}</h5>
                  </li>

                  <li>
                    <h5>Select Unit :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.unit}</h5>
                    {/* <button type="button">Loksabha</button> */}
                  </li>

                  <li>
                    <h5>Join Year :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.join_yr} </h5>
                  </li>
                  <li>
                    <h5>Leave Year :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.leave_yr} </h5>
                  </li>

                  <li>
                    <h5>Select Designation :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.designation} </h5>
                  </li>

                  <li>
                    <h5>Start Year :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.start_yr}</h5>
                  </li>
                  <li>
                    <h5>End Year :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.end_yr}</h5>
                  </li>
                </React.Fragment>
              )}
              <li>
                <h5>RSS Profile? :</h5>
              </li>
              <li>
                <h5 className="detail_color">{politicals.is_rss_profile} </h5>
              </li>
              {politicals.is_rss_profile && (
                <React.Fragment>
                  <li>
                    <h5>RSS Membership ID :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.rss_memb_id}</h5>
                  </li>

                  <li>
                    <h5>Select Unit :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.rss_unit} </h5>
                  </li>
                  <li>
                    <h5>Join Year :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.rss_join_yr} </h5>
                  </li>
                  <li>
                    <h5>Leave Year :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.rss_leave_yr} </h5>
                  </li>
                  <li>
                    <h5>Select Designation :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">
                      {politicals.rss_designation}{" "}
                    </h5>
                  </li>
                  <li>
                    <h5>Start Year :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.rss_start_yr} </h5>
                  </li>

                  <li>
                    <h5>End Year :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.rss_end_yr} </h5>
                  </li>

                  <li>
                    <h5>Description :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.rss_desc} </h5>
                  </li>
                </React.Fragment>
              )}
              <li>
                <h5>Associated With Other Parties :</h5>
              </li>
              <li>
                <h5 className="detail_color">
                  {politicals.is_associated_with_other}
                </h5>
              </li>
              {politicals.is_associated_with_other && (
                <React.Fragment>
                  <li>
                    <h5>Party :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.name} </h5>
                  </li>
                  <li>
                    <h5>No of years associated :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">
                      {politicals.no_of_years_asso_oth}{" "}
                    </h5>
                  </li>
                  <li>
                    <h5>Join Year :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.join_yr} </h5>
                  </li>
                  <li>
                    <h5>Leave Year :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.leave_yr} </h5>
                  </li>
                  <li>
                    <h5>Description :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.desc} </h5>
                  </li>
                </React.Fragment>
              )}
              <li>
                <h5>Any Family Member in Politics :</h5>
              </li>
              <li>
                <h5 className="detail_color">
                  {politicals.is_family_member_in_politics}
                </h5>
              </li>
              {politicals.is_family_member_in_politics && (
                <React.Fragment>
                  <li>
                    <h5>Name :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.name}</h5>
                  </li>
                  <li>
                    <h5>Relationship :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.relation}</h5>
                  </li>
                  <li>
                    <h5>Party :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.party} </h5>
                  </li>
                  <li>
                    <h5>Epic Number :</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.epic_no} </h5>
                  </li>
                  <li>
                    <h5>Description:</h5>
                  </li>
                  <li>
                    <h5 className="detail_color">{politicals.desc} </h5>
                  </li>
                </React.Fragment>
              )}
            </ul>
          </div>
        </div>
      </VoterHeaderMenu>
    </div>
  );
}

export default PoliticalDetailsList;
