import React from 'react'
import { CommunicationDetailsContainer } from "../../components/voterDetail";

function Communication() {
  return (
    <div>
      <CommunicationDetailsContainer />
    </div>
  )
}

export default Communication
