import React from "react";
import "./KarykartaPopUp.css";

export default function KarykartaPopUp({ data, closeKarykartaPopUp, confirmationBox }) {
  return (
    <div className="assignvoter_popupbg">
      <div className="assignvoter_popup2">
        <h4>Verify Detail</h4>
        <div className="karkartapopup2">
          <ul>
            <li>
              <h5>Name</h5>
            </li>
            <li>
              <h5>{data.name}</h5>
            </li>
            <li>

            </li>
          </ul>
          <ul>
            <li>
              <h5>Email</h5>
            </li>
            <li>
              <h5>{data.email}</h5>
            </li>
          </ul>
          <ul>
            <li>
              <h5>Mobile</h5>
            </li>
            <li>
              <h5>{data.mobile}</h5>
            </li>
          </ul>
        </div>
        <div className="otp_container2">
          <ul>
            <li>
              <button className="verify_btn2" onClick={() => confirmationBox()}>confirm</button>
            </li>
            <li>
              <button className="cancel_btn2" onClick={() => closeKarykartaPopUp()}>cancel</button>
            </li>
          </ul>

        </div>
      </div>

    </div>
  )
}
