import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import VoterHeaderComponent from "./voterheader";

const Karyakarta = () => {
  return (
    <div>
      <VoterHeaderComponent />
      <div className="voter_container">
        <div className="karya_heading">
          <h4>Karyakarta List</h4>
        </div>
      </div>

      <div className="karya_list">
        <ul>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6 className="karta_color">Name</h6>
                  </li>
                  <li>
                    <h6 className="karta_color karta_value">Assigned Voters</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Samsher Singh Rathore :</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Ranjeet</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Samsher Singh</h6>
                  </li>
                  <li>
                    <button type="button">Assigned Voter >></button>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Samsher Singh Rathore :</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Ranjeet</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Samsher Singh Rathore :</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Ranjeet</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Samsher Singh Rathore :</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div className="voter_container">
              <div className="karya_innerlist">
                <ul>
                  <li>
                    <h6>Ranjeet</h6>
                  </li>
                  <li>
                    <h6>1-50</h6>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Karyakarta;
